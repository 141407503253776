$faces: url('https://cdn.au.dk/2015/au/graphics/survey/gsa_test-smileyfaces.png');
$faces-outline: url('https://cdn.au.dk/2015/au/graphics/survey/gsa_test-smileyfaces2.png');

#au_gsa-smiley {
    position: absolute;
    width: 325px;
    background: #313131;
    background: rgba(49,49,49,0.95);
    top: 18px;
    right: 18px;
    color: #fff;
    text-align: center !important;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    z-index: 1;

    h2 {
        text-align: center !important;
        text-transform: uppercase;
        font-size: 22px !important;
        position: relative !important;
    }

    div {
        text-align: center !important;
    }

    a {
        color: #b5b5b5 !important;
        border-bottom: 1px dotted #b5b5b5;

        &:hover {
            text-decoration: none;
            color: #fff;
            border-bottom: 1px solid #fff;
        }
    }

    textarea {
        width: 280px;
    }

    input[type=submit] {
        font: 22px "AUPassataRegular";
        text-transform: uppercase;
        background: 0;
        border: 0;
        color: #fff;
        cursor: pointer;
        margin: 0.2em;
    }
}

#au_gsa-smiley-partone {
    padding: 14px;
}

#au_gsa-smiley-parttwo {

    padding: 14px;
    padding-top: 1px;
    box-shadow: inset 1px 4px 9px -6px black;
    background: #2f2f2f;
    background: rgba(47,47,47,0.95);
    display: none;

    h2 {
        text-transform: none !important;
        margin: 0.5em 0 !important;
        line-height: 1em;

        &.happy {
            color: #7ab51d;
        }

        &.neither {
            color: #f9ba00;
        }

        &.unhappy {
            color: #f13a6b;
        }
    }
}

#au_gsa-smiley-close {
    position: absolute;
    top: -18px;
    right: -18px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    z-index: 8040;
    background-image: url('https://cdn.au.dk/2015/news/graphics/fancybox_sprite.png');
}

#au_gsa-smiley-happy, #au_gsa-smiley-neither, #au_gsa-smiley-unhappy {
    width: 60px;
    height: 56px;
    display: inline-block;
    cursor: pointer;
    margin: 1em;
}

#au_gsa-smiley-happy {
    background: $faces 2px -56px no-repeat;

    &.selected {
        background-position: 2px 0;
    }
}

#au_gsa-smiley-neither {
    background: $faces -89px -56px no-repeat;

    &.selected {
        background-position: -89px 0;
    }
}

#au_gsa-smiley-unhappy {
    background: $faces -178px -56px no-repeat;

    &.selected {
        background-position: -178px 0;
    }
}

.au_survey_like {
    position: fixed;
    height: 280px;
    background: #313131;
    background: rgba(49,49,49,0.95);
    bottom: 70px;
    right: 0;
    color: #fff !important;
    text-align: center !important;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    z-index: 99;

    &.embedded {
        clear: both;
        position: static;
        z-index: auto;
        box-shadow: none;
        height: auto;
        padding: 1em;
        background: #f6f6f6;
        color: #212121;
        border: 1px solid #ccc;

        &.step2, &.step3, &.step4 {
            width: auto;
        }

        > div {
            float: none;

            &:first-child {
                width: auto;
                height: auto;

                p {
                    position: static;
                    -ms-transform: none;
                    -webkit-transform: none;
                    transform: none;
                    width: auto;
                    margin: auto;
                    text-align: center;

                    a {
                        color: #212121 !important;
                        height: auto;
                        padding: 0;
                        border-bottom: none !important;

                        &:hover, &:visited {
                            color: #212121 !important;
                        }
                    }
                }
            }

            &:nth-child(2) {
                > ul {

                    margin: auto;
                    max-width: 210px;

                    li {
                        padding-left: 0 !important;
                        line-height: 1 !important;
                        float: left;
                        display: inline-block;
                        margin: 5px !important;
                        background: $faces-outline no-repeat !important;

                        a {
                            border-bottom: none !important;
                        }
                    }

                    + p {
                        clear: both;
                        margin-bottom: 0;
                        text-align: center;
                    }
                }
            }

            &:nth-child(3) {

                h2 {
                    margin: .5em 0 !important;
                }

                text-align: center;

                textarea {
                    width: 100%;
                }

                input[type=submit] {
                    display: block;
                    margin: auto;
                    color: #000;
                }
            }

            &:nth-child(4) {

                h2 {
                    text-align: center;
                    width: auto;
                    padding: 50px 0;
                }
            }
        }
    }

    > div:nth-child(2) {
        display: none;
    }

    > div:nth-child(3) {
        display: none;
    }

    > div:nth-child(4) {
        display: none;
    }

    > div:nth-child(5) {
        display: none;
    }

    &.step1 {
        width: 50px;
    }

    &.step2 {
        width: 128px;

        > div:nth-child(2) {
            display: block;
        }

        > div:nth-child(5) {
            display: block;
        }
    }

    &.step3 {
        width: 400px;

        > div:nth-child(2) {
            display: block;
        }

        > div:nth-child(3) {
            display: block;
        }

        > div:nth-child(5) {
            display: block;
        }
    }

    &.step4 {
        width: 400px;

        > div:first-child {
            display: none;
        }

        > div:nth-child(4) {
            display: block;
        }

        > div:nth-child(5) {
            display: block;
        }
    }

    > div {
        float: left;

        &:first-child {

            width: 50px;
            height: 280px;

            p {
                -ms-transform: rotate(90deg);
                -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
                -ms-transform-origin: left top;
                -webkit-transform-origin: left top 0;
                transform-origin: left top 0;
                width: 280px;
                position: absolute;
                left: 50px;
                font: 18px "AUPassataRegular";

                a {
                    color: #fff;
                    display: block;
                    padding-top: 12px;
                    height: 38px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        &:nth-child(2) {
            > ul {

                margin-top: 1.75em;

                li {
                    text-indent: -9999px;
                    background: $faces no-repeat !important;
                    margin-bottom: 3rem;

                    a {
                        display: block;
                        width: 58px;
                        height: 56px;
                    }

                    &:first-child {
                        background-position: 2px -56px !important;
                    }

                    &:nth-child(2) {
                        background-position: -89px -56px !important;
                    }

                    &:nth-child(3) {
                        background-position: -178px -56px !important;
                    }

                    &.selected {
                        &:first-child {
                            background-position: 2px 0px !important;
                        }

                        &:nth-child(2) {
                            background-position: -89px 0px !important;
                        }

                        &:nth-child(3) {
                            background-position: -178px 0px !important;
                        }
                    }
                }
            }
        }

        &:nth-child(3) {

            padding: 1rem 1.1rem 0 1.1rem;

            textarea {
                width: 266px;
                height: 160px;
            }

            br {
                display: none;
            }

            input[type=submit] {
                font: 22px "AUPassataRegular";
                text-transform: uppercase;
                background: 0;
                border: 0;
                color: #fff;
                cursor: pointer;
                margin: 0.2em;
            }
        }

        &:nth-child(4) {

            h2 {
                padding: 115px 0;
                width: 400px;
            }
        }

        &:nth-child(5) {
            position: absolute;
            top: -18px;
            left: -18px;
            width: 36px;
            height: 36px;
            cursor: pointer;
            z-index: 8040;
            background-image: url('https://cdn.au.dk/2015/news/graphics/fancybox_sprite.png');
        }
    }
}

@media print {
    .au_survey_like {
        display: none;
    }
}

.au_survey_taskily {
    position: fixed;
    width: 360px;
    background: #313131;
    background: rgba(49,49,49,0.95);
    bottom: 150px;
    right: 0;
    color: #fff;
    text-align: center !important;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    z-index: 99;
    /*
    &.catchme {
        left: 0;
        right: auto;
    }*/

    h2 {
        margin-bottom: 1em;
        line-height: 1.25;
    }

    p.answers {
        width: 140px;
        margin: auto;
        font: 14px "AUPassataRegular";
        text-transform: uppercase;

        a {
            display: block;
            width: 60px;
            padding: .5em 0;
            color: #fff;
            margin-bottom: 1em;

            &.yes {
                float: left;
                background: #7ab51d;
            }

            &.no {
                float: right;
                background: #e2001a;
            }
        }
    }
}