@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.3.0
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
   ========================================================================== */
/**
 * 1. Change the default font family in all browsers (opinionated).
 * 2. Correct the line height in all browsers.
 * 3. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
figcaption,
figure {
  display: block;
}

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * Add the correct display in IE.
 */
main {
  display: block;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers (opinionated).
 */
a:active,
a:hover {
  outline-width: 0;
}

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ff0;
  color: #000;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
 * Show the overflow in IE.
 */
button {
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type=button],
[type=reset],
[type=submit] {
  /**
   * Remove the inner border and padding in Firefox.
   */
  /**
   * Restore the focus styles unset by the previous rule.
   */
}
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Show the overflow in Edge.
 */
input {
  overflow: visible;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
   * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
   */
}
[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto;
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/*
 * Add the correct display in IE 9-.
 */
menu {
  display: block;
}

/* Scripting
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* Hidden
   ========================================================================== */
/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em";
}

html {
  box-sizing: border-box;
  font-size: 11px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: Georgia;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0;
}

select {
  width: 100%;
  border-radius: 0;
}

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important;
}

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
}
[data-whatinput=mouse] button {
  outline: 0;
}

.is-visible {
  display: block !important;
}

.is-hidden {
  display: none !important;
}

.row {
  max-width: 127.2727272727rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}
.row .row {
  margin-right: -0.9090909091rem;
  margin-left: -0.9090909091rem;
}
@media print, screen and (min-width: 40em) {
  .row .row {
    margin-right: -1.3636363636rem;
    margin-left: -1.3636363636rem;
  }
}
@media print, screen and (min-width: 64em) {
  .row .row {
    margin-right: -1.3636363636rem;
    margin-left: -1.3636363636rem;
  }
}
@media screen and (min-width: 75em) {
  .row .row {
    margin-right: -1.3636363636rem;
    margin-left: -1.3636363636rem;
  }
}
@media screen and (min-width: 90em) {
  .row .row {
    margin-right: -1.3636363636rem;
    margin-left: -1.3636363636rem;
  }
}
.row.expanded {
  max-width: none;
}
.row.collapse > .column, .row.collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}
.row.is-collapse-child, .row.collapse > .column > .row, .row.collapse > .columns > .row {
  margin-right: 0;
  margin-left: 0;
}

.column, .columns {
  flex: 1 1 0px;
  padding-right: 0.9090909091rem;
  padding-left: 0.9090909091rem;
  min-width: initial;
}
@media print, screen and (min-width: 40em) {
  .column, .columns {
    padding-right: 1.3636363636rem;
    padding-left: 1.3636363636rem;
  }
}

.column.row.row, .row.row.columns {
  display: flex;
}

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.flex-container {
  display: flex;
}

.flex-child-auto {
  flex: 1 1 auto;
}

.flex-child-grow {
  flex: 1 0 auto;
}

.flex-child-shrink {
  flex: 0 1 auto;
}

.flex-dir-row {
  flex-direction: row;
}

.flex-dir-row-reverse {
  flex-direction: row-reverse;
}

.flex-dir-column {
  flex-direction: column;
}

.flex-dir-column-reverse {
  flex-direction: column-reverse;
}

.small-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-offset-1 {
  margin-left: 8.3333333333%;
}

.small-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-offset-2 {
  margin-left: 16.6666666667%;
}

.small-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.small-offset-4 {
  margin-left: 33.3333333333%;
}

.small-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-offset-5 {
  margin-left: 41.6666666667%;
}

.small-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.small-offset-7 {
  margin-left: 58.3333333333%;
}

.small-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.small-offset-8 {
  margin-left: 66.6666666667%;
}

.small-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.small-offset-10 {
  margin-left: 83.3333333333%;
}

.small-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-offset-11 {
  margin-left: 91.6666666667%;
}

.small-order-1 {
  order: 1;
}

.small-order-2 {
  order: 2;
}

.small-order-3 {
  order: 3;
}

.small-order-4 {
  order: 4;
}

.small-order-5 {
  order: 5;
}

.small-order-6 {
  order: 6;
}

.small-up-1 {
  flex-wrap: wrap;
}
.small-up-1 > .column, .small-up-1 > .columns {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-up-2 {
  flex-wrap: wrap;
}
.small-up-2 > .column, .small-up-2 > .columns {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-up-3 {
  flex-wrap: wrap;
}
.small-up-3 > .column, .small-up-3 > .columns {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-up-4 {
  flex-wrap: wrap;
}
.small-up-4 > .column, .small-up-4 > .columns {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-up-5 {
  flex-wrap: wrap;
}
.small-up-5 > .column, .small-up-5 > .columns {
  flex: 0 0 20%;
  max-width: 20%;
}

.small-up-6 {
  flex-wrap: wrap;
}
.small-up-6 > .column, .small-up-6 > .columns {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-up-7 {
  flex-wrap: wrap;
}
.small-up-7 > .column, .small-up-7 > .columns {
  flex: 0 0 14.2857142857%;
  max-width: 14.2857142857%;
}

.small-up-8 {
  flex-wrap: wrap;
}
.small-up-8 > .column, .small-up-8 > .columns {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.9090909091rem;
  padding-left: 0.9090909091rem;
}

@media print, screen and (min-width: 40em) {
  .medium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .medium-offset-0 {
    margin-left: 0%;
  }

  .medium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }

  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }

  .medium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .medium-offset-3 {
    margin-left: 25%;
  }

  .medium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }

  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }

  .medium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .medium-offset-6 {
    margin-left: 50%;
  }

  .medium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }

  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }

  .medium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .medium-offset-9 {
    margin-left: 75%;
  }

  .medium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }

  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }

  .medium-order-1 {
    order: 1;
  }

  .medium-order-2 {
    order: 2;
  }

  .medium-order-3 {
    order: 3;
  }

  .medium-order-4 {
    order: 4;
  }

  .medium-order-5 {
    order: 5;
  }

  .medium-order-6 {
    order: 6;
  }

  .medium-up-1 {
    flex-wrap: wrap;
  }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .medium-up-2 {
    flex-wrap: wrap;
  }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .medium-up-3 {
    flex-wrap: wrap;
  }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .medium-up-4 {
    flex-wrap: wrap;
  }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .medium-up-5 {
    flex-wrap: wrap;
  }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .medium-up-6 {
    flex-wrap: wrap;
  }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .medium-up-7 {
    flex-wrap: wrap;
  }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }

  .medium-up-8 {
    flex-wrap: wrap;
  }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .medium-expand {
    flex: 1 1 0px;
  }
}
@media print, screen and (min-width: 40em) {
  .medium-flex-dir-row {
    flex-direction: row;
  }
}
@media print, screen and (min-width: 40em) {
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
}
@media print, screen and (min-width: 40em) {
  .medium-flex-dir-column {
    flex-direction: column;
  }
}
@media print, screen and (min-width: 40em) {
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}
@media print, screen and (min-width: 40em) {
  .medium-flex-child-auto {
    flex: 1 1 auto;
  }
}
@media print, screen and (min-width: 40em) {
  .medium-flex-child-grow {
    flex: 1 0 auto;
  }
}
@media print, screen and (min-width: 40em) {
  .medium-flex-child-shrink {
    flex: 0 1 auto;
  }
}
.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 40em) {
  .row.medium-unstack > .column, .row.medium-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
}
@media print, screen and (min-width: 40em) {
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 1.3636363636rem;
    padding-left: 1.3636363636rem;
  }
}
@media print, screen and (min-width: 64em) {
  .large-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .large-offset-0 {
    margin-left: 0%;
  }

  .large-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .large-offset-1 {
    margin-left: 8.3333333333%;
  }

  .large-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .large-offset-2 {
    margin-left: 16.6666666667%;
  }

  .large-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .large-offset-3 {
    margin-left: 25%;
  }

  .large-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .large-offset-4 {
    margin-left: 33.3333333333%;
  }

  .large-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .large-offset-5 {
    margin-left: 41.6666666667%;
  }

  .large-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .large-offset-6 {
    margin-left: 50%;
  }

  .large-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .large-offset-7 {
    margin-left: 58.3333333333%;
  }

  .large-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .large-offset-8 {
    margin-left: 66.6666666667%;
  }

  .large-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .large-offset-9 {
    margin-left: 75%;
  }

  .large-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .large-offset-10 {
    margin-left: 83.3333333333%;
  }

  .large-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .large-offset-11 {
    margin-left: 91.6666666667%;
  }

  .large-order-1 {
    order: 1;
  }

  .large-order-2 {
    order: 2;
  }

  .large-order-3 {
    order: 3;
  }

  .large-order-4 {
    order: 4;
  }

  .large-order-5 {
    order: 5;
  }

  .large-order-6 {
    order: 6;
  }

  .large-up-1 {
    flex-wrap: wrap;
  }
  .large-up-1 > .column, .large-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .large-up-2 {
    flex-wrap: wrap;
  }
  .large-up-2 > .column, .large-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .large-up-3 {
    flex-wrap: wrap;
  }
  .large-up-3 > .column, .large-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .large-up-4 {
    flex-wrap: wrap;
  }
  .large-up-4 > .column, .large-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .large-up-5 {
    flex-wrap: wrap;
  }
  .large-up-5 > .column, .large-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .large-up-6 {
    flex-wrap: wrap;
  }
  .large-up-6 > .column, .large-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .large-up-7 {
    flex-wrap: wrap;
  }
  .large-up-7 > .column, .large-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }

  .large-up-8 {
    flex-wrap: wrap;
  }
  .large-up-8 > .column, .large-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .large-expand {
    flex: 1 1 0px;
  }
}
@media print, screen and (min-width: 64em) {
  .large-flex-dir-row {
    flex-direction: row;
  }
}
@media print, screen and (min-width: 64em) {
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
}
@media print, screen and (min-width: 64em) {
  .large-flex-dir-column {
    flex-direction: column;
  }
}
@media print, screen and (min-width: 64em) {
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}
@media print, screen and (min-width: 64em) {
  .large-flex-child-auto {
    flex: 1 1 auto;
  }
}
@media print, screen and (min-width: 64em) {
  .large-flex-child-grow {
    flex: 1 0 auto;
  }
}
@media print, screen and (min-width: 64em) {
  .large-flex-child-shrink {
    flex: 0 1 auto;
  }
}
.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 64em) {
  .row.large-unstack > .column, .row.large-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 64em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
}
@media print, screen and (min-width: 64em) {
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 1.3636363636rem;
    padding-left: 1.3636363636rem;
  }
}
@media screen and (min-width: 75em) {
  .xlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .xlarge-offset-0 {
    margin-left: 0%;
  }

  .xlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .xlarge-offset-1 {
    margin-left: 8.3333333333%;
  }

  .xlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .xlarge-offset-2 {
    margin-left: 16.6666666667%;
  }

  .xlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .xlarge-offset-3 {
    margin-left: 25%;
  }

  .xlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .xlarge-offset-4 {
    margin-left: 33.3333333333%;
  }

  .xlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .xlarge-offset-5 {
    margin-left: 41.6666666667%;
  }

  .xlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .xlarge-offset-6 {
    margin-left: 50%;
  }

  .xlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .xlarge-offset-7 {
    margin-left: 58.3333333333%;
  }

  .xlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .xlarge-offset-8 {
    margin-left: 66.6666666667%;
  }

  .xlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .xlarge-offset-9 {
    margin-left: 75%;
  }

  .xlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .xlarge-offset-10 {
    margin-left: 83.3333333333%;
  }

  .xlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .xlarge-offset-11 {
    margin-left: 91.6666666667%;
  }

  .xlarge-order-1 {
    order: 1;
  }

  .xlarge-order-2 {
    order: 2;
  }

  .xlarge-order-3 {
    order: 3;
  }

  .xlarge-order-4 {
    order: 4;
  }

  .xlarge-order-5 {
    order: 5;
  }

  .xlarge-order-6 {
    order: 6;
  }

  .xlarge-up-1 {
    flex-wrap: wrap;
  }
  .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .xlarge-up-2 {
    flex-wrap: wrap;
  }
  .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .xlarge-up-3 {
    flex-wrap: wrap;
  }
  .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .xlarge-up-4 {
    flex-wrap: wrap;
  }
  .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .xlarge-up-5 {
    flex-wrap: wrap;
  }
  .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .xlarge-up-6 {
    flex-wrap: wrap;
  }
  .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .xlarge-up-7 {
    flex-wrap: wrap;
  }
  .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }

  .xlarge-up-8 {
    flex-wrap: wrap;
  }
  .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 75em) and (min-width: 75em) {
  .xlarge-expand {
    flex: 1 1 0px;
  }
}
@media screen and (min-width: 75em) {
  .xlarge-flex-dir-row {
    flex-direction: row;
  }
}
@media screen and (min-width: 75em) {
  .xlarge-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
}
@media screen and (min-width: 75em) {
  .xlarge-flex-dir-column {
    flex-direction: column;
  }
}
@media screen and (min-width: 75em) {
  .xlarge-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}
@media screen and (min-width: 75em) {
  .xlarge-flex-child-auto {
    flex: 1 1 auto;
  }
}
@media screen and (min-width: 75em) {
  .xlarge-flex-child-grow {
    flex: 1 0 auto;
  }
}
@media screen and (min-width: 75em) {
  .xlarge-flex-child-shrink {
    flex: 0 1 auto;
  }
}
.row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
  flex: 0 0 100%;
}
@media screen and (min-width: 75em) {
  .row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media screen and (min-width: 75em) {
  .xlarge-collapse > .column, .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
}
@media screen and (min-width: 75em) {
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns {
    padding-right: 1.3636363636rem;
    padding-left: 1.3636363636rem;
  }
}
@media screen and (min-width: 90em) {
  .xxlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .xxlarge-offset-0 {
    margin-left: 0%;
  }

  .xxlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .xxlarge-offset-1 {
    margin-left: 8.3333333333%;
  }

  .xxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .xxlarge-offset-2 {
    margin-left: 16.6666666667%;
  }

  .xxlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .xxlarge-offset-3 {
    margin-left: 25%;
  }

  .xxlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .xxlarge-offset-4 {
    margin-left: 33.3333333333%;
  }

  .xxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .xxlarge-offset-5 {
    margin-left: 41.6666666667%;
  }

  .xxlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .xxlarge-offset-6 {
    margin-left: 50%;
  }

  .xxlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .xxlarge-offset-7 {
    margin-left: 58.3333333333%;
  }

  .xxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .xxlarge-offset-8 {
    margin-left: 66.6666666667%;
  }

  .xxlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .xxlarge-offset-9 {
    margin-left: 75%;
  }

  .xxlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .xxlarge-offset-10 {
    margin-left: 83.3333333333%;
  }

  .xxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .xxlarge-offset-11 {
    margin-left: 91.6666666667%;
  }

  .xxlarge-order-1 {
    order: 1;
  }

  .xxlarge-order-2 {
    order: 2;
  }

  .xxlarge-order-3 {
    order: 3;
  }

  .xxlarge-order-4 {
    order: 4;
  }

  .xxlarge-order-5 {
    order: 5;
  }

  .xxlarge-order-6 {
    order: 6;
  }

  .xxlarge-up-1 {
    flex-wrap: wrap;
  }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .xxlarge-up-2 {
    flex-wrap: wrap;
  }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .xxlarge-up-3 {
    flex-wrap: wrap;
  }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .xxlarge-up-4 {
    flex-wrap: wrap;
  }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .xxlarge-up-5 {
    flex-wrap: wrap;
  }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .xxlarge-up-6 {
    flex-wrap: wrap;
  }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .xxlarge-up-7 {
    flex-wrap: wrap;
  }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }

  .xxlarge-up-8 {
    flex-wrap: wrap;
  }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 90em) and (min-width: 90em) {
  .xxlarge-expand {
    flex: 1 1 0px;
  }
}
@media screen and (min-width: 90em) {
  .xxlarge-flex-dir-row {
    flex-direction: row;
  }
}
@media screen and (min-width: 90em) {
  .xxlarge-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
}
@media screen and (min-width: 90em) {
  .xxlarge-flex-dir-column {
    flex-direction: column;
  }
}
@media screen and (min-width: 90em) {
  .xxlarge-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}
@media screen and (min-width: 90em) {
  .xxlarge-flex-child-auto {
    flex: 1 1 auto;
  }
}
@media screen and (min-width: 90em) {
  .xxlarge-flex-child-grow {
    flex: 1 0 auto;
  }
}
@media screen and (min-width: 90em) {
  .xxlarge-flex-child-shrink {
    flex: 0 1 auto;
  }
}
.row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
  flex: 0 0 100%;
}
@media screen and (min-width: 90em) {
  .row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media screen and (min-width: 90em) {
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
}
@media screen and (min-width: 90em) {
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns {
    padding-right: 1.3636363636rem;
    padding-left: 1.3636363636rem;
  }
}
.shrink {
  flex: 0 0 auto;
  max-width: 100%;
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
}

em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: bold;
  line-height: inherit;
}

small {
  font-size: 80%;
  line-height: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "AUPassataBold";
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  line-height: 0;
  color: #cacaca;
}

h1 {
  font-size: 2.1818181818rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.8181818182rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 1rem;
}

h3 {
  font-size: 1.7272727273rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 1rem;
}

h4 {
  font-size: 1.6363636364rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 1rem;
}

h5 {
  font-size: 1.5454545455rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 1rem;
}

h6 {
  font-size: 1.4545454545rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 1rem;
}

@media print, screen and (min-width: 40em) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.8181818182rem;
  }

  h3 {
    font-size: 1.6363636364rem;
  }

  h4 {
    font-size: 1.2727272727rem;
  }

  h5 {
    font-size: 1.2727272727rem;
  }

  h6 {
    font-size: 1.2727272727rem;
  }
}
a {
  line-height: inherit;
  color: #002546;
  text-decoration: none;
  cursor: pointer;
}
a:hover, a:focus {
  color: #00203c;
}
a img {
  border: 0;
}

hr {
  clear: both;
  max-width: 127.2727272727rem;
  height: 0;
  margin: 1.8181818182rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0;
}

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6;
}

li {
  font-size: inherit;
}

ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

ol {
  margin-left: 1.25rem;
}

ul ul, ul ol, ol ul, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

dl {
  margin-bottom: 1rem;
}
dl dt {
  margin-bottom: 0.3rem;
  font-weight: bold;
}

blockquote {
  margin: 0 0 1rem;
  padding: 0.8181818182rem 1.8181818182rem 0 1.7272727273rem;
  border-left: 1px solid #cacaca;
}
blockquote, blockquote p {
  line-height: 1.6;
  color: #8a8a8a;
}

cite {
  display: block;
  font-size: 1.1818181818rem;
  color: #8a8a8a;
}
cite:before {
  content: "— ";
}

abbr {
  border-bottom: 1px dotted #0a0a0a;
  color: #0a0a0a;
  cursor: help;
}

figure {
  margin: 0;
}

code {
  padding: 0.1818181818rem 0.4545454545rem 0.0909090909rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a;
}

kbd {
  margin: 0;
  padding: 0.1818181818rem 0.3636363636rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a;
}

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a;
}

.lead {
  font-size: 13.75px;
  line-height: 1.6;
}

.stat {
  font-size: 2.5rem;
  line-height: 1;
}
p + .stat {
  margin-top: -1rem;
}

.no-bullet {
  margin-left: 0;
  list-style: none;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left;
  }

  .medium-text-right {
    text-align: right;
  }

  .medium-text-center {
    text-align: center;
  }

  .medium-text-justify {
    text-align: justify;
  }
}
@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left;
  }

  .large-text-right {
    text-align: right;
  }

  .large-text-center {
    text-align: center;
  }

  .large-text-justify {
    text-align: justify;
  }
}
@media screen and (min-width: 75em) {
  .xlarge-text-left {
    text-align: left;
  }

  .xlarge-text-right {
    text-align: right;
  }

  .xlarge-text-center {
    text-align: center;
  }

  .xlarge-text-justify {
    text-align: justify;
  }
}
@media screen and (min-width: 90em) {
  .xxlarge-text-left {
    text-align: left;
  }

  .xxlarge-text-right {
    text-align: right;
  }

  .xxlarge-text-center {
    text-align: center;
  }

  .xxlarge-text-justify {
    text-align: justify;
  }
}
.show-for-print {
  display: none !important;
}

@media print {
  * {
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important;
  }

  .show-for-print {
    display: block !important;
  }

  .hide-for-print {
    display: none !important;
  }

  table.show-for-print {
    display: table !important;
  }

  thead.show-for-print {
    display: table-header-group !important;
  }

  tbody.show-for-print {
    display: table-row-group !important;
  }

  tr.show-for-print {
    display: table-row !important;
  }

  td.show-for-print {
    display: table-cell !important;
  }

  th.show-for-print {
    display: table-cell !important;
  }

  a,
a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  .ir a:after,
a[href^="javascript:"]:after,
a[href^="#"]:after {
    content: "";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre,
blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }
  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }
}
.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 1.55rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #002546;
  color: #fefefe;
}
[data-whatinput=mouse] .button {
  outline: 0;
}
.button:hover, .button:focus {
  background-color: #001f3c;
  color: #fefefe;
}
.button.tiny {
  font-size: 1rem;
}
.button.small {
  font-size: 1.2rem;
}
.button.large {
  font-size: 1.85rem;
}
.button.expanded {
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}
.button.primary {
  background-color: #1779ba;
  color: #fefefe;
}
.button.primary:hover, .button.primary:focus {
  background-color: #126195;
  color: #fefefe;
}
.button.secondary {
  background-color: #767676;
  color: #fefefe;
}
.button.secondary:hover, .button.secondary:focus {
  background-color: #5e5e5e;
  color: #fefefe;
}
.button.success {
  background-color: #3adb76;
  color: #0a0a0a;
}
.button.success:hover, .button.success:focus {
  background-color: #22bb5b;
  color: #0a0a0a;
}
.button.warning {
  background-color: #ffae00;
  color: #0a0a0a;
}
.button.warning:hover, .button.warning:focus {
  background-color: #cc8b00;
  color: #0a0a0a;
}
.button.alert {
  background-color: #cc4b37;
  color: #fefefe;
}
.button.alert:hover, .button.alert:focus {
  background-color: #a53b2a;
  color: #fefefe;
}
.button.hollow {
  border: 1px solid #002546;
  color: #002546;
}
.button.hollow, .button.hollow:hover, .button.hollow:focus {
  background-color: transparent;
}
.button.hollow:hover, .button.hollow:focus {
  border-color: #001323;
  color: #001323;
}
.button.hollow.primary {
  border: 1px solid #1779ba;
  color: #1779ba;
}
.button.hollow.primary:hover, .button.hollow.primary:focus {
  border-color: #0c3d5d;
  color: #0c3d5d;
}
.button.hollow.secondary {
  border: 1px solid #767676;
  color: #767676;
}
.button.hollow.secondary:hover, .button.hollow.secondary:focus {
  border-color: #3b3b3b;
  color: #3b3b3b;
}
.button.hollow.success {
  border: 1px solid #3adb76;
  color: #3adb76;
}
.button.hollow.success:hover, .button.hollow.success:focus {
  border-color: #157539;
  color: #157539;
}
.button.hollow.warning {
  border: 1px solid #ffae00;
  color: #ffae00;
}
.button.hollow.warning:hover, .button.hollow.warning:focus {
  border-color: #805700;
  color: #805700;
}
.button.hollow.alert {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}
.button.hollow.alert:hover, .button.hollow.alert:focus {
  border-color: #67251a;
  color: #67251a;
}
.button.disabled, .button[disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled:hover, .button.disabled:focus, .button[disabled]:hover, .button[disabled]:focus {
  background-color: #002546;
  color: #fefefe;
}
.button.disabled.primary, .button[disabled].primary {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary:hover, .button[disabled].primary:focus {
  background-color: #1779ba;
  color: #fefefe;
}
.button.disabled.secondary, .button[disabled].secondary {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
  background-color: #767676;
  color: #fefefe;
}
.button.disabled.success, .button[disabled].success {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success:hover, .button[disabled].success:focus {
  background-color: #3adb76;
  color: #fefefe;
}
.button.disabled.warning, .button[disabled].warning {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning:hover, .button[disabled].warning:focus {
  background-color: #ffae00;
  color: #fefefe;
}
.button.disabled.alert, .button[disabled].alert {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert:hover, .button[disabled].alert:focus {
  background-color: #cc4b37;
  color: #fefefe;
}
.button.dropdown::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4em;
  content: "";
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #fefefe transparent transparent;
  position: relative;
  top: 0.4em;
  display: inline-block;
  float: right;
  margin-left: 1em;
}
.button.arrow-only::after {
  top: -0.1em;
  float: none;
  margin-left: 0;
}

[type=text], [type=password], [type=date], [type=datetime], [type=datetime-local], [type=month], [type=week], [type=email], [type=number], [type=search], [type=tel], [type=time], [type=url], [type=color],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 3.5454545455rem;
  margin: 0 0 1.4545454545rem;
  padding: 0.7272727273rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1.4545454545rem;
  font-weight: normal;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none;
}
[type=text]:focus, [type=password]:focus, [type=date]:focus, [type=datetime]:focus, [type=datetime-local]:focus, [type=month]:focus, [type=week]:focus, [type=email]:focus, [type=number]:focus, [type=search]:focus, [type=tel]:focus, [type=time]:focus, [type=url]:focus, [type=color]:focus,
textarea:focus {
  outline: none;
  border: 1px solid #8a8a8a;
  background-color: #fefefe;
  box-shadow: 0 0 5px #cacaca;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

textarea {
  max-width: 100%;
}
textarea[rows] {
  height: auto;
}

input::placeholder,
textarea::placeholder {
  color: #cacaca;
}
input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed;
}

[type=submit],
[type=button] {
  appearance: none;
  border-radius: 0;
}

input[type=search] {
  box-sizing: border-box;
}

[type=file],
[type=checkbox],
[type=radio] {
  margin: 0 0 1.4545454545rem;
}

[type=checkbox] + label,
[type=radio] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.7272727273rem;
  margin-right: 1.4545454545rem;
  margin-bottom: 0;
}
[type=checkbox] + label[for],
[type=radio] + label[for] {
  cursor: pointer;
}

label > [type=checkbox],
label > [type=radio] {
  margin-right: 0.7272727273rem;
}

[type=file] {
  width: 100%;
}

label {
  display: block;
  margin: 0;
  font-size: 1.2727272727rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a;
}
label.middle {
  margin: 0 0 1.4545454545rem;
  padding: 0.8181818182rem 0;
}

.help-text {
  margin-top: -0.7272727273rem;
  font-size: 1.1818181818rem;
  font-style: italic;
  color: #0a0a0a;
}

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1.4545454545rem;
  align-items: stretch;
}
.input-group > :first-child {
  border-radius: 0 0 0 0;
}
.input-group > :last-child > * {
  border-radius: 0 0 0 0;
}

.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label, .input-group-button, .input-group-field, .input-group-label {
  margin: 0;
  white-space: nowrap;
}

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}
.input-group-label:first-child {
  border-right: 0;
}
.input-group-label:last-child {
  border-left: 0;
}

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0;
}

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  flex: 0 0 auto;
}
.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  height: 3.6363636364rem;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1.4545454545rem;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  max-width: 100%;
  margin-bottom: 0.7272727273rem;
}

.fieldset {
  margin: 1.6363636364rem 0;
  padding: 1.8181818182rem;
  border: 1px solid #cacaca;
}
.fieldset legend {
  margin: 0;
  margin-left: -0.2727272727rem;
  padding: 0 0.2727272727rem;
  background: #fefefe;
}

select {
  height: 3.5454545455rem;
  margin: 0 0 1.4545454545rem;
  padding: 0.7272727273rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 1.4545454545rem;
  line-height: normal;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1.4545454545rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 2.1818181818rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}
@media screen and (min-width: 0\0 ) {
  select {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg==");
  }
}
select:focus {
  outline: none;
  border: 1px solid #8a8a8a;
  background-color: #fefefe;
  box-shadow: 0 0 5px #cacaca;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}
select:disabled {
  background-color: #e6e6e6;
  cursor: not-allowed;
}
select::-ms-expand {
  display: none;
}
select[multiple] {
  height: auto;
  background-image: none;
}

.is-invalid-input:not(:focus) {
  border-color: #ec5840;
  background-color: #fcedeb;
}
.is-invalid-input:not(:focus)::placeholder {
  color: #ec5840;
}

.is-invalid-label {
  color: #ec5840;
}

.form-error {
  display: none;
  margin-top: -0.7272727273rem;
  margin-bottom: 1.4545454545rem;
  font-size: 1.0909090909rem;
  font-weight: bold;
  color: #ec5840;
}
.form-error.is-visible {
  display: block;
}

.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none;
}

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0;
}
.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0;
}

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 1.0909090909rem;
  line-height: 1;
  color: #002546;
}
:last-child:not(.is-active) > .accordion-title {
  border-bottom: 1px solid #e6e6e6;
  border-radius: 0 0 0 0;
}
.accordion-title:hover, .accordion-title:focus {
  background-color: #e6e6e6;
}
.accordion-title::before {
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: -0.5rem;
  content: "+";
}
.is-active > .accordion-title::before {
  content: "–";
}

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: #0a0a0a;
}
:last-child > .accordion-content:last-child {
  border-bottom: 1px solid #e6e6e6;
}

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #002546;
  color: #fefefe;
}
.badge.primary {
  background: #2199e8;
  color: #0a0a0a;
}
.badge.secondary {
  background: #777;
  color: #fefefe;
}
.badge.success {
  background: #3adb76;
  color: #0a0a0a;
}
.badge.warning {
  background: #ffae00;
  color: #0a0a0a;
}
.badge.alert {
  background: #ec5840;
  color: #0a0a0a;
}

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none;
}
.breadcrumbs::before, .breadcrumbs::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.breadcrumbs::after {
  clear: both;
}
.breadcrumbs li {
  float: left;
  font-size: 1rem;
  color: #0a0a0a;
  cursor: default;
  text-transform: uppercase;
}
.breadcrumbs li:not(:last-child)::after {
  position: relative;
  top: 1px;
  margin: 0 0.75rem;
  opacity: 1;
  content: "/";
  color: #cacaca;
}
.breadcrumbs a {
  color: #002546;
}
.breadcrumbs a:hover {
  text-decoration: underline;
}
.breadcrumbs .disabled {
  color: #cacaca;
  cursor: not-allowed;
}

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
}
.button-group::before, .button-group::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.button-group::after {
  clear: both;
}
.button-group .button {
  margin: 0;
  margin-right: 1px;
  margin-bottom: 1px;
  font-size: 1.55rem;
  flex: 0 0 auto;
}
.button-group .button:last-child {
  margin-right: 0;
}
.button-group.tiny .button {
  font-size: 1rem;
}
.button-group.small .button {
  font-size: 1.2rem;
}
.button-group.large .button {
  font-size: 1.85rem;
}
.button-group.expanded .button {
  flex: 1 1 0px;
}
.button-group.primary .button {
  background-color: #2199e8;
  color: #0a0a0a;
}
.button-group.primary .button:hover, .button-group.primary .button:focus {
  background-color: #147cc0;
  color: #0a0a0a;
}
.button-group.secondary .button {
  background-color: #777;
  color: #fefefe;
}
.button-group.secondary .button:hover, .button-group.secondary .button:focus {
  background-color: #5f5f5f;
  color: #fefefe;
}
.button-group.success .button {
  background-color: #3adb76;
  color: #0a0a0a;
}
.button-group.success .button:hover, .button-group.success .button:focus {
  background-color: #22bb5b;
  color: #0a0a0a;
}
.button-group.warning .button {
  background-color: #ffae00;
  color: #0a0a0a;
}
.button-group.warning .button:hover, .button-group.warning .button:focus {
  background-color: #cc8b00;
  color: #0a0a0a;
}
.button-group.alert .button {
  background-color: #ec5840;
  color: #0a0a0a;
}
.button-group.alert .button:hover, .button-group.alert .button:focus {
  background-color: #da3116;
  color: #0a0a0a;
}
.button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
  flex-wrap: wrap;
}
.button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
  flex: 0 0 100%;
}
.button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 40em) {
  .button-group.stacked-for-small .button {
    flex: 1 1 0px;
    margin-bottom: 0;
  }
}
@media print, screen and (min-width: 64em) {
  .button-group.stacked-for-medium .button {
    flex: 1 1 0px;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 39.9375em) {
  .button-group.stacked-for-small.expanded {
    display: block;
  }
  .button-group.stacked-for-small.expanded .button {
    display: block;
    margin-right: 0;
  }
}

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, 0.25);
  border-radius: 0;
  background-color: white;
  color: #0a0a0a;
}
.callout > :first-child {
  margin-top: 0;
}
.callout > :last-child {
  margin-bottom: 0;
}
.callout.primary {
  background-color: #def0fc;
  color: #0a0a0a;
}
.callout.secondary {
  background-color: #ebebeb;
  color: #0a0a0a;
}
.callout.success {
  background-color: #e1faea;
  color: #0a0a0a;
}
.callout.warning {
  background-color: #fff3d9;
  color: #0a0a0a;
}
.callout.alert {
  background-color: #fce6e2;
  color: #0a0a0a;
}
.callout.small {
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
}
.callout.large {
  padding-top: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  padding-left: 3rem;
}

.card {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  background: #fefefe;
  box-shadow: none;
  overflow: hidden;
  color: #0a0a0a;
}
.card > :last-child {
  margin-bottom: 0;
}

.card-divider {
  flex: 0 1 auto;
  padding: 1rem;
  background: #e6e6e6;
}
.card-divider > :last-child {
  margin-bottom: 0;
}

.card-section {
  flex: 1 0 auto;
  padding: 1rem;
}
.card-section > :last-child {
  margin-bottom: 0;
}

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer;
}
[data-whatinput=mouse] .close-button {
  outline: 0;
}
.close-button:hover, .close-button:focus {
  color: #0a0a0a;
}
.close-button.small {
  right: 0.66rem;
  top: 0.33em;
  font-size: 1.5em;
  line-height: 1;
}
.close-button, .close-button.medium {
  right: 1rem;
  top: 0.5rem;
  font-size: 2em;
  line-height: 1;
}

.menu {
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}
.menu > li {
  flex: 0 0 auto;
}
[data-whatinput=mouse] .menu > li {
  outline: 0;
}
.menu > li > a {
  display: block;
  padding: 0.7rem 1rem;
  line-height: 1;
}
.menu input,
.menu select,
.menu a,
.menu button {
  margin-bottom: 0;
}
.menu > li > a {
  display: flex;
}
.menu > li > a {
  flex-flow: row nowrap;
}
.menu > li > a img,
.menu > li > a i,
.menu > li > a svg {
  margin-right: 0.25rem;
}
.menu, .menu.horizontal {
  flex-wrap: nowrap;
}
.menu > li, .menu.horizontal > li {
  flex: 0 0 auto;
}
.menu.expanded > li {
  flex: 1 1 0px;
}
.menu.expanded > li:first-child:last-child {
  width: 100%;
}
.menu.vertical {
  flex-wrap: wrap;
}
.menu.vertical > li {
  flex: 0 0 100%;
  max-width: 100%;
}
.menu.vertical > li > a {
  justify-content: flex-start;
  align-items: flex-start;
}
@media print, screen and (min-width: 40em) {
  .menu.medium-horizontal {
    flex-wrap: nowrap;
  }
  .menu.medium-horizontal > li {
    flex: 0 0 auto;
  }
  .menu.medium-expanded > li {
    flex: 1 1 0px;
  }
  .menu.medium-expanded > li:first-child:last-child {
    width: 100%;
  }
  .menu.medium-vertical {
    flex-wrap: wrap;
  }
  .menu.medium-vertical > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .menu.medium-vertical > li > a {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
@media print, screen and (min-width: 64em) {
  .menu.large-horizontal {
    flex-wrap: nowrap;
  }
  .menu.large-horizontal > li {
    flex: 0 0 auto;
  }
  .menu.large-expanded > li {
    flex: 1 1 0px;
  }
  .menu.large-expanded > li:first-child:last-child {
    width: 100%;
  }
  .menu.large-vertical {
    flex-wrap: wrap;
  }
  .menu.large-vertical > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .menu.large-vertical > li > a {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
@media screen and (min-width: 75em) {
  .menu.xlarge-horizontal {
    flex-wrap: nowrap;
  }
  .menu.xlarge-horizontal > li {
    flex: 0 0 auto;
  }
  .menu.xlarge-expanded > li {
    flex: 1 1 0px;
  }
  .menu.xlarge-expanded > li:first-child:last-child {
    width: 100%;
  }
  .menu.xlarge-vertical {
    flex-wrap: wrap;
  }
  .menu.xlarge-vertical > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .menu.xlarge-vertical > li > a {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
@media screen and (min-width: 90em) {
  .menu.xxlarge-horizontal {
    flex-wrap: nowrap;
  }
  .menu.xxlarge-horizontal > li {
    flex: 0 0 auto;
  }
  .menu.xxlarge-expanded > li {
    flex: 1 1 0px;
  }
  .menu.xxlarge-expanded > li:first-child:last-child {
    width: 100%;
  }
  .menu.xxlarge-vertical {
    flex-wrap: wrap;
  }
  .menu.xxlarge-vertical > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .menu.xxlarge-vertical > li > a {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.menu.simple li {
  display: inline-block;
  margin-right: 1rem;
  line-height: 1;
}
.menu.simple a {
  padding: 0;
}
.menu.align-right {
  justify-content: flex-end;
}
.menu.icon-top > li > a {
  flex-flow: column nowrap;
}
.menu.icon-top > li > a img,
.menu.icon-top > li > a i,
.menu.icon-top > li > a svg {
  align-self: stretch;
  margin-bottom: 0.25rem;
  text-align: center;
}
.menu.icon-top.vertical a > span {
  margin: auto;
}
.menu.nested {
  margin-left: 1rem;
}
.menu .active > a {
  background: inherit;
  color: #379fcb;
}
.menu.menu-bordered li {
  border: 1px solid #e6e6e6;
}
.menu.menu-bordered li:not(:first-child) {
  border-top: 0;
}
.menu.menu-hover li:hover {
  background-color: #e6e6e6;
}

.menu-text {
  padding-top: 0;
  padding-bottom: 0;
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit;
}

.menu-centered {
  text-align: center;
}
.menu-centered > .menu {
  display: inline-block;
}

.no-js [data-responsive-menu] ul {
  display: none;
}

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer;
}
.menu-icon::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #002546;
  box-shadow: 0 7px 0 #002546, 0 14px 0 #002546;
  content: "";
}
.menu-icon:hover::after {
  background: #cacaca;
  box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca;
}

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer;
}
.menu-icon.dark::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #0a0a0a;
  box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
  content: "";
}
.menu-icon.dark:hover::after {
  background: #8a8a8a;
  box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a;
}

.is-drilldown {
  position: relative;
  overflow: hidden;
}
.is-drilldown li {
  display: block;
}
.is-drilldown.animate-height {
  transition: height 0.5s;
}

.is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fefefe;
  transition: transform 0.15s linear;
}
.is-drilldown-submenu.is-active {
  z-index: 1;
  display: block;
  transform: translateX(-100%);
}
.is-drilldown-submenu.is-closing {
  transform: translateX(100%);
}

.drilldown-submenu-cover-previous {
  min-height: 100%;
}

.is-drilldown-submenu-parent > a {
  position: relative;
}
.is-drilldown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #002546;
  position: absolute;
  top: 50%;
  margin-top: -6px;
  right: 1rem;
}

.js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #002546 transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0;
}

.dropdown-pane {
  position: absolute;
  z-index: 10;
  display: block;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  border: 0;
  border-radius: 0;
  background-color: #fefefe;
  font-size: inherit;
}
.dropdown-pane.is-open {
  visibility: visible;
}

.dropdown-pane.tiny {
  width: 100px;
}

.dropdown-pane.small {
  width: 200px;
}

.dropdown-pane.large {
  width: 400px;
}

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto;
}
.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0;
}
[data-whatinput=mouse] .dropdown.menu a {
  outline: 0;
}
.no-js .dropdown.menu ul {
  display: none;
}
.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0;
}
.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
}
.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%;
}
@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
  }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
  }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
}
@media screen and (min-width: 75em) {
  .dropdown.menu.xlarge-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.xlarge-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.xlarge-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.xlarge-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
  }
  .dropdown.menu.xlarge-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
}
@media screen and (min-width: 90em) {
  .dropdown.menu.xxlarge-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.xxlarge-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.xxlarge-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.xxlarge-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
  }
  .dropdown.menu.xxlarge-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
}
.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto;
}

.is-dropdown-menu.vertical {
  width: 100px;
}
.is-dropdown-menu.vertical.align-right {
  float: right;
}

.is-dropdown-submenu-parent {
  position: relative;
}
.is-dropdown-submenu-parent a::after {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -6px;
}
.is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
  top: 100%;
  left: auto;
}
.is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
}
.is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%;
}

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 0;
  background: #fefefe;
}
.is-dropdown-submenu .is-dropdown-submenu {
  margin-top: -1px;
}
.is-dropdown-submenu > li {
  width: 100%;
}
.is-dropdown-submenu.js-dropdown-active {
  display: block;
}

.responsive-embed, .flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1.4545454545rem;
  padding-bottom: 75%;
  overflow: hidden;
}
.responsive-embed iframe,
.responsive-embed object,
.responsive-embed embed,
.responsive-embed video, .flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.responsive-embed.widescreen, .flex-video.widescreen {
  padding-bottom: 56.25%;
}

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #002546;
  color: #fefefe;
}
.label.primary {
  background: #2199e8;
  color: #0a0a0a;
}
.label.secondary {
  background: #777;
  color: #fefefe;
}
.label.success {
  background: #3adb76;
  color: #0a0a0a;
}
.label.warning {
  background: #ffae00;
  color: #0a0a0a;
}
.label.alert {
  background: #ec5840;
  color: #0a0a0a;
}

.media-object {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: nowrap;
}
.media-object img {
  max-width: none;
}
@media screen and (max-width: 39.9375em) {
  .media-object.stack-for-small {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 39.9375em) {
  .media-object.stack-for-small .media-object-section {
    padding: 0;
    padding-bottom: 1rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .media-object.stack-for-small .media-object-section img {
    width: 100%;
  }
}

.media-object-section {
  flex: 0 1 auto;
}
.media-object-section:first-child {
  padding-right: 1rem;
}
.media-object-section:last-child:not(:nth-child(2)) {
  padding-left: 1rem;
}
.media-object-section > :last-child {
  margin-bottom: 0;
}
.media-object-section.main-section {
  flex: 1 1 0px;
}

.is-off-canvas-open {
  overflow: hidden;
}

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(254, 254, 254, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}
.js-off-canvas-overlay.is-visible {
  opacity: 1;
  visibility: visible;
}
.js-off-canvas-overlay.is-closable {
  cursor: pointer;
}
.js-off-canvas-overlay.is-overlay-absolute {
  position: absolute;
}
.js-off-canvas-overlay.is-overlay-fixed {
  position: fixed;
}

.off-canvas-wrapper {
  position: relative;
  overflow: hidden;
}

.off-canvas {
  position: fixed;
  z-index: 1;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6;
}
[data-whatinput=mouse] .off-canvas {
  outline: 0;
}
.off-canvas.is-transition-overlap {
  z-index: 10;
}
.off-canvas.is-transition-overlap.is-open {
  box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
}
.off-canvas.is-open {
  transform: translate(0, 0);
}

.off-canvas-absolute {
  position: absolute;
  z-index: 1;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6;
}
[data-whatinput=mouse] .off-canvas-absolute {
  outline: 0;
}
.off-canvas-absolute.is-transition-overlap {
  z-index: 10;
}
.off-canvas-absolute.is-transition-overlap.is-open {
  box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
}
.off-canvas-absolute.is-open {
  transform: translate(0, 0);
}

.position-left {
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  transform: translateX(-250px);
  overflow-y: auto;
}
.position-left.is-open ~ .off-canvas-content {
  transform: translateX(250px);
}
.position-left.is-transition-push::after {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 1px;
  box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
  content: " ";
}
.position-left.is-transition-overlap.is-open ~ .off-canvas-content {
  transform: none;
}

.position-right {
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  transform: translateX(250px);
  overflow-y: auto;
}
.position-right.is-open ~ .off-canvas-content {
  transform: translateX(-250px);
}
.position-right.is-transition-push::after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 1px;
  box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
  content: " ";
}
.position-right.is-transition-overlap.is-open ~ .off-canvas-content {
  transform: none;
}

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(-250px);
  overflow-x: auto;
}
.position-top.is-open ~ .off-canvas-content {
  transform: translateY(250px);
}
.position-top.is-transition-push::after {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
  content: " ";
}
.position-top.is-transition-overlap.is-open ~ .off-canvas-content {
  transform: none;
}

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(250px);
  overflow-x: auto;
}
.position-bottom.is-open ~ .off-canvas-content {
  transform: translateY(-250px);
}
.position-bottom.is-transition-push::after {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 100%;
  box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
  content: " ";
}
.position-bottom.is-transition-overlap.is-open ~ .off-canvas-content {
  transform: none;
}

.off-canvas-content {
  transition: transform 0.5s ease;
  backface-visibility: hidden;
}

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 1;
  }
  .position-left.reveal-for-medium ~ .off-canvas-content {
    margin-left: 250px;
  }

  .position-right.reveal-for-medium {
    transform: none;
    z-index: 1;
  }
  .position-right.reveal-for-medium ~ .off-canvas-content {
    margin-right: 250px;
  }

  .position-top.reveal-for-medium {
    transform: none;
    z-index: 1;
  }
  .position-top.reveal-for-medium ~ .off-canvas-content {
    margin-top: 250px;
  }

  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 1;
  }
  .position-bottom.reveal-for-medium ~ .off-canvas-content {
    margin-bottom: 250px;
  }
}
@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 1;
  }
  .position-left.reveal-for-large ~ .off-canvas-content {
    margin-left: 250px;
  }

  .position-right.reveal-for-large {
    transform: none;
    z-index: 1;
  }
  .position-right.reveal-for-large ~ .off-canvas-content {
    margin-right: 250px;
  }

  .position-top.reveal-for-large {
    transform: none;
    z-index: 1;
  }
  .position-top.reveal-for-large ~ .off-canvas-content {
    margin-top: 250px;
  }

  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 1;
  }
  .position-bottom.reveal-for-large ~ .off-canvas-content {
    margin-bottom: 250px;
  }
}
@media screen and (min-width: 75em) {
  .position-left.reveal-for-xlarge {
    transform: none;
    z-index: 1;
  }
  .position-left.reveal-for-xlarge ~ .off-canvas-content {
    margin-left: 250px;
  }

  .position-right.reveal-for-xlarge {
    transform: none;
    z-index: 1;
  }
  .position-right.reveal-for-xlarge ~ .off-canvas-content {
    margin-right: 250px;
  }

  .position-top.reveal-for-xlarge {
    transform: none;
    z-index: 1;
  }
  .position-top.reveal-for-xlarge ~ .off-canvas-content {
    margin-top: 250px;
  }

  .position-bottom.reveal-for-xlarge {
    transform: none;
    z-index: 1;
  }
  .position-bottom.reveal-for-xlarge ~ .off-canvas-content {
    margin-bottom: 250px;
  }
}
@media screen and (min-width: 90em) {
  .position-left.reveal-for-xxlarge {
    transform: none;
    z-index: 1;
  }
  .position-left.reveal-for-xxlarge ~ .off-canvas-content {
    margin-left: 250px;
  }

  .position-right.reveal-for-xxlarge {
    transform: none;
    z-index: 1;
  }
  .position-right.reveal-for-xxlarge ~ .off-canvas-content {
    margin-right: 250px;
  }

  .position-top.reveal-for-xxlarge {
    transform: none;
    z-index: 1;
  }
  .position-top.reveal-for-xxlarge ~ .off-canvas-content {
    margin-top: 250px;
  }

  .position-bottom.reveal-for-xxlarge {
    transform: none;
    z-index: 1;
  }
  .position-bottom.reveal-for-xxlarge ~ .off-canvas-content {
    margin-bottom: 250px;
  }
}
.orbit {
  position: relative;
}

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
}

.orbit-slide {
  width: 100%;
}
.orbit-slide.no-motionui.is-active {
  top: 0;
  left: 0;
}

.orbit-figure {
  margin: 0;
}

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(10, 10, 10, 0.5);
  color: #fefefe;
}

.orbit-next, .orbit-previous {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #fefefe;
}
[data-whatinput=mouse] .orbit-next, [data-whatinput=mouse] .orbit-previous {
  outline: 0;
}
.orbit-next:hover, .orbit-previous:hover, .orbit-next:active, .orbit-previous:active, .orbit-next:focus, .orbit-previous:focus {
  background-color: rgba(10, 10, 10, 0.5);
}

.orbit-previous {
  left: 0;
}

.orbit-next {
  left: auto;
  right: 0;
}

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center;
}
[data-whatinput=mouse] .orbit-bullets {
  outline: 0;
}
.orbit-bullets button {
  width: 1.2rem;
  height: 1.2rem;
  margin: 0.1rem;
  border-radius: 50%;
  background-color: #cacaca;
}
.orbit-bullets button:hover {
  background-color: #8a8a8a;
}
.orbit-bullets button.is-active {
  background-color: #8a8a8a;
}

.pagination {
  margin-left: 0;
  margin-bottom: 1rem;
}
.pagination::before, .pagination::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.pagination::after {
  clear: both;
}
.pagination li {
  margin-right: 0.0909090909rem;
  border-radius: 0;
  font-size: 1.2727272727rem;
  display: none;
}
.pagination li:last-child, .pagination li:first-child {
  display: inline-block;
}
@media print, screen and (min-width: 40em) {
  .pagination li {
    display: inline-block;
  }
}
.pagination a,
.pagination button {
  display: block;
  padding: 0.2727272727rem 0.9090909091rem;
  border-radius: 0;
  color: #0a0a0a;
}
.pagination a:hover,
.pagination button:hover {
  background: #e6e6e6;
}
.pagination .current {
  padding: 0.2727272727rem 0.9090909091rem;
  background: #002546;
  color: #fefefe;
  cursor: default;
}
.pagination .disabled {
  padding: 0.2727272727rem 0.9090909091rem;
  color: #cacaca;
  cursor: not-allowed;
}
.pagination .disabled:hover {
  background: transparent;
}
.pagination .ellipsis::after {
  padding: 0.2727272727rem 0.9090909091rem;
  content: "…";
  color: #0a0a0a;
}

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: "«";
}

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: "»";
}

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #cacaca;
}
.progress.primary .progress-meter {
  background-color: #2199e8;
}
.progress.secondary .progress-meter {
  background-color: #777;
}
.progress.success .progress-meter {
  background-color: #3adb76;
}
.progress.warning .progress-meter {
  background-color: #ffae00;
}
.progress.alert .progress-meter {
  background-color: #ec5840;
}

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #002546;
}

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fefefe;
  white-space: nowrap;
}

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none;
}

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out;
}
.slider-fill.is-dragging {
  transition: all 0s linear;
}

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #002546;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation;
}
[data-whatinput=mouse] .slider-handle {
  outline: 0;
}
.slider-handle:hover {
  background-color: #001f3c;
}
.slider-handle.is-dragging {
  transition: all 0s linear;
}

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1);
}
.slider.vertical .slider-fill {
  top: 0;
  width: 0.5rem;
  max-height: 100%;
}
.slider.vertical .slider-handle {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1.4rem;
  height: 1.4rem;
  transform: translateX(-50%);
}

.sticky-container {
  position: relative;
}

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0);
}

.sticky.is-stuck {
  position: fixed;
  z-index: 5;
}
.sticky.is-stuck.is-at-top {
  top: 0;
}
.sticky.is-stuck.is-at-bottom {
  bottom: 0;
}

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto;
}
.sticky.is-anchored.is-at-bottom {
  bottom: 0;
}

body.is-reveal-open {
  overflow: hidden;
}

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  user-select: none;
}

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll;
}

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto;
}
[data-whatinput=mouse] .reveal {
  outline: 0;
}
@media print, screen and (min-width: 40em) {
  .reveal {
    min-height: 0;
  }
}
.reveal .column,
.reveal .columns {
  min-width: 0;
}
.reveal > :last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 40em) {
  .reveal {
    width: 600px;
    max-width: 127.2727272727rem;
  }
}
@media print, screen and (min-width: 40em) {
  .reveal .reveal {
    right: auto;
    left: auto;
    margin: 0 auto;
  }
}
.reveal.collapse {
  padding: 0;
}
@media print, screen and (min-width: 40em) {
  .reveal.tiny {
    width: 30%;
    max-width: 127.2727272727rem;
  }
}
@media print, screen and (min-width: 40em) {
  .reveal.small {
    width: 50%;
    max-width: 127.2727272727rem;
  }
}
@media print, screen and (min-width: 40em) {
  .reveal.large {
    width: 90%;
    max-width: 127.2727272727rem;
  }
}
.reveal.full {
  top: 0;
  left: 0;
  width: 100%;
  max-width: none;
  height: 100%;
  height: 100vh;
  min-height: 100vh;
  margin-left: 0;
  border: 0;
  border-radius: 0;
}
@media screen and (max-width: 39.9375em) {
  .reveal {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0;
  }
}
.reveal.without-overlay {
  position: fixed;
}

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 1.2727272727rem;
  font-weight: bold;
  color: #fefefe;
  user-select: none;
}

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0;
}

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer;
}
input + .switch-paddle {
  margin: 0;
}
.switch-paddle::after {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  transform: translate3d(0, 0, 0);
  border-radius: 0;
  background: #fefefe;
  transition: all 0.25s ease-out;
  content: "";
}
input:checked ~ .switch-paddle {
  background: #002546;
}
input:checked ~ .switch-paddle::after {
  left: 2.25rem;
}
[data-whatinput=mouse] input:focus ~ .switch-paddle {
  outline: 0;
}

.switch-inactive, .switch-active {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.switch-active {
  left: 8%;
  display: none;
}
input:checked + label > .switch-active {
  display: block;
}

.switch-inactive {
  right: 15%;
}
input:checked + label > .switch-inactive {
  display: none;
}

.switch.tiny {
  height: 1.5rem;
}
.switch.tiny .switch-paddle {
  width: 3rem;
  height: 1.5rem;
  font-size: 0.9090909091rem;
}
.switch.tiny .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 1rem;
  height: 1rem;
}
.switch.tiny input:checked ~ .switch-paddle::after {
  left: 1.75rem;
}

.switch.small {
  height: 1.75rem;
}
.switch.small .switch-paddle {
  width: 3.5rem;
  height: 1.75rem;
  font-size: 1.0909090909rem;
}
.switch.small .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
}
.switch.small input:checked ~ .switch-paddle::after {
  left: 2rem;
}

.switch.large {
  height: 2.5rem;
}
.switch.large .switch-paddle {
  width: 5rem;
  height: 2.5rem;
  font-size: 1.4545454545rem;
}
.switch.large .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 2rem;
  height: 2rem;
}
.switch.large input:checked ~ .switch-paddle::after {
  left: 2.75rem;
}

table {
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0;
}
thead,
tbody,
tfoot {
  border: 0;
  background-color: #fefefe;
}

caption {
  padding: 0.7272727273rem 0.9090909091rem 0.9090909091rem;
  font-weight: bold;
}

thead {
  background: #f8f8f8;
  color: #0a0a0a;
}

tfoot {
  background: #f1f1f1;
  color: #0a0a0a;
}

thead tr,
tfoot tr {
  background: transparent;
}
thead th,
thead td,
tfoot th,
tfoot td {
  padding: 0.7272727273rem 0.9090909091rem 0.9090909091rem;
  font-weight: bold;
  text-align: left;
}

tbody th,
tbody td {
  padding: 0.7272727273rem 0.9090909091rem 0.9090909091rem;
}

tbody tr {
  border-bottom: 0;
  border-bottom: 0;
  background-color: #fefefe;
}

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none;
  }
  table.stack tfoot {
    display: none;
  }
  table.stack tr,
table.stack th,
table.stack td {
    display: block;
  }
  table.stack td {
    border-top: 0;
  }
}

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto;
}

table.hover thead tr:hover {
  background-color: #f3f3f3;
}
table.hover tfoot tr:hover {
  background-color: #ececec;
}
table.hover tbody tr:hover {
  background-color: #f9f9f9;
}

.table-scroll {
  overflow-x: auto;
}
.table-scroll table {
  width: auto;
}

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #fefefe;
  list-style-type: none;
}
.tabs::before, .tabs::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.tabs::after {
  clear: both;
}

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto;
}

.tabs.simple > li > a {
  padding: 0;
}
.tabs.simple > li > a:hover {
  background: transparent;
}

.tabs.primary {
  background: #002546;
}
.tabs.primary > li > a {
  color: #fefefe;
}
.tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
  background: #00315c;
}

.tabs-title {
  float: left;
}
.tabs-title > a {
  display: block;
  padding: 1.25rem 1.5rem;
  font-size: 1.0909090909rem;
  line-height: 1;
  color: #002546;
}
.tabs-title > a:hover {
  background: #fefefe;
  color: #00203c;
}
.tabs-title > a:focus, .tabs-title > a[aria-selected=true] {
  background: #e6e6e6;
  color: #002546;
}

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #fefefe;
  color: #0a0a0a;
  transition: all 0.5s ease;
}

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0;
}

.tabs-panel {
  display: none;
  padding: 1rem;
}
.tabs-panel[aria-hidden=false] {
  display: block;
}

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fefefe;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  line-height: 0;
}

a.thumbnail {
  transition: box-shadow 200ms ease-out;
}
a.thumbnail:hover, a.thumbnail:focus {
  box-shadow: 0 0 6px 1px rgba(0, 37, 70, 0.5);
}
a.thumbnail image {
  box-shadow: none;
}

.title-bar {
  padding: 0.5rem;
  background: #fefefe;
  color: #002546;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-bar .menu-icon {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.title-bar-left,
.title-bar-right {
  flex: 1 1 0px;
}

.title-bar-right {
  text-align: right;
}

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
}

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help;
}

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #0a0a0a;
  font-size: 80%;
  color: #fefefe;
}
.tooltip::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: "";
  border-top-width: 0;
  border-bottom-style: solid;
  border-color: transparent transparent #0a0a0a;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.tooltip.top::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: "";
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #0a0a0a transparent transparent;
  top: 100%;
  bottom: auto;
}
.tooltip.left::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: "";
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #0a0a0a;
  top: 50%;
  bottom: auto;
  left: 100%;
  transform: translateY(-50%);
}
.tooltip.right::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: "";
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #0a0a0a transparent transparent;
  top: 50%;
  right: 100%;
  bottom: auto;
  left: auto;
  transform: translateY(-50%);
}

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap;
}
.top-bar,
.top-bar ul {
  background-color: #e6e6e6;
}
.top-bar input {
  max-width: 200px;
  margin-right: 1rem;
}
.top-bar .input-group-field {
  width: 100%;
  margin-right: 0;
}
.top-bar input.button {
  width: auto;
}
.top-bar .top-bar-left,
.top-bar .top-bar-right {
  flex: 0 0 100%;
  max-width: 100%;
}
@media print, screen and (min-width: 40em) {
  .top-bar {
    flex-wrap: nowrap;
  }
  .top-bar .top-bar-left {
    flex: 1 1 auto;
  }
  .top-bar .top-bar-right {
    flex: 0 1 auto;
  }
}
@media screen and (max-width: 63.9375em) {
  .top-bar.stacked-for-medium {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-medium .top-bar-left,
.top-bar.stacked-for-medium .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 74.9375em) {
  .top-bar.stacked-for-large {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-large .top-bar-left,
.top-bar.stacked-for-large .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 89.9375em) {
  .top-bar.stacked-for-xlarge {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-xlarge .top-bar-left,
.top-bar.stacked-for-xlarge .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.top-bar.stacked-for-xxlarge {
  flex-wrap: wrap;
}
.top-bar.stacked-for-xxlarge .top-bar-left,
.top-bar.stacked-for-xxlarge .top-bar-right {
  flex: 0 0 100%;
  max-width: 100%;
}

.top-bar-title {
  flex: 0 0 auto;
  margin: 1rem;
}

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important;
  }
}

@media screen and (min-width: 75em) {
  .hide-for-xlarge {
    display: none !important;
  }
}

@media screen and (max-width: 74.9375em) {
  .show-for-xlarge {
    display: none !important;
  }
}

@media screen and (min-width: 75em) and (max-width: 89.9375em) {
  .hide-for-xlarge-only {
    display: none !important;
  }
}

@media screen and (max-width: 74.9375em), screen and (min-width: 90em) {
  .show-for-xlarge-only {
    display: none !important;
  }
}

@media screen and (min-width: 90em) {
  .hide-for-xxlarge {
    display: none !important;
  }
}

@media screen and (max-width: 89.9375em) {
  .show-for-xxlarge {
    display: none !important;
  }
}

@media screen and (min-width: 90em) {
  .hide-for-xxlarge-only {
    display: none !important;
  }
}

@media screen and (max-width: 89.9375em) {
  .show-for-xxlarge-only {
    display: none !important;
  }
}

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
}

.show-for-landscape,
.hide-for-portrait {
  display: block !important;
}
@media screen and (orientation: landscape) {
  .show-for-landscape,
.hide-for-portrait {
    display: block !important;
  }
}
@media screen and (orientation: portrait) {
  .show-for-landscape,
.hide-for-portrait {
    display: none !important;
  }
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}
@media screen and (orientation: landscape) {
  .hide-for-landscape,
.show-for-portrait {
    display: none !important;
  }
}
@media screen and (orientation: portrait) {
  .hide-for-landscape,
.show-for-portrait {
    display: block !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.clearfix::before, .clearfix::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.clearfix::after {
  clear: both;
}

.align-right {
  justify-content: flex-end;
}

.align-center {
  justify-content: center;
}

.align-justify {
  justify-content: space-between;
}

.align-spaced {
  justify-content: space-around;
}

.align-top {
  align-items: flex-start;
}

.align-self-top {
  align-self: flex-start;
}

.align-bottom {
  align-items: flex-end;
}

.align-self-bottom {
  align-self: flex-end;
}

.align-middle {
  align-items: center;
}

.align-self-middle {
  align-self: center;
}

.align-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}

.small-order-1 {
  order: 1;
}

.small-order-2 {
  order: 2;
}

.small-order-3 {
  order: 3;
}

.small-order-4 {
  order: 4;
}

.small-order-5 {
  order: 5;
}

.small-order-6 {
  order: 6;
}

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1;
  }

  .medium-order-2 {
    order: 2;
  }

  .medium-order-3 {
    order: 3;
  }

  .medium-order-4 {
    order: 4;
  }

  .medium-order-5 {
    order: 5;
  }

  .medium-order-6 {
    order: 6;
  }
}
@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1;
  }

  .large-order-2 {
    order: 2;
  }

  .large-order-3 {
    order: 3;
  }

  .large-order-4 {
    order: 4;
  }

  .large-order-5 {
    order: 5;
  }

  .large-order-6 {
    order: 6;
  }
}
@media screen and (min-width: 75em) {
  .xlarge-order-1 {
    order: 1;
  }

  .xlarge-order-2 {
    order: 2;
  }

  .xlarge-order-3 {
    order: 3;
  }

  .xlarge-order-4 {
    order: 4;
  }

  .xlarge-order-5 {
    order: 5;
  }

  .xlarge-order-6 {
    order: 6;
  }
}
@media screen and (min-width: 90em) {
  .xxlarge-order-1 {
    order: 1;
  }

  .xxlarge-order-2 {
    order: 2;
  }

  .xxlarge-order-3 {
    order: 3;
  }

  .xxlarge-order-4 {
    order: 4;
  }

  .xxlarge-order-5 {
    order: 5;
  }

  .xxlarge-order-6 {
    order: 6;
  }
}
@font-face {
  font-family: "AUPassataBold";
  src: url("https://fonts.au.dk/fonts/aupassata_bold-webfont.eot");
  /* IE9 Compat Modes */
  src: url("https://fonts.au.dk/fonts/aupassata_bold-webfont.eot?#iefix") format("embedded-opentype"), url("https://fonts.au.dk/fonts/aupassata_bold-webfont.woff2") format("woff2"), url("https://fonts.au.dk/fonts/aupassata_bold-webfont.woff") format("woff"), url("https://fonts.au.dk/fonts/aupassata_bold-webfont.ttf") format("truetype"), url("https://fonts.au.dk/fonts/aupassata_bold-webfont.svg#AUPassataBold") format("svg");
  /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AUPassataLight";
  src: url("https://fonts.au.dk/fonts/aupassata_light-webfont.eot");
  /* IE9 Compat Modes */
  src: url("https://fonts.au.dk/fonts/aupassata_light-webfont.eot?#iefix") format("embedded-opentype"), url("https://fonts.au.dk/fonts/aupassata_light-webfont.woff2") format("woff2"), url("https://fonts.au.dk/fonts/aupassata_light-webfont.woff") format("woff"), url("https://fonts.au.dk/fonts/aupassata_light-webfont.ttf") format("truetype"), url("https://fonts.au.dk/fonts/aupassata_light-webfont.svg#AUPassataLight") format("svg");
  /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AUPassataRegular";
  src: url("https://fonts.au.dk/fonts/aupassata_rg-webfont.eot");
  /* IE9 Compat Modes */
  src: url("https://fonts.au.dk/fonts/aupassata_rg-webfont.eot?#iefix") format("embedded-opentype"), url("https://fonts.au.dk/fonts/aupassata_rg-webfont.woff2") format("woff2"), url("https://fonts.au.dk/fonts/aupassata_rg-webfont.woff") format("woff"), url("https://fonts.au.dk/fonts/aupassata_rg-webfont.ttf") format("truetype"), url("https://fonts.au.dk/fonts/aupassata_rg-webfont.svg#AUPassataRegular") format("svg");
  /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "au_icons";
  src: url("https://fonts.au.dk/fonts/au_icons.eot");
  src: url("https://fonts.au.dk/fonts/au_icons.eot#iefix") format("embedded-opentype"), url("https://fonts.au.dk/fonts/au_icons.woff") format("woff"), url("https://fonts.au.dk/fonts/au_icons.woff2") format("woff"), url("https://fonts.au.dk/fonts/au_icons.ttf") format("truetype"), url("https://fonts.au.dk/fonts/au_icons.svg#au_icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'au_icons';
    src: url('../font/au_icons.svg?70343072#au_icons') format('svg');
  }
}
*/
[class^=icon-]:before,
[class*=" icon-"]:before {
  font-family: "au_icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  cursor: pointer;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-plus:before {
  content: "+";
}

/* '+' */
.icon-minus:before {
  content: "-";
}

/* '-' */
.icon-calendar:before {
  content: "0";
}

/* '0' */
.icon-pencil:before {
  content: "1";
}

/* '1' */
.icon-user:before {
  content: "2";
}

/* '2' */
.icon-print-1:before {
  content: "3";
}

/* '3' */
.icon-download-1:before {
  content: "4";
}

/* '4' */
.icon-upload:before {
  content: "5";
}

/* '5' */
.icon-trash:before {
  content: "6";
}

/* '6' */
.icon-search:before {
  content: "7";
}

/* '7' */
.icon-floppy:before {
  content: "8";
}

/* '8' */
.icon-chat:before {
  content: "9";
}

/* '9' */
.icon-bullet:before {
  content: ">";
}

/* '&gt;' */
.icon-logo:before {
  content: "A";
}

/* 'A' */
.icon-facebook:before {
  content: "F";
}

/* 'F' */
.icon-blackboard:before {
  content: "B";
}

/* 'B' */
.icon-home:before {
  content: "H";
}

/* 'H' */
.icon-linkedin:before {
  content: "I";
}

/* 'I' */
.icon-footer_logo:before {
  content: "L";
}

/* 'L' */
.icon-mail:before {
  content: "M";
}

/* 'M' */
.icon-phone:before {
  content: "P";
}

/* 'P' */
.icon-instagram:before {
  content: "S";
}

/* 'S' */
.icon-twitter:before {
  content: "T";
}

/* 'T' */
.icon-udays:before {
  content: "U";
}

/* 'U' */
.icon-vimeo:before {
  content: "V";
}

/* 'V' */
.icon-youtube:before {
  content: "Y";
}

/* 'Y' */
.icon-systems:before {
  content: "Z";
}

/* 'Z' */
.icon-apply:before {
  content: "a";
}

/* 'a' */
.icon-read:before {
  content: "b";
}

/* 'b' */
.icon-close:before {
  content: "c";
}

/* 'c' */
.icon-download:before {
  content: "d";
}

/* 'd' */
.icon-email:before {
  content: "e";
}

/* 'e' */
.icon-find:before {
  content: "f";
}

/* 'f' */
.icon-obs:before {
  content: "i";
}

/* 'i' */
.icon-info:before {
  content: "j";
}

/* 'j' */
.icon-legal:before {
  content: "l";
}

/* 'l' */
.icon-menu:before {
  content: "m";
}

/* 'm' */
.icon-burger:before {
  content: "n";
}

/* 'n' */
.icon-pin:before {
  content: "o";
}

/* 'o' */
.icon-pin-old:before {
  content: "p";
}

/* 'p' */
.icon-question:before {
  content: "q";
}

/* 'q' */
.icon-rss:before {
  content: "r";
}

/* 'r' */
.icon-subscribe:before {
  content: "s";
}

/* 's' */
.icon-settings:before {
  content: "t";
}

/* 't' */
.icon-www:before {
  content: "w";
}

/* 'w' */
.icon-bullet_rounded:before {
  content: "≥";
}

/* '≥' */
.icon-search::before {
  content: "u";
}

.icon-word::before {
  content: "x";
}

.icon-excell::before {
  content: "y";
}

.icon-acrobat::before {
  content: "z";
}

.aubanner-2:hover:after {
  background-color: #002546 !important;
}

.bg-staff, .bg-dark-staff.aubanner-2:hover:after {
  background-color: #379fcb !important;
}

.bg-student, .bg-dark-student.aubanner-2:hover:after {
  background-color: #8bad3f !important;
}

.bg-shared, .bg-dark-shared.aubanner-2:hover:after {
  background-color: #3ca29e !important;
}

.bg-phd, .bg-dark-phd.aubanner-2:hover:after {
  background-color: #655a9f !important;
}

.bg-red, .bg-dark-red.aubanner-2:hover:after {
  background-color: #e2001a !important;
}

.bg-yellow, .bg-dark-yellow.aubanner-2:hover:after {
  background-color: #fabb00 !important;
}

.bg-orange, .bg-dark-orange.aubanner-2:hover:after {
  background-color: #ee7f00 !important;
}

.bg-magenta, .bg-dark-magenta.aubanner-2:hover:after {
  background-color: #e2007a !important;
}

.bg-gray, .bg-dark-gray.aubanner-2:hover:after {
  background-color: #878787 !important;
}

.bg-lightgray, .bg-dark-gray.aubanner-2:hover:after {
  background-color: #f0efef !important;
  color: #333 !important;
}

.bg-white, .bg-dark-white.aubanner-2:hover:after {
  background-color: #fff !important;
  color: #333;
}

.white.aubanner-2.white:hover:after {
  color: #fff;
}

.white.aubanner-2:after {
  background-color: #f0efef !important;
  color: #333 !important;
}

.white.aubanner-2.white:hover:after {
  color: #fff !important;
}

.bg-light-default {
  background-color: rgba(0, 61, 115, 0.15);
}

.bg-light-student {
  background-color: rgba(139, 173, 63, 0.15);
}

.bg-light-staff {
  background-color: rgba(55, 159, 203, 0.15);
}

.bg-light-shared {
  background-color: rgba(60, 162, 158, 0.15);
}

.bg-light-phd {
  background-color: rgba(101, 90, 159, 0.15);
}

.white.aubanner-2:hover:after, .lightgray.aubanner-2:hover:after {
  background-color: #333 !important;
}

.bg-dark-default {
  background-color: #002546 !important;
}

.button.bg-dark-default:hover {
  background-color: #004079 !important;
}

.bg-dark-primary, .button.bg-primary:hover {
  background-color: #002546 !important;
}

.bg-dark-staff, .button.bg-staff:hover {
  background-color: #003e5c !important;
}

.button.bg-dark-staff:hover {
  background-color: #00608f !important;
}

.bg-dark-student, .button.bg-student:hover {
  background-color: #2a4a0f !important;
}

.button.bg-dark-student:hover {
  background-color: #427418 !important;
}

.bg-dark-shared, .button.bg-shared:hover {
  background-color: #004543 !important;
}

.button.bg-dark-shared:hover {
  background-color: #007875 !important;
}

.bg-dark-phd, .button.bg-phd:hover {
  background-color: #281c41 !important;
}

.button.bg-dark-phd:hover {
  background-color: #3e2b65 !important;
}

.bg-dark-red, .button.bg-red:hover {
  background-color: #5b0c0c !important;
}

.button.bg-dark-red:hover {
  background-color: #881212 !important;
}

.bg-dark-yellow, .button.bg-yellow:hover {
  background-color: #634b03 !important;
}

.button.bg-dark-yellow:hover {
  background-color: #957105 !important;
}

.bg-dark-orange, .button.bg-orange:hover {
  background-color: #5f3408 !important;
}

.button.bg-dark-orange:hover {
  background-color: #8e4e0c !important;
}

.bg-dark-gray, .button.bg-gray:hover {
  background-color: #4b4b4a !important;
}

.bg-dark-lightgray, .button.bg-lightgray:hover {
  background-color: #cbc8c8 !important;
}

.bg-dark-white, .button.bg-white:hover {
  background-color: #f0efef !important;
}

.bg-dark-magenta, .button.bg-magenta:hover {
  background-color: #5f0030 !important;
}

.fg-staff, .button.bg-staff.arrow:after {
  color: #379fcb !important;
}

.fg-student, .button.bg-student.arrow:after {
  color: #8bad3f !important;
}

.fg-shared, .button.bg-shared.arrow:after {
  color: #3ca29e !important;
}

.fg-phd, .button.bg-phd.arrow:after {
  color: #655a9f !important;
}

.fg-red, .button.bg-red.arrow:after {
  color: #e2001a !important;
}

.fg-yellow, .button.bg-yellow.arrow:after {
  color: #fabb00 !important;
}

.fg-orange, .button.bg-orange.arrow:after {
  color: #ee7f00 !important;
}

.fg-magenta, .button.bg-magenta.arrow:after {
  color: #e2007a !important;
}

.fg-gray, .button.bg-gray.arrow:after {
  color: #878787 !important;
}

.fg-white {
  color: #fff !important;
}

.fg-default {
  color: #002546 !important;
}

/*############### GENERIC COLORS AND GRADIENT SETS (Rows, lists etc.) ############ */
.grey_gradient1, .au_grey-bg {
  background-color: #87888a !important;
}

.au_grey-fg {
  color: #87888a !important;
}

.grey_gradient2 {
  background-color: #a5a6a7 !important;
}

.grey_gradient3 {
  background-color: #c3c4c5 !important;
}

.grey_gradient4 {
  background-color: #e1e1e2 !important;
}

.cyan_gradient1, .au_cyan-bg {
  background-color: #009ee0 !important;
}

.au_cyan-fg {
  color: #009ee0 !important;
}

.cyan_gradient2 {
  background-color: #40b6e8 !important;
}

.cyan_gradient3 {
  background-color: #7fceef !important;
}

.cyan_gradient4 {
  background-color: #b2e2f6 !important;
}

.purple_gradient1, .au_purple-bg {
  background-color: #655a9f !important;
}

.au_purple-fg {
  color: #655a9f !important;
}

.purple_gradient2 {
  background-color: #8c83b7 !important;
}

.purple_gradient3 {
  background-color: #b2accf !important;
}

.purple_gradient4 {
  background-color: #d0cde2 !important;
}

.green_gradient1, .au_green-bg {
  background-color: #7ab51d !important;
}

.au_green-fg {
  color: #7ab51d !important;
}

.green_gradient2 {
  background-color: #9bc856 !important;
}

.green_gradient3 {
  background-color: #bcda8e !important;
}

.green_gradient4 {
  background-color: #d7e9bb !important;
}

.blue_gradient1, .au_blue-bg {
  background-color: #003d85 !important;
}

.au_blue-fg {
  color: #003d85 !important;
}

.blue_gradient2 {
  background-color: #406ea4 !important;
}

.blue_gradient3 {
  background-color: #7f9ec2 !important;
}

.blue_gradient4 {
  background-color: #b8c4da !important;
}

.aubanner {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  letter-spacing: 0.04rem;
  z-index: 0;
}
.aubanner.aubanner-2 {
  transition: 0.2s;
  -webkit-transform: translateZ(0);
}
.aubanner.aubanner-2 img {
  width: 100%;
}
.aubanner.aubanner-2 .aubanner-content {
  position: relative;
  padding: 2rem 2rem 4rem 2rem;
}
.aubanner.aubanner-2 a, .aubanner.aubanner-2 h2, .aubanner.aubanner-2 h3, .aubanner.aubanner-2 p {
  color: #fff;
}
.aubanner.aubanner-2:after {
  transition: 0.2s;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  color: #333;
  text-align: center;
  background: #fff;
  position: absolute;
  right: 1.5em;
  bottom: 1.5em;
  line-height: 2.4em;
  content: "≥";
  font-family: "au_icons";
  z-index: 1;
  font-size: 1.2rem;
  margin-top: -16px;
}
.aubanner.aubanner-2:hover:after {
  color: #fff;
}
.aubanner.aubanner-2 h3 {
  font-size: 115%;
  margin: 0 0 0.75em 0;
  font-family: "AUPassataRegular";
}
.aubanner.aubanner-2 p, .aubanner.aubanner-2 ul {
  font-family: Georgia;
  margin-top: 0.75em;
}
.aubanner.aubanner-2 ul {
  margin-left: 1em;
}
.aubanner.aubanner-2 ul li:before {
  color: #fff;
  margin-left: -1.4rem;
  line-height: 2.7em;
}
.aubanner.aubanner-2:before {
  content: " ";
  border: 1px solid #000;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  opacity: 0;
  transition: 0.2s;
}
.aubanner.aubanner-2:hover:before {
  opacity: 0.1;
}
.aubanner.small-margin {
  margin-bottom: 1rem;
}
.aubanner a {
  border-bottom: 0 !important;
  text-decoration: none !important;
}
.aubanner a:hover {
  text-decoration: none !important;
}
.aubanner .aubanner-content-header {
  height: 33%;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 2;
  padding: 0 2em;
}
.aubanner .aubanner-content-header:after, .aubanner .aubanner-content-header .aubanner-2 .aubanner-content:after {
  transition: 0.2s;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  color: #002546;
  text-align: center;
  background: #fff;
  position: absolute;
  top: 50%;
  right: 1.5em;
  line-height: 2.4em;
  content: "≥";
  font-family: "au_icons";
  z-index: 2;
  font-size: 1.2rem;
  margin-top: -16px;
}
.aubanner.hastext .aubanner-content-header:after {
  top: auto;
  bottom: 1.5em;
}
.aubanner.staff .aubanner-content-header:after {
  color: #379fcb;
}
.aubanner.student .aubanner-content-header:after {
  color: #8bad3f;
}
.aubanner.shared .aubanner-content-header:after {
  color: #3ca29e;
}
.aubanner.phd .aubanner-content-header:after {
  color: #655a9f;
}
.aubanner.red .aubanner-content-header:after {
  color: #e2001a;
}
.aubanner.yellow .aubanner-content-header:after {
  color: #fabb00;
}
.aubanner.orange .aubanner-content-header:after {
  color: #ee7f00;
}
.aubanner.gray .aubanner-content-header:after {
  color: #878787;
}
.aubanner.magenta .aubanner-content-header:after {
  color: #e2007a;
}
.aubanner:hover .aubanner-content-header:after, .aubanner.aubanner-2 {
  background-color: #002546;
  color: #fff;
}
.aubanner.staff:hover .aubanner-content-header:after, .aubanner.aubanner-2.staff {
  background-color: #003e5c;
}
.aubanner.student:hover .aubanner-content-header:after, .aubanner.aubanner-2.student {
  background-color: #2a4a0f;
}
.aubanner.shared:hover .aubanner-content-header:after, .aubanner.aubanner-2.shared {
  background-color: #004543;
}
.aubanner.phd:hover .aubanner-content-header:after, .aubanner.aubanner-2.phd {
  background-color: #281c41;
}
.aubanner.red:hover .aubanner-content-header:after, .aubanner.aubanner-2.red {
  background-color: #5b0c0c;
}
.aubanner.yellow:hover .aubanner-content-header:after, .aubanner.aubanner-2.yellow {
  background-color: #634b03;
}
.aubanner.orange:hover .aubanner-content-header:after, .aubanner.aubanner-2.orange {
  background-color: #5f3408;
}
.aubanner.gray:hover .aubanner-content-header:after, .aubanner.aubanner-2.gray {
  background-color: #4b4b4a;
}
.aubanner.magenta:hover .aubanner-content-header:after, .aubanner.aubanner-2.magenta {
  background-color: #5f0030;
}
.aubanner.aubanner-2.white, .aubanner.aubanner-2.lightgray {
  background-color: #fff;
}
.aubanner.aubanner-2.white a, .aubanner.aubanner-2.white p, .aubanner.aubanner-2.white h2, .aubanner.aubanner-2.white h3, .aubanner.aubanner-2.white li:before, .aubanner.aubanner-2.lightgray a, .aubanner.aubanner-2.lightgray p, .aubanner.aubanner-2.lightgray h2, .aubanner.aubanner-2.lightgray h3, .aubanner.aubanner-2.lightgray li:before {
  color: #333;
}
.aubanner.aubanner-2.lightgray {
  background-color: #f0efef;
}
.aubanner .aubanner-content-header-pos {
  height: 100%;
  position: relative;
  display: table;
  margin-right: 4em;
}
.aubanner h2, .aubanner p {
  color: #002546;
}
.aubanner h2 {
  font-family: "AUPassataBold";
  font-size: 1.4vw;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  line-height: 1.2;
}
.aubanner:not(.hastext) p {
  display: none;
}
@media screen and (max-width: 39.9375em) {
  .aubanner h2 {
    font-size: 4.8vw;
  }
  .aubanner:not(.hastext) .aubanner-content, .aubanner:not(.hastext) .aubanner-content-header {
    height: 33%;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .aubanner h2 {
    font-size: 2.4vw;
  }
}
@media screen and (min-width: 75em) {
  .aubanner h2 {
    font-size: 20px;
  }
}
.aubanner p {
  line-height: 1.4;
  margin-top: 1em;
}
.aubanner:hover .aubanner-content-overlay {
  opacity: 0.92;
}
.aubanner .aubanner-content {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 33%;
  transition: 0.2s ease-in-out;
  overflow: hidden;
  bottom: 0;
  background-position: bottom left;
  background-size: 100%;
  -webkit-transform-style: preserve-3d;
}
.aubanner .aubanner-content .aubanner-content-overlay {
  background-color: rgba(255, 255, 255, 0.9);
  height: 100%;
  transition: 0.2s;
}
.aubanner .aubanner-content .aubanner-content-text {
  bottom: 0;
  position: absolute;
  margin-right: 60px;
  display: table-cell;
  vertical-align: middle;
}
.aubanner.hastext .aubanner-content, .aubanner.overlay_full .aubanner-content {
  height: 100%;
}
.aubanner.hastext .aubanner-content-header, .aubanner.overlay_full .aubanner-content-header {
  height: auto;
}
.aubanner.hastext .aubanner-content-header-pos, .aubanner.overlay_full .aubanner-content-header-pos {
  display: block;
}
.aubanner.hastext.overlay_partial_33 .aubanner-content {
  height: 33%;
}
.aubanner.hastext.overlay_partial_33 .aubanner-content-header {
  height: auto;
}
.aubanner.hastext.overlay_partial_33 .aubanner-content-header-pos {
  display: block;
}
.aubanner.overlay_full:not(.hastext) .aubanner-content-header {
  margin-bottom: 1em;
}
.aubanner.overlay_partial_50 .aubanner-content {
  height: 50%;
}
.aubanner.overlay_partial_50 .aubanner-content-header {
  height: 50%;
}
.aubanner.hastext.overlay_partial_50 .aubanner-content {
  height: 50%;
}
.aubanner.hastext.overlay_partial_50 .aubanner-content-header {
  height: auto;
}
.aubanner.hastext.overlay_partial_50 .aubanner-content-header-pos {
  display: block;
}
.aubanner.dark .aubanner-content-overlay {
  background-color: rgba(0, 37, 70, 0.9);
}
.aubanner.dark.student .aubanner-content-overlay {
  background-color: rgba(139, 173, 63, 0.9);
}
.aubanner.dark.shared .aubanner-content-overlay {
  background-color: rgba(60, 162, 158, 0.9);
}
.aubanner.dark.phd .aubanner-content-overlay {
  background-color: rgba(101, 90, 159, 0.9);
}
.aubanner.dark.staff .aubanner-content-overlay {
  background-color: rgba(55, 159, 203, 0.9);
}
.aubanner.dark.yellow .aubanner-content-overlay {
  background-color: rgba(250, 187, 0, 0.9);
}
.aubanner.dark.orange .aubanner-content-overlay {
  background-color: rgba(238, 127, 0, 0.9);
}
.aubanner.dark.red .aubanner-content-overlay {
  background-color: rgba(226, 0, 26, 0.9);
}
.aubanner.dark.magenta .aubanner-content-overlay {
  background-color: rgba(226, 0, 122, 0.9);
}
.aubanner.dark.gray .aubanner-content-overlay {
  background-color: rgba(135, 135, 135, 0.9);
}
.aubanner.dark h2, .aubanner.dark p {
  color: #fff;
}
.aubanner.dark:hover:after {
  background-color: rgba(0, 0, 0, 0.5);
}
.aubanner.light .aubanner-content-overlay {
  background-color: rgba(182, 221, 255, 0.85);
}
.aubanner.light.student .aubanner-content-overlay {
  background-color: rgba(216, 230, 185, 0.85);
}
.aubanner.light.student h2, .aubanner.light.student p {
  color: #2a4a0f;
}
.aubanner.light.student:after {
  color: #2a4a0f;
}
.aubanner.light.student:hover:after {
  background-color: #2a4a0f;
  color: #fff;
}
.aubanner.light.shared .aubanner-content-overlay {
  background-color: rgba(175, 225, 223, 0.85);
}
.aubanner.light.shared h2, .aubanner.light.shared p {
  color: #004543;
}
.aubanner.light.shared:after {
  color: #004543;
}
.aubanner.light.shared:hover:after {
  background-color: #004543;
  color: #fff;
}
.aubanner.light.phd .aubanner-content-overlay {
  background-color: rgba(206, 202, 225, 0.85);
}
.aubanner.light.phd h2, .aubanner.light.phd p {
  color: #281c41;
}
.aubanner.light.phd:after {
  color: #281c41;
}
.aubanner.light.phd:hover:after {
  background-color: #281c41;
  color: #fff;
}
.aubanner.light.staff .aubanner-content-overlay {
  background-color: rgba(197, 227, 240, 0.85);
}
.aubanner.light.staff h2, .aubanner.light.staff p {
  color: #003e5c;
}
.aubanner.light.staff:after {
  color: #003e5c;
}
.aubanner.light.staff:hover:after {
  background-color: #003e5c;
  color: #fff;
}
.aubanner.light.magenta .aubanner-content-overlay {
  background-color: rgba(255, 150, 206, 0.85);
}
.aubanner.light.magenta h2, .aubanner.light.magenta p {
  color: #5f0030;
}
.aubanner.light.magenta:after {
  color: #5f0030;
}
.aubanner.light.magenta:hover:after {
  background-color: #5f0030;
  color: #fff;
}
.aubanner.overlay_none .aubanner-content {
  display: none;
}
.aubanner img:not(.frontEndEditIcons) {
  width: 100%;
  position: relative;
  clip-path: inset(-1px -1px 1px -1px);
}
.aubanner img.frontEndEditIcons {
  z-index: 3;
  position: absolute;
  bottom: 0;
}

.main ul.useful-links {
  overflow: auto;
}
.main ul.useful-links li {
  display: block;
  width: 50%;
  font-family: "AUPassataBold";
  float: left;
  color: #fff;
  font-size: 125%;
  text-align: center;
  background-color: #2a4a0f;
  margin-bottom: 2px !important;
  padding: 0;
  position: relative;
  height: 99px;
  transition: 0.1s;
}
.main ul.useful-links li:nth-child(odd) {
  border-right: 2px solid;
}
.main ul.useful-links li:before {
  content: "";
}
.main ul.useful-links li:hover {
  background-color: #315712;
}
.main ul.useful-links a {
  display: block;
  width: 100%;
  height: 100%;
  color: #fff;
  border: 0;
  padding-top: 3.6em;
}
.main ul.useful-links a:before {
  font-size: 300%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  font-family: "au_icons";
  color: #8bad3f;
}
.main ul.useful-links a:hover {
  border: 0;
}
.main ul.useful-links .icon-blackboard a:before {
  content: "B";
}
.main ul.useful-links .icon-mail a:before {
  content: "M";
}
.main ul.useful-links .icon-book a:before {
  content: "b";
}
.main ul.useful-links .icon-apply a:before {
  content: "a";
}

.cludo-banner .aubanner {
  margin: -15px;
}

.au_dropdown {
  margin-bottom: 1em;
}

.au_button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 1.55rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #002546;
  color: #fefefe;
}
[data-whatinput=mouse] .au_button {
  outline: 0;
}
.au_button:hover, .au_button:focus {
  background-color: #001f3c;
  color: #fefefe;
}
.au_button a {
  color: #fff;
  font-family: "AUPassataRegular", Verdana, sans-serif;
  text-decoration: none !important;
}
.au_dropdown .au_button {
  background-color: inherit;
  margin-bottom: 0;
  width: 100%;
  font-family: "AUPassataRegular";
}

.button {
  position: relative;
  text-decoration: none !important;
  -webkit-appearance: none !important;
}

.stafflinks a {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 1.55rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #002546;
  color: #fefefe;
  color: #fff;
  font-family: "AUPassataRegular", Verdana, sans-serif;
  text-decoration: none !important;
}
[data-whatinput=mouse] .stafflinks a {
  outline: 0;
}
.stafflinks a:hover, .stafflinks a:focus {
  background-color: #001f3c;
  color: #fefefe;
}
.stafflinks li {
  padding-left: 0;
}
.stafflinks li:before {
  content: "" !important;
}

.au_list-fancy-style1 [class^=icon-]:before, .au_list-fancy-style1 [class*=" icon-"]:before {
  font-size: 2.4rem;
  line-height: 1em;
  margin: 0;
  margin-right: 0.35em;
  vertical-align: top;
}

.button[class^=icon-]:before, .button[class*=" icon-"]:before {
  font-size: 2.5rem;
  line-height: 0.85em;
  margin: 0;
  margin-right: 0.35em;
  vertical-align: middle;
}

.button[class*=" icon-"],
.button[class^=icon-] {
  padding: 1.03rem 1em;
}

.button.large[class*=" icon-"]::before,
.button.large[class^=icon-]::before {
  font-size: 4.15rem;
}

.button.large[class*=" icon-"],
.button.large[class^=icon-] {
  padding: 0.735rem 1em;
}

.button.small[class*=" icon-"]::before,
.button.small[class^=icon-]::before {
  font-size: 2.32rem;
}

.button.small[class*=" icon-"],
.button.small[class^=icon-] {
  padding: 0.634rem 1em;
}

.button.tiny[class*=" icon-"]::before,
.button.tiny[class^=icon-]::before {
  font-size: 1.63rem;
}

.button.tiny[class*=" icon-"],
.button.tiny[class^=icon-] {
  padding: 0.657rem 1em;
}

.button.arrow {
  padding-right: 3.4em;
}
.button.arrow:after {
  transition: 0.2s;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  color: #002546;
  text-align: center;
  background: #fff;
  position: absolute;
  right: 1em;
  top: 0.65em;
  line-height: 2em;
  content: "≥";
  font-family: "au_icons";
  font-size: 0.8em;
  margin-top: 0;
}
.button.arrow.large:after {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  font-size: 0.75em;
}
.button.arrow.small:after {
  width: 22px;
  height: 22px;
  border-radius: 11px;
}
.button.arrow.tiny:after {
  width: 18px;
  height: 18px;
  border-radius: 9px;
}

.button.small-margin {
  margin-bottom: 0.25em;
}
.button.large-margin {
  margin-bottom: 30px;
}
.button.arrow.bg-white {
  color: #0a0a0a;
}
.button.arrow.bg-white:hover {
  color: #fff;
}

div[class*=bg-dark] .button.arrow.bg-white {
  color: #0a0a0a;
}
div[class*=bg-dark] .button.arrow.bg-white:hover {
  background-color: rgba(255, 255, 255, 0.85) !important;
  color: #0a0a0a;
  opacity: 1;
}

.bg-dark-white, .button.bg-white:hover {
  background-color: black !important;
  opacity: 0.9;
}

/*########################## FANCY LIST STYLES ########################## */
/* Fancy list style 1:List elements appears as big buttons */
#au_content ul.au_list-fancy-style1 li, .au_misc_related_content ul.au_list-fancy-style1 li {
  display: block;
  background-color: #19a9e4;
  font-family: "AUPassataRegular";
  font-size: 16px;
  text-transform: uppercase;
  background-image: none !important;
  margin-bottom: 1.2em;
  padding: 0 !important;
  white-space: nowrap;
}

ul.au_list-fancy-style1 li:before {
  content: "" !important;
}

#au_content ul.au_list-fancy-style1 li a, .au_misc_related_content ul.au_list-fancy-style1 li a {
  border: 0 !important;
  color: #fff;
  display: block;
  padding: 0.8em;
  text-decoration: none !important;
}

/* POPUP */
.au_news_popup {
  position: fixed;
  width: 732px;
  height: auto;
  top: 42px;
  bottom: 0;
  left: 50%;
  margin-left: -366px;
  z-index: 101;
  background: #fff;
  -webkit-overflow-scrolling: touch;
}
.au_news_popup > div:first-child {
  position: relative;
  overflow: auto;
  outline: none;
}
.au_news_popup.ipad {
  bottom: 0;
  overflow: auto;
  top: 0;
}
.au_news_popup.social {
  bottom: 30px;
}
.au_news_popup.fade {
  z-index: 99;
}
@media only screen and (max-width: 1024px) {
  .au_news_popup {
    width: 100%;
    margin: 0;
    left: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
  }
  .au_news_popup.social {
    bottom: 0;
  }
}

@media print {
  .au_news_popup {
    position: static;
    width: auto;
    margin-left: 0;
  }
  .au_news_popup > div:first-child {
    overflow: visible;
    height: auto !important;
  }

  .au_news_overlay, .au_news_overlay_close, .au_news_socialbar, .au_news-single-print, .au_news-single-language {
    display: none;
  }
}
div.au_news_overlay {
  background-color: #002546;
  height: 42px;
  padding: 12px 0;
  position: fixed;
  width: 732px;
  top: 0;
}
.ipad div.au_news_overlay {
  position: static;
}
@media only screen and (max-width: 1024px) {
  div.au_news_overlay {
    width: 100%;
    position: static;
    font-size: 150%;
    height: 70px;
    padding: 20px 0;
  }
}

div.au_news_overlay_wrapper {
  padding: 1.5rem;
}

div.au_news_socialbar {
  position: fixed;
  bottom: 0;
  width: 732px;
  height: 30px;
  background: #000;
}
@media only screen and (max-width: 1024px) {
  div.au_news_socialbar {
    position: static;
    width: 100%;
  }
}

h1.au_news_overlay_header {
  font-size: 2.8rem;
  line-height: 1em;
  padding: 18px 0;
  text-transform: uppercase;
}

.au_news_overlay_close {
  position: absolute;
  width: 36px;
  height: 36px;
  background: url("../img/news/fancybox_sprite.png");
  z-index: 101;
  top: -18px;
  right: -18px;
  cursor: pointer;
}
@media only screen and (max-width: 1024px) {
  .au_news_overlay_close {
    width: 50px;
    height: 50px;
    background: url("../img/news/close_mobile.png");
    position: fixed;
    z-index: 101;
    top: 90px;
    right: 30px;
    background-size: 50px 50px;
  }
}

/* LIST */
.twoone .column .au_news-list-container .column .au_news-list3-item, .twoone .columns .au_news-list-container .column .au_news-list3-item, .twoone .column .au_news-list-container .columns .au_news-list3-item, .twoone .columns .au_news-list-container .columns .au_news-list3-item {
  min-height: 225px;
}
.twoone .column .au_news-list-container .column .au_news-list4-item, .twoone .columns .au_news-list-container .column .au_news-list4-item, .twoone .column .au_news-list-container .columns .au_news-list4-item, .twoone .columns .au_news-list-container .columns .au_news-list4-item {
  min-height: 225px;
}
.twoone .column .au_news-list-container .column .au_news-list5-item, .twoone .columns .au_news-list-container .column .au_news-list5-item, .twoone .column .au_news-list-container .columns .au_news-list5-item, .twoone .columns .au_news-list-container .columns .au_news-list5-item {
  min-height: 140px;
}

.au_news-list-header {
  position: relative;
}

.au_news-list-container .au_focus {
  max-width: 670px;
  height: 270px;
  background-repeat: no-repeat;
}
.au_news-list-container .au_focus li {
  background-color: rgba(0, 61, 133, 0.75) !important;
}
.au_news-list-container .au_focus.au_focus_small {
  max-width: 330px;
  height: 132px;
}
div.main .au_news-list-container .au_focus.au_focus_small h1 {
  font-size: 16px;
  line-height: 1;
  font-family: "AUPassataRegular";
  text-transform: none;
}

div.au_news-list1-item, div.au_news-list2-item {
  margin-bottom: 2em;
}
div.au_news-list1-item div.au_news-list-item-img, div.au_news-list2-item div.au_news-list-item-img {
  float: none;
  overflow: hidden;
}
div.au_news-list1-item div.au_news-list-item-text, div.au_news-list2-item div.au_news-list-item-text {
  margin-left: 0;
}
div.au_news-list1-item h2, div.au_news-list2-item h2 {
  margin: 0.5em 0;
  font-family: "AUPassataBold";
  font-size: 200%;
}

div.au_news-list3-item div.au_news-list-item-text {
  margin-left: 150px;
}

div.au_news-list5-item div.au_news-list-item-text {
  margin-left: 0;
}

div.au_news-header-list {
  color: #999;
}
div.au_news-header-list ul {
  margin: 0 !important;
}

div.au_news-list3-item, div.au_news-list4-item, div.au_news-list5-item, div.au_news-header-list {
  clear: both;
  margin-bottom: 0;
  overflow: auto;
  padding: 1.36364rem 0;
}
div.au_news-list3-item:last-child, div.au_news-list4-item:last-child, div.au_news-list5-item:last-child, div.au_news-header-list:last-child {
  border: none;
  padding-bottom: 1rem;
}

div.au_news-list-item:last-child {
  border: none;
}

p.au_news-legend {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #636363;
  margin: 0 0 0.5em 0;
}

.au_news_popup p.au_news-legend {
  clear: both;
}

span.au_news-single-print, span.au_news-single-language {
  float: right;
  margin-right: 1em;
  font-family: "AUPassataRegular";
}
span.au_news-single-print a, span.au_news-single-language a {
  color: #fff;
}

div.content div.au_news-list3-item h2, div.content div.au_news-list4-item h2, div.content div.au_news-list5-item h2, div.content .au_news-eventlist1-item a, div#au_related_content div.au_news-list3-item h2, div#au_related_content div.au_news-list4-item h2, div#au_related_content div.au_news-list5-item h2, div#au_related_content .au_news-eventlist1-item a {
  line-height: 1.2em;
  margin: 3px 0 6px 0;
  font-family: "AUPassataBold";
  font-size: 1.4rem;
}
div.content .csc-header.relative, div#au_related_content .csc-header.relative {
  position: relative;
  clear: both;
}
div.content .csc-header.relative > ul, div#au_related_content .csc-header.relative > ul {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
}
div.content .csc-header.relative > ul > li, div#au_related_content .csc-header.relative > ul > li {
  float: right;
  padding: 0;
  margin: 0;
  width: 29px;
  min-height: 29px;
  margin-left: 6px;
  text-indent: -9999px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: initial;
}
div.content .csc-header.relative > ul > li > div, div#au_related_content .csc-header.relative > ul > li > div {
  text-indent: 0;
  cursor: auto;
  margin-top: 29px;
  color: #fff;
  padding: 1em;
  display: none;
  position: relative;
}
div.content .csc-header.relative > ul > li > div h2, div#au_related_content .csc-header.relative > ul > li > div h2 {
  margin: 0 0 0.5em 0;
  padding: 0;
}
div.content .csc-header.relative > ul > li > div a, div#au_related_content .csc-header.relative > ul > li > div a {
  color: #fff;
  border-bottom: 1px dotted #fff;
}
div.content .csc-header.relative > ul > li > div .close, div#au_related_content .csc-header.relative > ul > li > div .close {
  width: 16px;
  height: 16px;
  background-image: url("../img/news/news_sprite.png");
  background-position: -102px -31px;
  cursor: pointer;
  position: absolute;
  top: -22px;
  left: 6px;
}
div.content .csc-header.relative > ul > li.rss, div#au_related_content .csc-header.relative > ul > li.rss {
  background-color: #fe8d22;
  background-image: url("../img/news/news_sprite.png");
  background-position: -30px -57px;
}
div.content .csc-header.relative > ul > li.add, div#au_related_content .csc-header.relative > ul > li.add {
  display: none;
  background-color: #47bd26;
  background-image: url("../img/news/news_sprite.png");
  background-position: 0 0;
}
div.content .csc-header.relative > ul > li.edit, div#au_related_content .csc-header.relative > ul > li.edit {
  background: #47bd26;
  text-indent: 0;
  width: 60px;
  color: #fff;
  text-align: right;
}
div.content .csc-header.relative > ul > li.edit > span, div#au_related_content .csc-header.relative > ul > li.edit > span {
  font-family: "AUPassataRegular";
  font-size: 14px;
  position: absolute;
  left: 12px;
  top: 6px;
}
div.content .csc-header.relative > ul > li.wide, div#au_related_content .csc-header.relative > ul > li.wide {
  width: 220px;
}
div.content .csc-header.relative > ul > li.wide > div, div#au_related_content .csc-header.relative > ul > li.wide > div {
  display: block;
}
div.content .csc-header.relative > ul > li.wide > div input[type=text], div#au_related_content .csc-header.relative > ul > li.wide > div input[type=text] {
  width: 188px;
}
div.content .csc-header.relative > ul > li.wide > div label, div#au_related_content .csc-header.relative > ul > li.wide > div label {
  color: #fff;
}
div.content .csc-header.relative > ul > li.wide.rss, div#au_related_content .csc-header.relative > ul > li.wide.rss {
  background-position: 161px -57px;
}
div.content .csc-header.relative > ul > li.wide.edit > span, div#au_related_content .csc-header.relative > ul > li.wide.edit > span {
  display: none;
}

/* SINGLE */
.au_newsevent-time-text {
  font-weight: bold;
}

.au_newsevent-from-date .au_news_events_weekday {
  text-transform: capitalize;
}

.au_news-single-news-permalink {
  color: #8c8c8c;
  font-size: 85%;
  margin: 1em 0;
  clear: both;
}

.au_news_content {
  font-size: 1.25rem;
}
.au_news_content a {
  border-bottom: 1px dotted #003d73;
}
.au_news_content a:hover {
  border-bottom: 1px solid #000;
  text-decoration: none;
}
.au_news_content p {
  font-size: 1.25rem;
}
.external .au_news_content {
  padding: 1em;
  background: #f0efef;
}
@media only screen and (max-width: 1024px) {
  .au_news_content {
    font-size: 150%;
  }
}
.au_news_content ol li {
  list-style-position: inside !important;
}

@media print {
  .au_news_content {
    font-size: 100%;
  }
}
#disqus_thread {
  margin-top: 2em;
}

/* EVENTS */
.au_news-eventlist1-item {
  margin-bottom: 3em;
}
.au_news-eventlist1-item > div {
  display: table-cell;
  position: relative;
}
.au_news-eventlist1-item .au_news_eventlist1-calendar {
  width: 75px;
  height: 86px;
  color: #939292;
  text-transform: uppercase;
  font-family: "AUPassataRegular";
  background: url("") no-repeat;
  padding-top: 6px;
}
.au_news-eventlist1-item .au_news_eventlist1-calendar span {
  position: absolute;
  display: block;
  text-align: center;
  width: 52px;
}
.au_news-eventlist1-item .au_news_eventlist1-calendar span.multiple {
  display: none;
}
.au_news-eventlist1-item .au_news_eventlist1-calendar .au_news_events_weekday {
  font-size: 13px;
  top: 5px;
  left: 0;
}
.au_news-eventlist1-item .au_news_eventlist1-calendar .au_news_events_date {
  font-size: 30px;
  line-height: 1;
  top: 24px;
  left: 0;
}
.au_news-eventlist1-item .au_news_eventlist1-calendar .au_news_events_month {
  font-size: 13px;
  top: 49px;
  left: 0;
}
.au_news-eventlist1-item .au_news_eventlist1-calendar .au_news_events_date, .au_news-eventlist1-item .au_news_eventlist1-calendar.multiple .au_news_events_date_end {
  font-weight: bold;
  font-family: "AUPassataBold";
  letter-spacing: -2px;
}
.au_news-eventlist1-item .au_news_eventlist1-calendar.multiple {
  background: url("") no-repeat;
  padding-top: 2px;
}
.au_news-eventlist1-item .au_news_eventlist1-calendar.multiple span {
  display: block;
  width: 34px;
}
.au_news-eventlist1-item .au_news_eventlist1-calendar.multiple .au_news_events_weekday {
  font-size: 9px;
  top: 2px;
}
.au_news-eventlist1-item .au_news_eventlist1-calendar.multiple .au_news_events_date {
  top: 20px;
  font-size: 24px;
  line-height: 14px;
  width: 31px;
}
.au_news-eventlist1-item .au_news_eventlist1-calendar.multiple .au_news_events_month {
  top: 34px;
  font-size: 9px;
}
.au_news-eventlist1-item .au_news_eventlist1-calendar.multiple .au_news_events_weekday_end {
  top: 34px;
  left: 31px;
  font-size: 9px;
}
.au_news-eventlist1-item .au_news_eventlist1-calendar.multiple .au_news_events_date_end {
  top: 51px;
  left: 31px;
  font-size: 24px;
  line-height: 14px;
  width: 31px;
}
.au_news-eventlist1-item .au_news_eventlist1-calendar.multiple .au_news_events_month_end {
  top: 65px;
  left: 31px;
  font-size: 9px;
}
.au_news-eventlist1-item .au_news_eventlist1-text span, .au_news-eventlist1-item .au_news-header-list span {
  color: #666;
}

.au_news-eventlist2-item {
  clear: both;
  min-height: 100px;
  margin-bottom: 14px;
  position: relative;
}
.au_news-eventlist2-item .au_padding {
  background: #f3f3f3 !important;
  padding: 14px;
  padding-bottom: 10px;
  min-height: 101px;
}
.au_news-eventlist2-item.au_event-featured {
  margin-right: 2px !important;
}
.au_news-eventlist2-item.au_event-featured .au_padding {
  background: #fff !important;
  -moz-box-shadow: 0 0 5px #888;
  -webkit-box-shadow: 0 0 5px #888;
  box-shadow: 0 0 5px #888;
  margin-left: 5px !important;
  z-index: 999;
  padding: 24px !important;
  margin-right: 0 !important;
}
.au_news-eventlist2-item.au_event-featured .au_padding:hover {
  padding: 23px !important;
}
.au_news-eventlist2-item .savebutton {
  position: absolute;
  display: none;
  right: 10px;
  top: 10px;
  font-family: "AUPassataBold";
  cursor: pointer;
  text-align: center !important;
  font-size: 15px;
}
.au_news-eventlist2-item .savebutton a {
  color: #fff !important;
  border: none !important;
}
.au_news-eventlist2-item:hover .savebutton {
  display: block;
}
.au_news-eventlist2-item .au_news_eventlist2-text span {
  color: #838383;
}
.au_news-eventlist2-item .au_news_events_time {
  color: #333 !important;
}
.au_news-eventlist2-item .au_news_eventlist2-calendar {
  width: 96px;
  height: 78px;
  background: #9b9b9b;
  float: left;
  margin-right: 1em;
  text-shadow: 0px 1px 3px #616161;
  filter: dropshadow(color=#616161, offx=0, offy=1);
  text-transform: uppercase;
  font-family: "AUPassataRegular";
  color: #fff;
}
.au_news-eventlist2-item .au_news_eventlist2-calendar .au_news_events_weekday, .au_news-eventlist2-item .au_news_eventlist2-calendar .au_news_events_month {
  font-size: 18px;
}
.au_news-eventlist2-item .au_news_eventlist2-calendar .au_news_events_date {
  font-size: 24px;
  font-family: "AUPassataBold";
  line-height: 1em;
}
.au_news-eventlist2-item .au_news_eventlist2-calendar span {
  display: block;
  text-align: center;
  width: 52px;
  margin-left: 42px;
}
.au_news-eventlist2-item .au_news_eventlist2-calendar.show-year span {
  display: block;
  text-align: center;
  width: 60px;
  margin-left: 28px;
}
div.main .au_news-eventlist2-item h2 {
  font-size: 18px;
  margin: 0 0 4px 0;
}
div.main .au_news-eventlist2-item h2 a {
  border: none;
}
div.main .au_news-eventlist2-item h2 a:hover {
  border-bottom: 1px solid #003d73;
}

/* BUSINESS CARD */
.au_news_card {
  border: 1px solid #ccc;
  width: 300px;
  font-size: 85%;
  padding: 1em;
  margin: 0.5em 0 1em 0;
}
.au_news_card h2 {
  margin-top: 0;
  font-size: 130%;
}
.au_news_card.left {
  float: left;
  margin: 0.5em 1em 1em 0;
}
.au_news_card.right {
  float: right;
  margin: 0.5em 0 1em 1em;
}
.au_news_card.loading {
  min-height: 60px;
  background-image: url("//cmsdesign.au.dk/design/2008/graphics/gsa/ajax-loader.gif");
  background-repeat: no-repeat;
  background-position: center center;
}

label.checked-partial {
  text-indent: 20px;
  background-image: url("../img/news/news_sprite.png");
  background-repeat: no-repeat;
  background-position: -29px -2px;
  background-size: initial;
}
label.checked-partial input:checked {
  display: none;
}

/* COLLAGE */
.featured-content #news1 img, .featured-content #event1 img, .featured-content #event2 img {
  display: none;
}
.featured-content #news1 .aubanner-content p:last-child span:last-child, .featured-content #news2 .aubanner-content p:last-child span:last-child, .featured-content #news3 .aubanner-content p:last-child span:last-child, .featured-content #news4 .aubanner-content p:last-child span:last-child {
  display: none;
}
.featured-content .news-event .aubanner-content p:nth-last-child(2) {
  margin-bottom: 4rem;
}
.featured-content .news-event .aubanner-content p:last-child {
  position: absolute;
  bottom: 1rem;
}
.featured-content .news-event .aubanner-content p:last-child span {
  font-family: "AUPassataRegular";
  font-size: 1rem;
  display: block;
}
.featured-content .news-event .aubanner-content p:last-child span:last-child {
  margin-right: 6rem;
}

/* CALENDAR */
#maincontent .columns.onetwo:first-child .column:first-child .au_padding, #maincontent .columns.onetwo:first-child .columns:first-child .au_padding {
  overflow: hidden;
}

.au_newsroom_calendar_categories ul {
  margin-top: 0 !important;
  margin-bottom: 1em !important;
}
.au_newsroom_calendar_categories ul.category-child {
  margin-bottom: 0 !important;
  display: none;
}
.au_newsroom_calendar_categories ul .category-child-of-0 {
  display: block !important;
}
.au_newsroom_calendar_categories ul li {
  background-image: none !important;
  padding: 0 !important;
  margin: 0 !important;
  position: relative;
}
.au_newsroom_calendar_categories ul li span {
  position: absolute;
  top: 8px;
  right: 6px;
  width: 12px;
  height: 12px;
  display: block;
  background-image: url("../img/news/news_sprite.png");
  background-position: -15px -45px;
  background-size: inherit;
  cursor: pointer;
}
.au_newsroom_calendar_categories ul li span.folded-out {
  background-position: -1px -45px;
}
.au_newsroom_calendar_categories ul li.category-parent ul {
  display: none;
}
.au_newsroom_calendar_categories ul label {
  padding: 0.3em 0;
  margin: 0;
  display: block;
  font-weight: normal;
}
.au_newsroom_calendar_categories input[type=checkbox] {
  margin: 0 0.6em 0 0;
}

.au_newsroom-addevent {
  position: absolute;
  right: 15px;
  top: -60px;
  font-family: "AUPassataBold";
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  color: #ffffff;
  padding: 7px 12px;
  background-color: #4ac026;
}
.au_newsroom-addevent a {
  color: #fff !important;
  border: 0 !important;
}

.temp-block {
  background: #ccc;
  height: 200px;
  margin-bottom: 30px;
}

/*##############*/
/* --- NEWS --- */
/*##############*/
/* --- GENERAL ELEMENTS --- */
.au_news-legend {
  margin-bottom: 0;
  color: #666;
  font-size: 90%;
  text-transform: uppercase;
}

.main .au_newsroom_calendar_categories ul li:before {
  content: "";
}

/* --- LISTS --- */
.main .au_news-list-item h2, .main .au_news-list3-item h2 {
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.2em;
  margin: 3px 0 6px 0;
}

.related .au_news-list-item h2, .related .au_news-list3-item h2, .related .au_news-list2-item h2 {
  padding: 0;
  font-weight: normal;
  font-size: 125%;
  margin: 3px 0 6px 0;
}

.au_news-list-item img, .au_news-list2-item img {
  float: left;
  clear: left;
  margin: 0 12px 6px 0;
}

.au_news-list-item, .au_news-list3-item {
  margin-bottom: 1em;
  overflow: auto;
  padding: 1.36364rem 0;
}

div.au_news-list-item-img {
  float: left;
}

div.au_news-list-item-img img {
  width: auto;
}

div.au_news-list-item-text {
  margin-left: 108px;
}

div.au_news-list-item {
  clear: both;
}

.main div.au_news-header-list ul li {
  margin-left: 0;
}

/* --- LATEST --- */
div.au_news-latest-container div.au_news-list-item-text {
  margin-left: 168px;
}

/* --- SINGLES --- */
div.au_news-single-item .au_news-legend {
  padding-bottom: 1em;
}

div.au_news-single-item blockquote {
  width: 200px;
  float: right;
  font-family: Georgia;
  font-size: 150%;
  border: 0;
  padding: 0 0 0 36px;
  line-height: 1.2em;
  font-style: italic;
  font-weight: bold;
  background: url("https://cdn.au.dk/2015/au/graphics/sprite_specials.png") -167px -276px no-repeat;
}

div.au_news-single-item blockquote p {
  margin-bottom: 0;
}

div.au_news-single-item blockquote p + p {
  font-size: 11px;
  float: right;
  clear: right;
  background: none;
  font-family: "AUPassataRegular";
  color: #666;
  font-style: normal;
  font-weight: normal;
  margin-top: 0.5em;
  width: 200px;
}

#au_content div.au_news-single2-img-main a, #au_content .news-set1-img1all a, #au_content .au_news-single-sub-images a {
  border: 0;
}

p.au_news-single-subheader {
  font: 18px "AUPassataRegular", verdana !important;
}

.au_news-single-imgcaption, .au_news-single2-imgcaption {
  clear: left;
  float: left;
  margin-right: 22px !important;
  width: 200px;
  font-size: 90%;
  color: #818181;
  padding-bottom: 1.4em;
}

.au_news-single-sub-images {
  float: left;
  width: 210px;
  padding-right: 12px;
}

.au_news-single-img img {
  float: left;
  clear: left;
  margin: 0 18px 0.5em 0;
}

.au_news-single-additional-info dt {
  font-weight: bold;
  padding-top: 1em;
}

.au_news-single-additional-info dt:first-child {
  padding-top: 0;
}

.main .au_news-single-additional-info dd {
  margin-left: 0;
  padding: inherit;
}

.au_news-single-additional-info {
  padding: 2em;
  border-top: 6px solid #073D78;
  margin-top: 2em;
  clear: both;
}

.au_news-single2-item .au_news-single2-imgcaption {
  float: none;
  margin: 0;
  padding: 0.4em 0;
  width: 100%;
}

.au_news-single2-img-main, .news-set1-img1all {
  margin-bottom: 1em;
}

.au_news-single-news-category {
  color: #8C8C8C;
  font-size: 85%;
  margin: 1em 0;
}

/* --- ARCHIVE --- */
.amenu-act, .au_news-amenu-item-year {
  font-weight: bold;
}

/* --- PAGEBROWSER --- */
.au_news-list-browse a, .au_pagebrowser a {
  display: block;
  float: left;
  border: 1px solid #8C8C8C !important;
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 0 0.4em;
  text-decoration: none !important;
}

#au_content .au_news-list-browse a:hover, .au_pagebrowser a:hover {
  border: 1px solid #003d85;
}

.au_news-list-browse, .au_pagebrowser {
  padding-bottom: 1em;
  overflow: auto;
}

/* --- News events --- */
.au_news_events {
  color: #666;
}
.au_news_events a {
  font-family: "AUPassataBold";
  font-size: 1.4rem;
}
.au_news_events dt {
  display: block;
  width: 75px;
  height: 86px;
  text-transform: uppercase;
  font-family: "AUPassataRegular";
  padding-top: 6px;
}
.au_news_events dt span {
  width: 53px;
  display: block;
  text-align: center;
  font-size: 14px;
}
.au_news_events dd {
  position: relative;
  top: -86px;
  margin: 0 0 -40px 5em;
}
.au_news_events dd p {
  color: #212121;
}
.au_news_events .au_news_events_date {
  font-size: 32px;
  font-family: "AUPassataBold";
  line-height: 28px;
  letter-spacing: -2px;
  margin-left: -1px;
  margin-bottom: -5px;
}

.au_news_events_time {
  font-weight: bold;
}

table.au_newsevent-event {
  background: #F0EFEF;
  margin-bottom: 1em;
  width: 100%;
  position: relative;
}
table.au_newsevent-event tbody {
  background: inherit;
}

table.au_newsevent-event td {
  padding: 5px 10px;
}

td.au_newsevent-where-text, td.au_newsevent-date-text, td.au_newsevent-from-time {
  font-weight: bold;
}

div.au_social_sharebox {
  position: relative;
}

ul.au_social_sharing {
  font-family: "AUPassataRegular";
  text-transform: uppercase;
  color: #fff;
  clear: both;
  margin-bottom: 0;
}
ul.au_social_sharing:after {
  display: block;
  content: "";
  clear: both;
}
ul.au_social_sharing.horizontal > li {
  float: left;
}
ul.au_social_sharing.horizontal > li > ul {
  position: absolute;
  bottom: 30px;
}
ul.au_social_sharing.horizontal > li > ul li:nth-child(even) {
  background-color: #004e93;
}
ul.au_social_sharing.horizontal > li > ul li:nth-child(odd) {
  background-color: #003d73;
}
ul.au_social_sharing.horizontal > li > ul li a {
  text-indent: 10px;
}
ul.au_social_sharing.horizontal.services-2 li {
  width: 50%;
}
ul.au_social_sharing.horizontal.services-3 li:first-child, ul.au_social_sharing.horizontal.services-3 li:nth-child(3) {
  width: 33%;
}
ul.au_social_sharing.horizontal.services-3 li:nth-child(2) {
  width: 34%;
}
ul.au_social_sharing.horizontal.services-4 li {
  width: 25%;
}
@media screen and (max-width: 39.9375em) {
  ul.au_social_sharing.horizontal.services-4 li {
    width: 50%;
  }
}
ul.au_social_sharing.horizontal.services-5 li {
  width: 20%;
}
ul.au_social_sharing.icons li a:after {
  color: #fff;
  opacity: 0.8;
  font-family: "au_icons";
  font-size: 18px;
  position: absolute;
  top: 6px;
  right: 6px;
}
ul.au_social_sharing.icons li.email > a:after {
  content: "+";
}
ul.au_social_sharing.icons li.email > a.active:after {
  content: "-";
}
ul.au_social_sharing.icons li.facebook > a:after {
  content: "f";
}
ul.au_social_sharing.icons li.twitter > a:after {
  content: "t";
}
ul.au_social_sharing.icons li.linkedin > a:after {
  content: "i";
}
ul.au_social_sharing.icons li.gmail > a:after, ul.au_social_sharing.icons li.outlook > a:after, ul.au_social_sharing.icons li.outlookcom > a:after, ul.au_social_sharing.icons li.yahoo > a:after {
  content: "";
  text-transform: none;
}
ul.au_social_sharing.spacing > li {
  margin-bottom: 8px;
}
ul.au_social_sharing li {
  font-size: 14px;
  padding-left: 0;
  background-image: none;
  margin: 0;
  line-height: 1;
  list-style: none;
}
ul.au_social_sharing li a {
  border: none !important;
  text-indent: 10px;
  padding: 8px 0;
  color: #fff !important;
  display: block;
}
ul.au_social_sharing li a:hover {
  text-decoration: underline;
}
ul.au_social_sharing li > a {
  position: relative;
}
ul.au_social_sharing li > ul {
  margin: 0;
  display: none;
  width: 100%;
}
ul.au_social_sharing li > ul li:nth-child(odd) {
  background-color: #004e93;
}
ul.au_social_sharing li > ul li:nth-child(even) {
  background-color: #003d73;
}
ul.au_social_sharing li > ul li a {
  text-indent: 20px;
}
ul.au_social_sharing li.email {
  background-color: #003d73;
}
ul.au_social_sharing li.facebook {
  background-color: #3b5998;
}
ul.au_social_sharing li.twitter {
  background-color: #00aced;
}
ul.au_social_sharing li.linkedin {
  background-color: #007bb6;
}
@media only screen and (max-width: 1320px) {
  #au_content ul.au_social_sharing.horizontal li {
    font-size: 12px;
  }
  #au_content ul.au_social_sharing.horizontal li a {
    text-indent: 6px;
  }
  #au_content ul.au_social_sharing.horizontal > li > ul {
    bottom: 28px;
  }
  #au_content ul.au_social_sharing.horizontal.icons li a:after {
    font-size: 16px;
    top: 5px;
    right: 3px;
  }
}
@media only screen and (max-width: 1100px) {
  #au_content ul.au_social_sharing.horizontal.icons li a:after {
    display: none;
  }
}

ul.au_social_linking {
  font-family: "au_icons";
  margin: 0;
}
.footer ul.au_social_linking li:before {
  content: "";
}
ul.au_social_linking.small {
  font-size: 30px;
}
ul.au_social_linking.small li {
  width: 30px;
  height: 30px;
}
ul.au_social_linking.medium {
  font-size: 50px;
}
ul.au_social_linking.medium li {
  width: 50px;
  height: 50px;
}
ul.au_social_linking.large {
  font-size: 70px;
}
ul.au_social_linking.large li {
  width: 70px;
  height: 70px;
}
ul.au_social_linking li {
  float: left;
  padding-left: 0 !important;
  margin: 0;
  line-height: 1;
  text-align: left;
  overflow: hidden;
}
.main ul.au_social_linking li a {
  display: block;
  position: relative;
  border-bottom: none;
}
.main ul.au_social_linking li a:after {
  position: absolute;
  top: 0;
  left: 0;
  text-decoration: none;
}
ul.au_social_linking li.facebook a {
  color: #3b5998 !important;
}
ul.au_social_linking li.facebook a:after {
  content: "F";
}
ul.au_social_linking li.twitter a {
  color: #00aced !important;
}
ul.au_social_linking li.twitter a:after {
  content: "T";
}
ul.au_social_linking li.linkedin a {
  color: #007bb6 !important;
}
ul.au_social_linking li.linkedin a:after {
  content: "I";
}
ul.au_social_linking li.youtube a {
  color: #bb0000 !important;
}
ul.au_social_linking li.youtube a:after {
  content: "Y";
}
ul.au_social_linking li.vimeo a {
  color: #aad450 !important;
}
ul.au_social_linking li.vimeo a:after {
  content: "V";
}
ul.au_social_linking li.instagram a {
  color: #517fa4 !important;
}
ul.au_social_linking li.instagram a:after {
  content: "S";
}

.au_collapsible {
  background-color: #f0eeef;
  padding: 1rem;
  margin-bottom: 1rem;
  position: relative;
  clear: both;
}
.au_collapsible .row {
  background: transparent;
}
.main .au_collapsible h2, .main .au_collapsible h3 {
  margin-top: 0;
}
.au_collapsible > .csc-header {
  cursor: pointer;
  margin-bottom: 2rem;
}
.au_collapsible .csc-header h1, #au_content .column .au_collapsible .csc-header h1 {
  color: #002546;
  border: 0;
  padding: 0 1.5rem 0 0;
  margin: 0;
  font-size: 2rem;
}
.bachelor .au_collapsible .csc-header h1, .bachelor #au_content .column .au_collapsible .csc-header h1 {
  color: #379fcb;
}
.au_collapsible .csc-header h2, #au_content .column .au_collapsible .csc-header h2 {
  color: #002546;
  border: 0;
  padding: 0 1.5rem 0 0;
  margin: 0;
  font-size: 1.818182rem;
}
.bachelor .au_collapsible .csc-header h2, .bachelor #au_content .column .au_collapsible .csc-header h2 {
  color: #379fcb;
}
.au_collapsible .csc-header h3, #au_content .column .au_collapsible .csc-header h3 {
  color: #002546;
  border: 0;
  padding: 0 1.5rem 0 0;
  margin: 0;
}
#au_content .au_collapsible ul {
  margin-bottom: 0;
}

.au_collapsed > .csc-header {
  margin-bottom: 0;
}
.au_collapsed.csc-frame > .csc-header ~ *, .au_collapsed.csc-frame > :not(.csc-header) {
  display: none;
}
.au_collapsed.csc-frame > .csc-header h1, .au_collapsed.csc-frame > .csc-header h2 {
  color: #000 !important;
  margin: 0;
}
.bachelor .au_collapsed.csc-frame > .csc-header h1, .bachelor .au_collapsed.csc-frame > .csc-header h2 {
  color: #379fcb !important;
}

.csc-frame.au_collapsible > .csc-header:after, .csc-frame.au_collapsed > .csc-header:after {
  font-family: "au_icons";
  float: right;
  color: #002546;
  font-size: 2.9090901rem;
  position: absolute;
  margin-top: 0.15rem;
  right: 0.3rem;
}
.bachelor .csc-frame.au_collapsible > .csc-header:after, .bachelor .csc-frame.au_collapsed > .csc-header:after {
  color: #379fcb;
}

.csc-frame.au_collapsible > .csc-header:after {
  content: "-";
  top: 0;
}

.csc-frame.au_collapsed > .csc-header:after {
  content: "+";
}

.au_collapsible_new .au_collapsible .au_collapsible {
  background-color: #e2e2e2;
}
.au_collapsible_new .au_collapsible .au_collapsible.au_collapsed {
  background-color: #f0eeef;
  border-top: 1px solid #dedcdc;
}
.au_collapsible_new .au_collapsible .csc-frame.au_collapsed > .csc-header:after {
  color: #999 !important;
}

/* Collapsible indeni neutral container */
.neutral-container .bg-dark-student .au_collapsible_new .au_collapsible .au_collapsible {
  background-color: #427418 !important;
}
.neutral-container .bg-dark-shared .au_collapsible_new .au_collapsible .au_collapsible {
  background-color: #007875 !important;
}
.neutral-container .bg-dark-phd .au_collapsible_new.au_collapsible .au_collapsible {
  background-color: #3e2b65 !important;
}
.neutral-container .bg-dark-staff .au_collapsible_new .au_collapsible .au_collapsible {
  background-color: #00608f !important;
}
.neutral-container .bg-dark-yellow .au_collapsible_new .au_collapsible .au_collapsible {
  background-color: #957105 !important;
}
.neutral-container .bg-dark-orange .au_collapsible_new .au_collapsible .au_collapsible {
  background-color: #8e4e0c !important;
}
.neutral-container .bg-dark-red .au_collapsible_new .au_collapsible .au_collapsible {
  background-color: #881212 !important;
}
.neutral-container .bg-dark-magenta .au_collapsible_new .au_collapsible .au_collapsible {
  background-color: #92004a !important;
}
.neutral-container .bg-dark-gray .au_collapsible_new .au_collapsible .au_collapsible {
  background-color: #656563 !important;
}

.neutral-container div[class*=bg-dark] .au_collapsible_new h1, .neutral-container div[class*=bg-dark] .au_collapsible_new h2, .neutral-container div[class*=bg-dark] .au_collapsible_new h3 {
  color: #fff !important;
}
.neutral-container div[class*=bg-dark] .au_collapsible_new .au_collapsible .au_collapsible.au_collapsed {
  background-color: transparent !important;
}
.neutral-container div[class*=bg-dark] .au_collapsible_new .csc-frame.au_collapsible > .csc-header:after {
  color: #fff;
}

.neutral-container div[class*=bg-light] .au_collapsible_new .au_collapsible.au_collapsed {
  background-color: transparent !important;
}

.neutral-container .bg-light-student .au_collapsible_new .au_collapsible .au_collapsible {
  background-color: rgba(139, 173, 63, 0.35) !important;
}
.neutral-container .bg-light-shared .au_collapsible_new .au_collapsible .au_collapsible {
  background-color: rgba(60, 162, 158, 0.35) !important;
}
.neutral-container .bg-light-phd .au_collapsible_new .au_collapsible .au_collapsible {
  background-color: rgba(101, 90, 159, 0.35) !important;
}
.neutral-container .bg-light-staff .au_collapsible_new .au_collapsible .au_collapsible {
  background-color: rgba(55, 159, 203, 0.35) !important;
}
.neutral-container .bg-light-yellow .au_collapsible_new .au_collapsible .au_collapsible {
  background-color: rgba(250, 187, 0, 0.35) !important;
}
.neutral-container .bg-light-orange .au_collapsible_new .au_collapsible .au_collapsible {
  background-color: rgba(238, 127, 0, 0.35) !important;
}
.neutral-container .bg-light-red .au_collapsible_new .au_collapsible .au_collapsible {
  background-color: rgba(226, 0, 26, 0.35) !important;
}
.neutral-container .bg-light-magenta .au_collapsible_new .au_collapsible .au_collapsible {
  background-color: rgba(226, 0, 122, 0.35) !important;
}
.neutral-container .bg-light-gray .au_collapsible_new .au_collapsible .au_collapsible {
  background-color: rgba(135, 135, 135, 0.35) !important;
}

.content .au_focus {
  position: relative;
  color: #fff;
  font-family: "AUPassataRegular";
  margin-bottom: 2em;
  background-size: cover;
}
.content .au_focus h1 {
  line-height: 32px;
  font-size: 32px;
  float: left;
  clear: both;
  margin: 0 !important;
  padding: 4px 10px;
  color: #fff;
  font-family: "AUPassataLight";
  background: #333333;
  background: rgba(26, 26, 26, 0.75);
  border: 0;
}

.au_focus {
  margin-bottom: 0;
}
.au_focus h1 strong {
  font-family: "AUPassataBold";
}
.au_focus h1 a {
  color: #fff;
  border: 0;
  text-decoration: none;
}
.au_focus h1 a:hover {
  text-decoration: underline;
  border: 0;
}
.au_focus h1 + p {
  margin-top: 4px;
}
.au_focus p {
  float: left;
  clear: both;
  margin: 0;
  padding: 0;
  color: #fff;
  padding: 4px 10px;
  background: #333333;
  background: rgba(26, 26, 26, 0.75);
  font-size: 16px !important;
}
.au_focus p a {
  color: #fff;
  text-decoration: none;
}
.au_focus p a:hover {
  text-decoration: underline;
}
.au_focus .au_focus_content {
  margin-left: 24px;
  bottom: 24px;
  position: absolute;
}
.au_focus ul {
  font-size: 16px;
  clear: left;
  margin: 0;
}
.au_focus ul li {
  float: left;
  clear: left;
  padding: 4px 10px !important;
  margin-top: 4px;
  padding-left: 10px;
  background-image: none;
  line-height: 1em !important;
  margin-bottom: 0 !important;
}
.au_focus ul li a {
  color: #fff !important;
  text-decoration: none;
  border: none !important;
}
.au_focus ul li a:hover {
  text-decoration: underline;
}
.au_focus ul li:before {
  display: none;
}

@media screen and (max-width: 39.9375em) {
  .au_focus {
    height: initial !important;
    min-height: 90px;
  }

  .au_focus .au_focus_content {
    margin-left: inherit;
    bottom: initial;
    position: relative;
    overflow: auto;
    padding: 1em;
  }

  .content .au_focus h1 {
    line-height: inherit;
  }
}
table.au_table-style1 {
  width: 100%;
}
table.au_table-style1 td {
  vertical-align: top;
  padding-bottom: 1em;
}
table.au_table-style1 tr td:first-child {
  text-transform: uppercase;
  font-family: "AUPassataRegular";
  font-size: 90%;
}
table.au_table-style1 tr:nth-of-type(even) {
  background-color: transparent !important;
}
table.au_table-style2, table[class^=au_table_style2-] {
  border-collapse: collapse;
  border-style: hidden;
  margin: 2em 0;
  width: 100%;
}
table.au_table-style2 td, table[class^=au_table_style2-] td {
  background-color: #b2e2f6;
  vertical-align: top;
}
table.au_table-style2 th, table[class^=au_table_style2-] th {
  font-family: "AUPassataRegular";
  background-color: #7fceef;
  text-transform: uppercase;
}
table.au_table-style2 td, table.au_table-style2 th, table[class^=au_table_style2-] td, table[class^=au_table_style2-] th {
  border: 10px solid #fff;
  padding: 6px 10px;
}
table.au_table-style2 em, table[class^=au_table_style2-] em {
  font-style: normal;
  color: #fff;
}
.au_table_style2-green td, table.au_table-style2.au_green-bg td, table[class^=au_table_style2-].au_green-bg td {
  background-color: #d7e9bb !important;
}

.au_table_style2-green th, table.au_table-style2.au_green-bg th, table[class^=au_table_style2-].au_green-bg th {
  background-color: #bcda8e !important;
}

.au_table_style2-lightblue td, table.au_table-style2.au_blue-bg td, table[class^=au_table_style2-].au_blue-bg td {
  background-color: #b8c4da !important;
}

.au_table_style2-lightblue th, table.au_table-style2.au_blue-bg th, table[class^=au_table_style2-].au_blue-bg th {
  background-color: #7f9ec2 !important;
}

.au_table_style2-purple td, table.au_table-style2.au_purple-bg td, table[class^=au_table_style2-].au_purple-bg td {
  background-color: #d0cde2 !important;
}

.au_table_style2-purple th, table.au_table-style2.au_purple-bg th, table[class^=au_table_style2-].au_purple-bg th {
  background-color: #b2accf !important;
}

.au_table_style2-grey td, table.au_table-style2.au_grey-bg td, table[class^=au_table_style2-].au_grey-bg td {
  background-color: #e1e1e2 !important;
}

.au_table_style2-grey th, table.au_table-style2.au_grey-bg th, table[class^=au_table_style2-].au_grey-bg th {
  background-color: #c3c4c5 !important;
}

table .au_bg_dark {
  background: #002546;
  color: #fff !important;
}
table .au_bg_medium {
  background: rgba(0, 37, 70, 0.25);
}
table .au_bg_light {
  background: rgba(0, 37, 70, 0.125);
}
table .au_bg_grey {
  background: #e1e1e2;
}
.student table .au_bg_dark {
  background: #2a4a0f;
  color: #fff !important;
}
.student table .au_bg_medium {
  background: rgba(42, 74, 15, 0.25);
}
.student table .au_bg_light {
  background: rgba(42, 74, 15, 0.125);
}
.staff table .au_bg_dark {
  background: #003e5c;
  color: #fff !important;
}
.staff table .au_bg_medium {
  background: rgba(0, 62, 92, 0.25);
}
.staff table .au_bg_light {
  background: rgba(0, 62, 92, 0.125);
}
.shared table .au_bg_dark {
  background: #004543;
  color: #fff !important;
}
.shared table .au_bg_medium {
  background: rgba(0, 69, 67, 0.25);
}
.shared table .au_bg_light {
  background: rgba(0, 69, 67, 0.125);
}
.phd table .au_bg_dark {
  background: #281c41;
  color: #fff !important;
}
.phd table .au_bg_medium {
  background: rgba(40, 28, 65, 0.25);
}
.phd table .au_bg_light {
  background: rgba(40, 28, 65, 0.125);
}
table .au_fg_dark {
  color: #002546;
}
table .au_fg_medium {
  color: rgba(0, 37, 70, 0.25);
}
table .au_fg_light {
  color: rgba(0, 37, 70, 0.125);
}
.student table .au_fg_dark {
  color: #2a4a0f;
}
.student table .au_fg_medium {
  color: rgba(42, 74, 15, 0.25);
}
.student table .au_fg_light {
  color: rgba(42, 74, 15, 0.125);
}
.staff table .au_fg_dark {
  color: #003e5c;
}
.staff table .au_fg_medium {
  color: rgba(0, 62, 92, 0.25);
}
.staff table .au_fg_light {
  color: rgba(0, 62, 92, 0.125);
}
.shared table .au_fg_dark {
  color: #004543;
}
.shared table .au_fg_medium {
  color: rgba(0, 69, 67, 0.25);
}
.shared table .au_fg_light {
  color: rgba(0, 69, 67, 0.125);
}
.phd table .au_fg_dark {
  color: #281c41;
}
.phd table .au_fg_medium {
  color: rgba(40, 28, 65, 0.25);
}
.phd table .au_fg_light {
  color: rgba(40, 28, 65, 0.125);
}
table .au_bt_dark {
  border-top: 1px solid #002546;
}
table .au_bt_medium {
  border-top: 1px solid rgba(0, 37, 70, 0.25);
}
table .au_bt_light {
  border-top: 1px solid rgba(0, 37, 70, 0.125);
}
.student table .au_bt_dark {
  border-top: 1px solid #2a4a0f;
}
.student table .au_bt_medium {
  border-top: 1px solid rgba(42, 74, 15, 0.25);
}
.student table .au_bt_light {
  border-top: 1px solid rgba(42, 74, 15, 0.125);
}
.staff table .au_bt_dark {
  border-top: 1px solid #003e5c;
}
.staff table .au_bt_medium {
  border-top: 1px solid rgba(0, 62, 92, 0.25);
}
.staff table .au_bt_light {
  border-top: 1px solid rgba(0, 62, 92, 0.125);
}
.shared table .au_bt_dark {
  border-top: 1px solid #004543;
}
.shared table .au_bt_medium {
  border-top: 1px solid rgba(0, 69, 67, 0.25);
}
.shared table .au_bt_light {
  border-top: 1px solid rgba(0, 69, 67, 0.125);
}
.phd table .au_bt_dark {
  border-top: 1px solid #281c41;
}
.phd table .au_bt_medium {
  border-top: 1px solid rgba(40, 28, 65, 0.25);
}
.phd table .au_bt_light {
  border-top: 1px solid rgba(40, 28, 65, 0.125);
}
table .au_bb_dark {
  border-bottom: 1px solid #002546;
}
table .au_bb_medium {
  border-bottom: 1px solid rgba(0, 37, 70, 0.25);
}
table .au_bb_light {
  border-bottom: 1px solid rgba(0, 37, 70, 0.125);
}
.student table .au_bb_dark {
  border-bottom: 1px solid #2a4a0f;
}
.student table .au_bb_medium {
  border-bottom: 1px solid rgba(42, 74, 15, 0.25);
}
.student table .au_bb_light {
  border-bottom: 1px solid rgba(42, 74, 15, 0.125);
}
.staff table .au_bb_dark {
  border-bottom: 1px solid #003e5c;
}
.staff table .au_bb_medium {
  border-bottom: 1px solid rgba(0, 62, 92, 0.25);
}
.staff table .au_bb_light {
  border-bottom: 1px solid rgba(0, 62, 92, 0.125);
}
.shared table .au_bb_dark {
  border-bottom: 1px solid #004543;
}
.shared table .au_bb_medium {
  border-bottom: 1px solid rgba(0, 69, 67, 0.25);
}
.shared table .au_bb_light {
  border-bottom: 1px solid rgba(0, 69, 67, 0.125);
}
.phd table .au_bb_dark {
  border-bottom: 1px solid #281c41;
}
.phd table .au_bb_medium {
  border-bottom: 1px solid rgba(40, 28, 65, 0.25);
}
.phd table .au_bb_light {
  border-bottom: 1px solid rgba(40, 28, 65, 0.125);
}

/* TABLES-TO-LIST */
.au_responsive_table_list {
  margin: 0 !important;
}
.au_responsive_table_list li {
  padding: 1em 1em 0 1em !important;
  margin-bottom: 1em !important;
  background: #f0f0f0 !important;
  overflow: auto;
}
.au_responsive_table_list li h3 {
  margin: 0 0 1em 0 !important;
  font-weight: bold;
  background: #ccc;
  padding: 0.5em;
}
.au_responsive_table_list li p {
  background: #e4e4e4;
  padding: 0.5em;
}
.au_responsive_table_list.purple li {
  background: #d0cde2 !important;
}
.au_responsive_table_list.purple li h3 {
  background: #b2accf;
}
.au_responsive_table_list.purple li p {
  background: #c1bbe0;
}
.au_responsive_table_list.green li {
  background: #d7e9bb !important;
}
.au_responsive_table_list.green li h3 {
  background: #bcda8e;
}
.au_responsive_table_list.green li p {
  background: #c8e59c;
}
.au_responsive_table_list.blue li {
  background: #b8c4da !important;
}
.au_responsive_table_list.blue li h3 {
  background: #7f9ec2;
}
.au_responsive_table_list.blue li p {
  background: #afbad0;
}

/* GENERIC RESPONSIVE TABLE FIX */
div.responsive-table-container {
  width: 100%;
  overflow-y: auto;
  margin: 0 0 1em;
  background-repeat: repeat-y;
  background-position: top right;
}

div.responsive-table-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
  height: 14px;
}

div.responsive-table-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid #fff;
  background-color: rgba(0, 0, 0, 0.3);
}

/* EDUTABLES - OMG EDDI */
div.studieoversigt table {
  background-color: transparent;
  empty-cells: show;
  width: 100%;
  border-collapse: collapse;
  font-size: 90%;
  table-layout: fixed;
  clear: both;
}
div.studieoversigt table tbody, div.studieoversigt table thead {
  background: transparent !important;
}
div.studieoversigt table tr {
  background-color: transparent !important;
}
div.studieoversigt table th {
  width: 10%;
  font-weight: bold;
}
div.studieoversigt table td {
  padding: 5px;
}
div.studieoversigt table td[rowspan="2"] {
  height: 230px;
}
div.studieoversigt table td[rowspan="2"] .element {
  height: 220px;
}
div.studieoversigt table td[rowspan="3"] {
  height: 340px;
}
div.studieoversigt table td[rowspan="3"] .element {
  height: 330px;
}
div.studieoversigt table td[rowspan="4"] {
  height: 470px;
}
div.studieoversigt table td[rowspan="4"] .element {
  height: 460px;
}
div.studieoversigt table td[rowspan="6"] {
  height: 710px;
}
div.studieoversigt table td[rowspan="6"] .element {
  height: 700px;
}
div.studieoversigt table div.left {
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}
div.studieoversigt table .obligatorisk {
  background-color: #f2e4d4;
}
div.studieoversigt table .valgfag {
  background-color: #d1e1d6;
}
div.studieoversigt table .tilvalg {
  background-color: #e9ccda;
}
div.studieoversigt table .stottefag {
  background-color: #cfe1e7;
}
div.studieoversigt table .blank {
  background-color: #dddddd;
}
div.studieoversigt div.enkeltStudieoversigt {
  width: 100%;
  margin-bottom: 2em;
}
div.studieoversigt div.enkeltStudieoversigt a.aulink:link {
  text-decoration: none !important;
}
div.studieoversigt div.enkeltStudieoversigt a.aulink:link:hover {
  text-decoration: none !important;
}
div.studieoversigt a.aulink:link {
  text-decoration: none !important;
}
div.studieoversigt a.aulink:link .obligatorisk:hover {
  color: #f2e4d4 !important;
}
div.studieoversigt a.aulink:link .obligatorisk:hover div {
  color: #f2e4d4 !important;
}
div.studieoversigt a.aulink:link .valgfag:hover {
  color: #d1e1d6 !important;
}
div.studieoversigt a.aulink:link .valgfag:hover div {
  color: #d1e1d6 !important;
}
div.studieoversigt a.aulink:link .tilvalg:hover {
  color: #e9ccda !important;
}
div.studieoversigt a.aulink:link .tilvalg:hover div {
  color: #e9ccda !important;
}
div.studieoversigt a.aulink:link .stottefag:hover {
  color: #cfe1e7 !important;
}
div.studieoversigt a.aulink:link .stottefag:hover div {
  color: #cfe1e7 !important;
}
div.studieoversigt a.aulink:link, div.studieoversigt a.aulink:visited {
  color: #333 !important;
}
div.studieoversigt .elementtekst {
  padding: 0 2px 0 0;
  margin: 0;
}
div.studieoversigt .element {
  margin: 0;
  overflow: visible;
  word-wrap: break-word;
  height: 100px;
  padding: 5px;
  position: relative;
  clear: both;
}
div.studieoversigt .studiediagram_navn {
  position: relative;
  top: 0;
  text-decoration: underline;
}
div.studieoversigt .studiediagram_ects {
  position: absolute;
  bottom: 0;
  padding: 6px 2px 6px 2px;
}
div.studieoversigt a .element:hover {
  background-color: #676767 !important;
}

.studiediagram_navn + div {
  bottom: 5px;
  position: absolute;
}

/* EDUTABLES - AWESOME! */
.au_edutable_track {
  display: none;
}
.au_edutable_track.active {
  display: block;
}

.au_edutable_legend {
  float: right;
  clear: both;
  margin-right: 0.4em;
}
.au_edutable_legend dt {
  padding: 0.5em;
  width: 10em;
  margin-bottom: 0;
}
.au_edutable_legend dd {
  margin-bottom: 0.4rem;
}
.au_edutable_legend:after {
  content: "";
  clear: both;
  display: block;
}

.au_edutable_mandatory {
  background-color: #f2e4d4;
}

.au_edutable_optional {
  background-color: #d1e1d6;
}

.au_edutable_supplementary {
  background-color: #e9ccda;
}

.au_edutable_support {
  background-color: #cfe1e7;
}

.au_edutable_eddi_container {
  overflow: auto;
}

.studydiagram_legend {
  display: none;
}

.main .au_responsive_education_overview {
  background: #f1eeef;
  overflow: auto;
  padding: 1rem;
}
.main .au_responsive_education_overview ul {
  margin-bottom: 2em;
}
.main .au_responsive_education_overview ul:last-child {
  margin-bottom: 0em;
}
.main .au_responsive_education_overview ul li {
  background: none;
  padding: 0;
}
.main .au_responsive_education_overview ul h3 {
  font-family: "AUPassataBold";
  text-transform: uppercase;
  margin: 1rem 0;
}
.main .au_responsive_education_overview ul p {
  padding: 0.5em;
  margin-bottom: 0.25em;
}
.main .au_responsive_education_overview ul p.edu_support {
  background: #cfe1e7;
}
.main .au_responsive_education_overview ul p.edu_mandatory {
  background: #f2e4d4;
}
.main .au_responsive_education_overview ul p.edu_supplementary {
  background: #e9ccda;
}
.main .au_responsive_education_overview ul p.edu_optional {
  background: #d1e1d6;
}
.main .au_responsive_education_overview ul p.edu_empty {
  visibility: hidden;
}
.main .au_responsive_education_overview ul p:last-child {
  margin-bottom: 0em;
}
.main .au_responsive_education_overview ul p span:last-child {
  float: right;
}
.main .au_responsive_education_overview ul p a {
  color: #000;
  text-decoration: none;
  border: none;
}

table tr th[align=right], table tr td[align=right] {
  text-align: right;
}

table tr th[align=center], table tr td[align=center] {
  text-align: center;
}

/*############################*/
/* --- CSS STYLED CONTENT --- */
/*############################*/
.csc-textpic {
  overflow: hidden;
}

.tx-felogin-pi1 label {
  display: block;
}

.tx-felogin-pi1 legend {
  display: none;
}

/* Captions */
div.csc-textpic-caption-c .csc-textpic-caption {
  text-align: center;
}

div.csc-textpic-caption-r .csc-textpic-caption {
  text-align: right;
}

div.csc-textpic-caption-l .csc-textpic-caption {
  text-align: left;
}

.csc-textpic-caption {
  font-size: 90%;
  line-height: 130%;
  color: #818181;
  padding: 0.4em 0;
}

/* Needed for noRows setting */
div.csc-textpic div.csc-textpic-imagecolumn {
  float: left;
  display: inline;
}

/* Border just around the image */
div.csc-textpic-border div.csc-textpic-imagewrap .csc-textpic-image img, div.csc-textpic-border div.csc-textpic-single-image img {
  border: 2px solid black;
  padding: 0px 0px;
}

div.csc-textpic-imagewrap {
  padding: 0;
}

#au_content div.csc-textpic img, #au_content .csc-textpic-imagewrap a {
  border: none;
}

/* div:This will place the images side by side */
div.csc-textpic div.csc-textpic-imagewrap div.csc-textpic-image {
  float: left;
}

/* UL:This will place the images side by side */
div.csc-textpic div.csc-textpic-imagewrap ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

div.csc-textpic div.csc-textpic-imagewrap ul li {
  float: left;
  margin: 0;
  padding: 0;
}

.main ul li.csc-textpic-image:before {
  content: "" !important;
}

/* DL:This will place the images side by side */
div.csc-textpic div.csc-textpic-imagewrap dl.csc-textpic-image {
  float: left;
}

div.csc-textpic div.csc-textpic-imagewrap dl.csc-textpic-image dt {
  float: none;
}

div.csc-textpic div.csc-textpic-imagewrap dl.csc-textpic-image dd {
  float: none;
}

div.csc-textpic div.csc-textpic-imagewrap dl.csc-textpic-image dd img {
  border: none;
}

/* FE-Editing Icons */
dl.csc-textpic-image {
  margin: 0;
}

dl.csc-textpic-image dt {
  margin: 0;
  display: inline;
}

dl.csc-textpic-image dd {
  margin: 0;
}

/* Clearer */
div.csc-textpic-clear {
  clear: both;
}

/* Margins around images:*/
/* Pictures on left,add margin on right */
div.csc-textpic-left div.csc-textpic-imagewrap .csc-textpic-image,
div.csc-textpic-intext-left-nowrap div.csc-textpic-imagewrap .csc-textpic-image,
div.csc-textpic-intext-left div.csc-textpic-imagewrap .csc-textpic-image {
  display: inline;
  /* IE fix for double-margin bug */
  margin-right: 10px;
}

/* Pictures on right,add margin on left */
div.csc-textpic-right div.csc-textpic-imagewrap .csc-textpic-image,
div.csc-textpic-intext-right-nowrap div.csc-textpic-imagewrap .csc-textpic-image,
div.csc-textpic-intext-right div.csc-textpic-imagewrap .csc-textpic-image {
  display: inline;
  /* IE fix for double-margin bug */
}

/* Pictures centered,add margin on left */
div.csc-textpic-center div.csc-textpic-imagewrap .csc-textpic-image {
  display: inline;
  /* IE fix for double-margin bug */
  margin-left: 10px;
}

div.csc-textpic div.csc-textpic-imagewrap .csc-textpic-image .csc-textpic-caption {
  margin: 0;
}

div.csc-textpic div.csc-textpic-imagewrap .csc-textpic-image img {
  margin: 0;
}

/* Space below each image (also in-between rows) */
div.csc-textpic div.csc-textpic-imagewrap .csc-textpic-image {
  margin-bottom: 5px;
  padding-left: 0 !important;
}

div.csc-textpic-equalheight div.csc-textpic-imagerow {
  margin-bottom: 5px;
  display: block;
}

div.csc-textpic div.csc-textpic-imagerow {
  clear: both;
}

div.csc-textpic div.csc-textpic-single-image img {
  margin-bottom: 5px;
}

/* No margins around the whole image-block */
div.csc-textpic div.csc-textpic-imagewrap .csc-textpic-firstcol {
  margin-left: 0px !important;
}

div.csc-textpic div.csc-textpic-imagewrap .csc-textpic-lastcol {
  margin-right: 0px !important;
}

/* Add margin from image-block to text (in case of "Text w/ images") */
div.csc-textpic-intext-left div.csc-textpic-imagewrap,
div.csc-textpic-intext-left-nowrap div.csc-textpic-imagewrap {
  margin-right: 20px !important;
}

div.csc-textpic-intext-right div.csc-textpic-imagewrap,
div.csc-textpic-intext-right-nowrap div.csc-textpic-imagewrap {
  margin-left: 20px !important;
}

/* Positioning of images:*/
/* Above */
div.csc-textpic-above div.csc-textpic-text {
  clear: both;
}

/* Center (above or below) */
div.csc-textpic-center {
  text-align: center;
  /* IE-hack */
}

div.csc-textpic-center div.csc-textpic-imagewrap {
  margin: 0 auto;
}

div.csc-textpic-center div.csc-textpic-imagewrap .csc-textpic-image {
  text-align: left;
  /* Remove IE-hack */
}

div.csc-textpic-center div.csc-textpic-text {
  text-align: left;
  /* Remove IE-hack */
}

/* Right (above or below) */
div.csc-textpic-right div.csc-textpic-imagewrap {
  float: right;
}

div.csc-textpic-right div.csc-textpic-text {
  clear: right;
}

/* Left (above or below) */
div.csc-textpic-left div.csc-textpic-imagewrap {
  float: left;
}

div.csc-textpic-left div.csc-textpic-text {
  clear: left;
}

/* Left (in text) */
div.csc-textpic-intext-left div.csc-textpic-imagewrap {
  float: left;
}

/* Right (in text) */
div.csc-textpic-intext-right div.csc-textpic-imagewrap {
  float: right;
}

/* Right (in text,no wrap around) */
div.csc-textpic-intext-right-nowrap div.csc-textpic-imagewrap {
  float: right;
  clear: both;
}

/* Hide from IE5-mac. Only IE-win sees this. \*/
* html div.csc-textpic-intext-right-nowrap .csc-textpic-text {
  height: 1%;
}

/* End hide from IE5/mac */
/* Left (in text,no wrap around) */
div.csc-textpic-intext-left-nowrap div.csc-textpic-imagewrap {
  float: left;
  clear: both;
}

/* Hide from IE5-mac. Only IE-win sees this. \*/
* html div.csc-textpic-intext-left-nowrap .csc-textpic-text {
  height: 1%;
}

/* End hide from IE5/mac */
div.csc-textpic div.csc-textpic-imagerow-last {
  margin-bottom: 0;
}

/* Browser fixes:*/
/* Fix for unordered and ordered list with image "In text,left" */
.csc-textpic-intext-left ol, .csc-textpic-intext-left ul {
  overflow: auto;
  height: 1%;
}

.csc-textpic-imagewrap {
  max-width: 100%;
  overflow-x: auto;
}

.csc-textpic-imagecolumn {
  max-width: 100% !important;
}

.csc-textpic-image {
  max-width: 100% !important;
  padding-right: 10px;
  margin: 0 0 10px 0 !important;
}

.csc-textpic-intext-left-nowrap .csc-textpic-image, .csc-textpic-intext-left .csc-textpic-image, .csc-textpic-left .csc-textpic-image {
  padding-right: 0 !important;
}

/*########################*/
/* --- GSA SEARCH --- */
/*########################*/
.searchpage .columns.search {
  display: none;
}

div.au_content_results ul li.g {
  background: none;
  line-height: 1.3em;
  list-style: none outside none;
  margin-left: 0 !important;
  padding-left: 0px;
  margin-bottom: 1.5em;
}

div.au_content_results li.g a span {
  font-size: 110%;
}

div.au_content_results ul li span.url {
  color: #666;
  font-size: 90%;
}

div.au_content_results ul li span.title {
  font-size: 110%;
}

div.au_gsa_keymatches {
  margin: 2em 0;
  padding: 0.75em;
  background: #e9e9e9;
}

div.au_gsa_keymatches p {
  font-weight: bold;
  float: right;
}

div.au_gsa_keymatches ul {
  padding: 0;
  margin: 1em;
}

div.au_gsa_meta {
  font-size: 90%;
  color: #333;
}

ul li.g table {
  border: none;
}

ul li.g table tr td {
  font-size: 1rem;
}

/* = TABS = */
ul.tabs {
  background: url("//cmsdesign.au.dk/design/2008/graphics/gsa/tabs_bg2.png") repeat-x scroll 0 -138px transparent;
  font-size: 0;
  height: 23px;
  list-style: none outside none;
  margin: 10px 0 1em;
  padding: 0;
}

ul.tabs li {
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  vertical-align: top;
  background: none;
  padding-left: 0 !important;
  float: right;
}

ul.tabs li:before, ul li.g:before {
  content: "" !important;
}

ul.tabs li.gsa-tab-last {
  float: right;
  margin-right: 0;
}

ul.tabs li.gsa-tab-first {
  float: left;
  margin-right: 0;
}

ul.tabs li a {
  background: url("//cmsdesign.au.dk/design/2008/graphics/gsa/tabs_bg2.png") no-repeat scroll 0 0 transparent;
  cursor: pointer;
  display: inline-block;
  font-size: inherit;
  height: 23px;
  line-height: 22px;
  text-decoration: none;
  vertical-align: top;
  border: none !important;
}

ul.tabs li a:hover {
  border: none;
}

ul.tabs a span {
  background: url("//cmsdesign.au.dk/design/2008/graphics/gsa/tabs_bg2.png") no-repeat scroll right -23px transparent;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  padding: 0 8px;
}

ul.tabs a:hover {
  background-position: 0 -46px;
  text-decoration: none !important;
}

ul.tabs a:hover span {
  background-position: right -69px;
}

ul.tabs li.selected a {
  background-position: 0 -92px;
  color: #002546;
  background: #eee;
}

ul.tabs li.selected a span {
  background-position: right -115px;
}

div.tab_content {
  border-top: none;
  background: url("//cmsdesign.au.dk/design/2008/graphics/gsa/results_bg.png") top left no-repeat;
  min-height: 300px;
}

div.tab_content div:first-child {
  background: url("//cmsdesign.au.dk/design/2008/graphics/gsa/results_bg.png") top right no-repeat;
}

div.tab_content div:first-child div {
  background: none;
}

div.au_content_results {
  padding: 2em;
}

ul.tabs li.gsa-tab-label span {
  font-size: 11px;
  line-height: 22px;
  padding-right: 0.5em;
}

/* = AU GSA SEARCH FORM = */
div#au_searchbox.au_gsa input#tx_googlequery_searchform_ss_q {
  width: 80px;
}

div.au_gsa fieldset {
  border: none;
  padding: 0;
}

div#au_searchbox.au_gsa {
  background: url("//cmsdesign.au.dk/design/2008/graphics/gsa/au_gsa_search_bg.gif") no-repeat scroll 0 0 transparent;
  height: 25px;
  padding-bottom: 2em;
  left: 0px;
  margin-left: 0px;
  width: 406px;
}

div#au_searchbox.au_gsa input#tx_googlequery_searchform_ss_q {
  background: none repeat scroll 0 0 transparent;
  border: 0 none;
  color: #515151;
  float: left;
  margin: 2px 0 0 20px;
  padding: 3px 0 2px 4px;
  width: 290px;
  box-shadow: none;
  height: 2rem;
}

div#au_searchbox.au_gsa input.au_searchbox_button {
  cursor: pointer;
  float: right;
  margin: 3px 4px 0 0;
}

div#au_searchbox.au_gsa legend {
  display: none;
}

div#au_searchbox.au_gsa p {
  float: right;
}

/* = MISC = */
div.gsa_sorting {
  float: right;
  clear: right;
}

div.au_gsa_suggestions {
  margin-bottom: 1em;
  padding-left: 25px;
}

div.au_gsa_suggestions span {
  color: #cc0000;
}

p.gsa_results_count {
  float: right;
}

div.au_gsa_relatedqueries {
  padding-left: 2em;
}

div#au_searchbox.au_gsa input#tx_googlequery_searchform_ss_q.loading {
  background: url("//cmsdesign.au.dk/design/2008/graphics/gsa/ajax-loader.gif") no-repeat scroll 95% 50%;
}

/* = PAGEBROWSER = */
.au_browse {
  clear: left;
  font-size: 0.95em;
  text-align: center;
}

.au_browse p.results_text {
  text-align: left;
  text-transform: uppercase;
}

.au_browse a, .au_browse span {
  background: #FFFFFF none repeat scroll 0 0;
  border: 1px solid #FFFFFF;
  display: block;
  float: left;
  margin-right: 0.2em;
  padding: 2px 0.4em;
}

.au_browse li.current a {
  background: #666 none repeat scroll 0 0;
  border: 1px solid #666;
  color: #FFF !important;
  font-weight: bold;
}

.au_browse a, .au_browse a:visited {
  border: 1px solid #ccc !important;
  text-decoration: none;
  color: #ccc;
}

.au_browse a:hover {
  border-color: #666;
  color: #666;
}

.au_browse li {
  margin-bottom: 0;
  padding-left: 0;
}

.au_browse li.next a, .au_browse li.last a {
  background: url("//cmsdesign.au.dk/design/2008/graphics/gsa/pb_next.png") center right no-repeat;
  color: #fff;
  border: none !important;
  font-weight: bold;
  padding: 3px 0.8em;
  padding-right: 1.3em;
}

.au_browse li.previous a, .au_browse li.first a {
  background: url("//cmsdesign.au.dk/design/2008/graphics/gsa/pb_previous.png") center left no-repeat;
  color: #fff !important;
  border: none;
  font-weight: bold;
  padding: 3px 0.8em;
  padding-left: 1.3em;
}

.au_browse li.previous a:hover, .au_browse li.next a:hover, .au_browse li.first a:hover, .au_browse li.last a:hover {
  color: #fff !important;
}

.au_browse ul, .au_browse ul li {
  padding: 0;
  margin-left: 0;
}

.au_floatright {
  float: right !important;
}

.au_floatleft {
  float: left !important;
}

ul.au_browse li:before {
  content: "" !important;
}

/* = SORTING WIDGET = */
.au_gsa_sorting {
  clear: right;
  width: 220px;
  height: 24px;
  background: url("//cmsdesign.au.dk/design/2008/graphics/gsa/au_gsa_sort_bg.gif") 83px -48px no-repeat !important;
  position: relative;
  float: right;
}

.au_gsa_sorting a, .au_gsa_sorting a:hover {
  border: none !important;
}

.date_selected {
  background-position: 83px -24px !important;
}

.date_selected {
  background-position: 83px -24px !important;
}

.date_selected .au_gsa_sorting_relevance a[href]:link, .date_selected .au_gsa_sorting_relevance a[href]:visited {
  color: #999;
}

.date_selected .au_gsa_sorting_relevance a[href]:hover {
  color: #333 !important;
}

.date_selected .au_gsa_sorting_date a[href]:link, .date_selected .au_gsa_sorting_date a[href]:visited {
  color: #fff;
}

.date_selected .au_gsa_sorting_date a[href]:hover {
  color: #fff !important;
}

.etad {
  background-position: 83px 0px !important;
}

.au_gsa_sorting a[href]:link, .au_gsa_sorting a[href]:visited {
  text-decoration: none;
  color: #fff;
}

.au_gsa_sorting a[href]:hover {
  text-decoration: underline;
  color: #fff !important;
}

.au_gsa_sorting_label {
  position: absolute;
  top: 3px;
}

.au_gsa_sorting_relevance {
  position: absolute;
  top: 1px;
  left: 94px;
}

.au_gsa_sorting_date {
  position: absolute;
  top: 1px;
  left: 161px;
}

.au_gsa_sorting_date a[href]:link, .au_gsa_sorting_date a[href]:visited {
  color: #999;
}

.au_gsa_sorting_date a[href]:hover {
  color: #333 !important;
}

/*MAIN PAGE SEARCH*/
div.au_content_results ul li.g {
  clear: both;
}

.tab_content .au_content_results p {
  clear: both;
}

.main .au_gsa_search_select li:before {
  content: initial;
}

.main .au_gsa_search_select a {
  text-decoration: none;
}

.au_gsa_search_form input[type=text] {
  float: left;
  width: calc(100% - 50px) !important;
}

.au_gsa_search_form input[type=image] {
  margin-right: 10px;
}

#au_searchbox.au_gsa_search_form {
  height: 50px;
}

.au_gsa_search_form label {
  padding: 10px 0;
}

/*MAIN PAGE SEARCH END*/
/*FACULTIES SEARCH*/
#searchtips {
  display: none;
  background: #f2f2f2;
  padding: 1em;
  width: 100%;
  z-index: 1;
}

.au_browse a {
  line-height: 20px;
}

ul.tabs a span {
  font-size: 12px;
  background: none;
}

ul.tabs {
  border: none;
}

ul li.g table tr td {
  font-size: inherit;
  padding: 0.72727rem 0;
}

.au_gsa_meta {
  font-style: italic;
}

ul.tabs li a {
  border: 1px solid #cfcfcf !important;
  border-radius: 5px 5px 0 0;
  background: none;
  transition: ease-in-out 0.2s;
}

ul.tabs li a:hover,
li.gsa-tab.selected {
  background: #002546;
  border: 1px solid #002546 !important;
  border-bottom: 1px solid #cfcfcf !important;
  transition: ease-in-out 0.2s;
}

ul.tabs li.selected a {
  background: #002546;
  border-radius: 5px 5px 0 0;
  border: 1px solid #002546 !important;
  color: #fff;
}

.au_browse li.current a {
  background: #002546;
  border: 1px solid #002546 !important;
}

li.next,
li.previous {
  float: left;
  background: #002546;
  color: #FFF;
  position: relative;
  line-height: 20px;
  font-size: inherit;
}

li.previous {
  margin-right: 5px;
}

li.next:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-left: 15px solid #002546;
  position: absolute;
  top: 0;
  right: -15px;
}

.au_browse li.next a,
.au_browse li.previous a {
  background: none;
  padding: 3px 12px;
  text-decoration: none;
  border: none !important;
}

li.previous:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-right: 15px solid #002546;
  position: absolute;
  top: 0;
  left: 2px;
}

ul.tabs .gsa-tab-first a span {
  background: none;
}

/*FACULTIES SEARCH - END*/
/*########################*/
/* --- PURE EXTENSION --- */
/*########################*/
div.pure-basic-person-single {
  padding: 20px;
  background: url("") bottom left repeat-x;
  border: 1px solid #ddddd9;
  margin-left: -12px;
  margin-bottom: 2em;
  overflow: auto;
  padding-bottom: 6px;
}

div.pure-basic-person-single img.photo {
  float: right;
  max-width: 160px;
}

div.pure-small-person {
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid #C5C5C5;
}

div#au_content div.pure-basic-person-single h2 {
  margin-top: 0;
}

div#au_content div#au_vcard_areas li,
div#au_content div.pure-basic-person-single li {
  margin-left: 0;
}

div#au_content div.pure-basic-person-single ul {
  margin-top: 16px;
  padding: 0;
}

div#au_content div.pure-basic-person-single h3.au_vcard_emphasized_header {
  margin: 1em 0;
  border-top: 1px solid #cccbc6;
  border-bottom: 1px solid #cccbc6;
  padding: 0.7em 0;
}

div.vcard_areas h1 {
  border-top: 1px solid #cccbc6;
  border-bottom: 1px solid #cccbc6;
  font-size: 180%;
  padding: 0.7em 0;
  font-weight: 400;
  text-transform: uppercase;
  margin-left: -12px;
  padding-left: 12px;
  margin-bottom: 0.5em;
}

div.vcard_areas h2 {
  margin: 1em 0;
}

.pure-tabs-ul {
  padding: 0 !important;
  width: 100%;
  height: 21px;
}

.pure-tabs-ul li {
  background: none !important;
  display: inline;
  white-space: nowrap;
  padding-left: 0 !important;
}

.pure-tabs-ul li a {
  padding: 6px;
  padding-top: 2px;
  border: 1px solid #ccc;
  text-decoration: none !important;
  background: url() bottom left repeat-x;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  height: 20px;
  line-height: 12px;
}

.pure-tabs-ul li.pure-tabs-selected a,
div#au_content .pure-tabs-ul li.pure-tabs-selected a:hover {
  border: 1px solid #003d85;
  text-decoration: none !important;
  color: #fff !important;
  background-image: none;
  background-color: #003d85;
}

.pure-tabs-hover a,
.pure-tabs-ul a:hover {
  border: 1px solid #003d85;
  border-bottom: 0;
  text-decoration: none !important;
}

.pure-tabs-contents div.pure_loading,
div.pure-tabs-result {
  display: none;
  border: 1px solid #DDDDD9;
  margin-top: 0;
  background: #fff;
  padding: 12px;
}

.tx-pure-pi {
  display: block;
}

.pure-simple-person-single {
  float: left;
  width: 300px;
  min-height: 200px;
  min-width: 20em;
  margin: 0 0.8em 0.8em 0;
  padding: 0.8em;
  border: 1px solid #DDDDD9;
  background-position: left bottom;
  background-repeat: no-repeat;
  position: relative;
}

div#au_content .pure-simple-person-single h3 {
  margin-top: 0.4em;
  margin-bottom: 0;
  padding-bottom: 0;
}

div#au_content .pure-simple-person-single h3 a {
  text-decoration: none;
}

div#au_content .pure-simple-person-single h3 a:hover {
  text-decoration: underline;
}

.pure-simple-person-single span.title {
  font-variant: small-caps;
  display: block;
  margin-bottom: 0;
  padding-bottom: 0;
}

.pure-simple-person-single img {
  position: absolute;
  top: 4.3em;
  max-height: 110px;
  max-width: 90px !important;
  right: 1.4em;
}

.pure-simple-person {
  display: table;
  overflow: auto;
}

.activities-simple,
.clippings-simple,
.persons-simple,
.projects-simple,
.publication-simple {
  display: table;
  overflow: auto;
  width: 100%;
}

.activities-simple .activities,
.clippings-simple .clippings,
.persons-simple .persons,
.projects-simple .projects,
.publication-simple .publications {
  float: left;
  position: relative;
  width: 100%;
}

.activities-simple .browse-links,
.clippings-simple .browse-links,
.persons-simple .browse-links,
.projects-simple .browse-links,
.publication-simple .browse-links {
  position: relative;
  float: left;
}

.activities-simple a.feedlink,
.clippings-simple a.feedlink,
.persons-simple a.feedlink,
.projects-simple a.feedlink,
.publication-simple a.feedlink {
  background: transparent url("") no-repeat scroll top right;
  border: medium none !important;
  color: #777;
  float: right;
  position: relative;
  right: 0;
  height: 14px;
  padding-right: 16px;
}

.activities-simple a.feedlink:hover,
.clippings-simple a.feedlink:hover,
.persons-simple a.feedlink:hover,
.projects-simple a.feedlink:hover,
.publication-simple a.feedlink:hover {
  background-position: bottom right;
}

.activities-simple .sorting,
.clippings-simple .sorting,
.persons-simple .sorting,
.projects-simple .sorting,
.publication-simple .sorting {
  position: relative;
  float: left;
}

.dropdown-sorting {
  float: right;
  padding: 0 0 0 10px;
}

.tx-pure-sorting-head {
  background-color: #f2f2f2;
  cursor: pointer;
  padding: 1rem;
  display: block;
  min-width: 180px;
}

.tx-pure-sorting-body {
  display: block;
  background-color: #f2f2f2;
  position: absolute;
  z-index: 20;
  padding: 1rem;
  font-size: 85%;
  min-width: 180px;
}

span.pubtype {
  color: #666;
  font-size: smaller;
  font-variant: small-caps;
  margin-left: 0.4em;
  margin-right: 0.4em;
}

span.pubstatus {
  background-color: #999;
  color: #FFF;
  font-size: smaller;
  font-variant: small-caps;
  margin-left: 0.2em;
  margin-right: 0.2em;
  padding-left: 0.3em;
  padding-right: 0.3em;
}

table.pure-persons-table {
  margin: 10px 0 15px;
  width: 100%;
  text-align: left;
}

table.pure-persons-table tfoot tr th,
table.pure-persons-table thead tr th {
  background-color: #e6EEEE;
  border: 1px solid #FFF;
  padding: 4px;
}

table.pure-persons-table thead tr th {
  font-weight: 700;
  text-transform: uppercase;
}

table.pure-persons-table thead tr .header {
  background-image: url("");
  background-repeat: no-repeat;
  background-position: center right;
  cursor: pointer;
  padding-right: 21px !important;
}

table.pure-persons-table tbody td {
  color: #3D3D3D;
  padding: 2px;
  background-color: #FFF;
  vertical-align: top;
}

table.pure-persons-table tbody tr.odd td {
  background-color: #F0F0F6;
}

table.pure-persons-table thead tr .headerSortUp {
  background-image: url("");
}

table.pure-persons-table thead tr .headerSortDown {
  background-image: url("");
}

table.pure-persons-table thead tr .headerSortDown,
table.pure-persons-table thead tr .headerSortUp {
  background-color: #8dbdd8;
}

.pure_search_form {
  background-color: #EEEEED;
  padding: 2rem;
  white-space: nowrap;
  overflow: auto;
}
.pure_search_form select {
  width: initial;
  margin: 0;
}
.pure_search_form select[name="tx_pure_pi[organisationIds]"] {
  width: 100% !important;
}
.pure_search_form tbody {
  background: none;
}
.pure_search_form tbody td {
  padding: 1em 0;
}

.pure_search_form select[name="tx_pure_pi[organisationIds]"],
.pure_search_form select[name="tx_pure_pi[publicationCategories]"] {
  width: initial;
}

div.pure-search input[type=submit] {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 1.55rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #002546;
  color: #fefefe;
  font-family: AUPassataRegular;
  min-width: 150px;
}
[data-whatinput=mouse] div.pure-search input[type=submit] {
  outline: 0;
}
div.pure-search input[type=submit]:hover, div.pure-search input[type=submit]:focus {
  background-color: #001f3c;
  color: #fefefe;
}

.person-keyword a {
  text-decoration: none !important;
  color: #000 !important;
}

.pure-image-person a {
  border: 1px solid #DDDDD9;
  text-align: center;
  margin: 4px;
  width: 75px;
  display: inline-block;
  height: 100px;
  background-color: #EDEDED;
  vertical-align: middle;
  overflow: hidden;
}

.pure-image-person a img {
  max-width: 75px;
  max-height: 100px;
  vertical-align: middle;
}

.tx-pure-pi .message-information {
  background-color: #EAF7FF;
  background-image: url();
  color: #4C73A1;
  background-position: 10px 12px;
  background-repeat: no-repeat;
  border: 1px solid;
  margin-bottom: 4px;
  padding: 12px 12px 12px 36px;
}

.tx-pure-pi .typo3-message .message-header {
  font-size: 11px;
  font-weight: 700;
}

div#au_content div.tx-pure-pi .persons li,
div#au_content div.tx-pure-pi li.portal_list_item {
  list-style-type: none !important;
}

div.tx-pure-pi li.portal_list_item a.link {
  border-bottom: none !important;
}

div#au_content ol.portal_list {
  margin: 0 !important;
}

.pure-simple-person-single {
  background-color: #f0eeef;
  border: 0;
  width: 100%;
  max-width: 305px;
}
.pure-simple-person-single a {
  border: 0 !important;
}
.pure-simple-person-single span.title {
  font-variant: normal;
  display: block;
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 85%;
}
.pure-simple-person-single img {
  position: absolute;
  bottom: 1em !important;
  max-height: 110px;
  max-width: 90px !important;
  right: 1em;
  top: inherit;
}
.pure-simple-person-single .organisation {
  display: block;
  margin: 0.5em 33.33% 0.8em 0;
  line-height: 1.2em;
  font-size: 85%;
}
.pure-simple-person-single .icon {
  font-family: "au_icons";
  margin-right: 0.5em;
  color: #9C9C9C;
  font-size: 115%;
  line-height: 1em;
}
.pure-simple-person-single .details {
  margin: 0.8em 30% 0.8em 0;
  font-size: 85%;
}
.pure-simple-person-single h3 {
  margin-top: 0.2em !important;
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 40em) {
  .pure-simple-person-single {
    max-width: none;
    margin: 1em 0;
  }
}
.pure-simple-person-info {
  width: 180px;
  padding-top: 1em;
}

.pure-simple-person-single .icon {
  font-size: 125%;
  vertical-align: middle;
  color: #666;
  font-weight: normal;
}

div.pure5 a {
  text-decoration-style: dotted;
  text-decoration-color: #888;
}

/*Og reducere størrelsen af sekundære grupperinger <h2> (den primære gruppering har også en <h2>)*/
div.pure5 .groupby-header + .groupby-header h2 {
  font-size: medium;
}

div.pure5 h2.title {
  font-size: medium;
}

div.pure5 div.pure-full-publication td {
  vertical-align: baseline;
  padding: 0 0.90909rem;
}

div.pure5 div.pure-full-publication td:first-child {
  width: 100px;
  /* lidt mindre end inline nowrap text */
}

div.pure5 .rendering.rendering_researchoutput {
  display: list-item;
  margin-left: 1.5rem;
  list-style-type: none;
}

div.pure5 .rendering.rendering_researchoutput::before {
  font-family: au_icons;
  content: "≥";
  font-size: 0.8rem;
  position: absolute;
  margin-left: -1.5rem;
  line-height: 2.5em;
}

div.pure5 div.rendering_clipping p.type,
div.pure5 div.rendering_clipping p.clippingShortRendererReferencesCount {
  display: none;
}

div.pure5 div.rendering_clipping h2.title + p {
  margin: 0;
}

div.pure5 .rss-icon a.feedlink::before {
  content: "r";
  font-family: "au_icons";
  background-color: #fe8d22;
  color: #fff;
  font-size: 1.4em;
}

div.pure5 .rss-icon a.feedlink {
  text-decoration: none !important;
  background: none;
  padding: 0;
}

div.pure5 .rss-icon {
  float: right;
  position: relative;
  height: 0;
  top: -2.82rem;
  padding: 0.264rem;
}

div.pure5 .tx-pure-sorting-body,
div.pure5 .tx-pure-sorting-head {
  background-color: #f2f2f2;
  padding: 0.2rem 1rem;
  display: block;
  min-width: 180px;
}

div.pure5 .tx-pure-sorting-body li::before {
  line-height: 2.2em;
}

div.pure5 .dropdown-sorting {
  float: none;
  padding: 0;
}

div.pure5 .sorting p {
  margin-bottom: 0;
}

/*styling of possible added onLeave-messages */
.pure5 .vcard .leave {
  background-color: #ece28a;
  padding: 0 8em 0 0.5rem;
  margin: 0 0 0.5em -0.3em;
}

.pure5 .vcard.pure-small-person-single .leave {
  padding: 0 0.5em;
  margin: -0.5em -0.5em 0.5em;
}

.pure5 .vcard.pure-simple-person-single.folded:nth-of-type(2n+1) {
  border-right: 1rem #aaa solid;
  border-bottom: none;
  margin-right: 0;
  clear: left;
}

@media only screen and (max-width: 61em) {
  .pure5 .vcard.pure-simple-person-single.folded:nth-of-type(2n+1) {
    border-right: none;
    border-bottom: 1rem #aaa solid;
    margin-bottom: 0;
  }

  .pure5 .vcard.pure-simple-person-single.folded:nth-of-type(2n) {
    margin-top: 0;
    padding-top: 0.4rem;
    font-size: 85%;
  }
}
/*folded vcards begin*/
.pure5 .vcard.pure-simple-person-single.folded:nth-of-type(2n+1) {
  border-right: 1rem #aaa solid;
  border-bottom: none;
  margin-right: 0;
  clear: left;
  max-width: calc(305px + 1rem);
}

@media only screen and (max-width: 61em) {
  .pure5 .vcard.pure-simple-person-single.folded:nth-of-type(2n+1) {
    border-right: none;
    border-bottom: 1rem #aaa solid;
    margin-bottom: 0;
    max-width: 100%;
    width: 100%;
  }

  .pure5 .vcard.pure-simple-person-single.folded:nth-of-type(2n) {
    margin-right: 0;
    margin-top: 0;
    padding-top: 0.4rem;
    font-size: 85%;
    max-width: 100%;
    width: 100%;
  }
}
.pure5 .pure-simple-person-single {
  min-height: 13.82em;
}

.pure5 .pure-simple-person-single.folded {
  height: 13.82em;
}

.pure5 .pure-simple-person-single.second {
  padding-right: 0;
}

.pure5 .pure-simple-person-single.second .extratext {
  font-size: 1.105rem;
  overflow: auto;
  height: 100%;
}

.pure5 .pure-simple-person-single.second .extratext h1,
.pure5 .pure-simple-person-single.second .extratext h2,
.pure5 .pure-simple-person-single.second .extratext h3 {
  font-size: 1.33636rem;
  margin: 0.6rem 0 0.1rem;
}

.pure5 .pure-simple-person-single.second .extratext ul li {
  margin-bottom: 0;
}

.pure5 .pure-simple-person-single.second .extratext ul li::before {
  line-height: 2.24em;
  font-weight: 400;
  color: #666;
}

/*folded vcards end*/
.pure5 .pure-simple-person-single.nophoto {
  min-height: 12.31em;
  padding-bottom: 0;
}

/*paging begin*/
.tx-pure-pure5-browsebox table {
  width: auto;
}

.tx-pure-pure5-browsebox td {
  border: 1px solid #c8c8c8;
  text-align: center;
  padding: 0;
}

.tx-pure-pure5-browsebox td p {
  padding: 0 0.4em;
  margin: 0;
}

.tx-pure-pure5-browsebox td p a {
  text-decoration: none;
  display: block;
  padding: 0 0.4em;
  margin: 0 -0.4em;
}

.tx-pure-pure5-browsebox-SCell {
  font-weight: bold;
}

/*paging end*/
/* nye */
div.neutral-container div[class*=bg-] .pure5 .vcard {
  background-color: rgba(0, 37, 70, 0.05);
  border-left: 1px solid #9c9c9c;
}

div.neutral-container div[class*=bg-dark] .pure5 .vcard {
  background-color: rgba(0, 37, 70, 0.35);
}

div.neutral-container div[class*=bg-] .pure5 .vcard:only-child {
  border: none;
}

div.neutral-container div[class*=bg-dark] .pure5 .vcard .icon {
  color: #9c9c9c;
}

/* rettet fra min-width: 20em;  */
.pure5 .vcard {
  min-width: 17em;
}

div.pure .rendering.googlemaps-component {
  display: none;
}

/*visitkort*/
.pure5 .pure-simple-person-single img {
  position: absolute;
  bottom: 1em !important;
  right: 1em;
  top: inherit;
  height: 110px;
  width: 80px;
  object-fit: cover;
  /*vertical-align: middle;  unødvendig slettes*/
}

/*portrætgalleri*/
.pure5 .pure-image-person a img {
  object-fit: cover;
  object-position: center;
  width: 73px;
  min-height: 100px;
  /*vertical-align: middle;  unødvendig slettes*/
  top: 0 !important;
}

/*pub.lister og lign*/
.pure5 .publication-simple .rendering {
  margin-bottom: 1rem;
}

/*ny supplerende rendering af personoplysninger ’minimal information’*/
.pure5 .pure-simple-person-classic h3 {
  margin-top: 0.2em !important;
}

.pure5 .pure-simple-person-classic .icon {
  font-size: 125%;
  vertical-align: middle;
  color: #666;
  font-weight: 400;
  font-family: au_icons;
  margin-right: 0.5em;
  line-height: 1em;
}

.pure5 .pure-simple-person-classic .details {
  margin: 0em 30% 0.8em 0;
  font-size: 85%;
}

div.view_person_photo img {
  width: 150px;
  min-height: 110px;
  /*ny*/
  object-fit: cover;
  /*ny*/
}

.pure5 .pure5-persons-table tr td:first-child {
  vertical-align: top;
}

div.pure div.view_container .portal_graphviewer_button {
  display: none;
}

.pure5 .rendering.rendering_researchoutput.rendering_apa a:last-child span,
.pure5 .rendering.rendering_researchoutput.rendering_harvard a:last-child span {
  word-break: break-word;
}

/* tilføjes for at rette fejl med vores kunstige bullets der sad fast og flød ud over den faste højde (jeg kan demonstrere) */
.pure5 .pure-simple-person-single.second .extratext ul li::before {
  position: inherit;
  margin-right: 0.5em;
}

/* de næste er definitioner for kommende <dl>-strukturer i dobbelte visitkort */
.pure5 .pure-simple-person-single.second .extratext dl dt {
  margin-bottom: 0;
  font-weight: 400;
}

.pure5 .pure-simple-person-single.second .extratext dl dd {
  margin-left: 2.5rem;
}

/* endeligt denne, der er identisk med den der er gældende for .pure5 .pure-simple-person-single.second .extratext ul li::before */
.pure5 .pure-simple-person-single.second .extratext dl dt::before {
  content: "≥";
  font-family: au_icons;
  margin-right: 0.5rem;
  font-size: 0.8rem;
  line-height: 2.24em;
  color: #666;
}

.pure5 .persons-simple.pure-simple-person .details br + br,
.pure5 .persons-simple.pure-simple-person .extratext br:nth-child(2n+1),
.pure5 .persons-simple.pure-simple-person .extratext h2 + br,
.pure5 .persons-simple.pure-simple-person .extratext h3 + br {
  display: none;
}

.pure5 .pure-simple-person-single.folded:not(.second) {
  border-right: 6px solid #ccc !important;
  max-width: 311px;
}

.pure5 .pure-simple-person-single.folded.second {
  padding: 0 0 0 0.8rem;
}

.pure5 .pure-simple-person-single.folded.second .extratext {
  scrollbar-width: thin;
  scrollbar-color: #ccc transparent;
  padding: 1.4rem 0;
}

.pure5 .pure-simple-person-single.folded.second .extratext::-webkit-scrollbar {
  width: 6px;
  height: 100%;
}

.pure5 .pure-simple-person-single.folded.second .extratext::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

@media only screen and (max-width: 84em) {
  :not(.related) .pure5 .vcard.pure-simple-person-single.folded:not(.second) {
    border-right: none !important;
    border-bottom: 6px #ccc solid;
    margin-bottom: 0;
    max-width: 100%;
    width: 100%;
  }

  :not(.related) .pure5 .vcard.pure-simple-person-single.folded.second {
    padding-top: 0;
    font-size: 85%;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 1.8rem;
    max-width: 100%;
    width: 100%;
  }

  :not(.related) .pure5 .pure-simple-person-single.folded.second .extratext {
    height: calc(100% - 0.4rem);
    padding: 0.8rem 0;
    margin-top: 0.4rem;
  }
}
.related .pure5 .vcard.pure-simple-person-single.folded:not(.second) {
  border-right: none !important;
  border-bottom: 6px #ccc solid;
  margin-bottom: 0;
  max-width: 100%;
  width: 100%;
}

.related .pure5 .vcard.pure-simple-person-single.folded.second {
  padding-top: 0;
  font-size: 85%;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 1.8rem;
  max-width: 100%;
  width: 100%;
}

.related .pure5 .pure-simple-person-single.folded.second .extratext {
  height: calc(100% - 0.4rem);
  padding: 0.8rem 0;
  margin-top: 0.4rem;
}

/*########################## COOKIES ALERT ##########################*/
#au_cookiesalert {
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #3a3a3a;
  background-color: rgba(58, 58, 58, 0.97);
  z-index: 9999;
  color: #fff;
  margin: 0 auto;
  overflow: auto;
  padding: 2rem;
}
#au_cookiesalert h1 {
  border: none;
  padding: 0;
  font-size: 170%;
  line-height: 1.2em;
  margin-bottom: 1em;
  color: #fff;
}
#au_cookiesalert h2 {
  text-align: center;
  padding-bottom: 1em;
}
#au_cookiesalert a {
  color: #fff;
  text-decoration: underline;
}
#au_cookiesalert button {
  color: #fff;
  cursor: pointer;
  font-family: "AUPassataRegular";
}
#au_cookiesalert p {
  float: left;
  max-width: 600px;
}
#au_cookiesalert p.button {
  float: right;
  background: #7ab51d;
}
@media screen and (max-width: 39.9375em) {
  #au_cookiesalert {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  #au_cookiesalert h1 {
    font-size: 130% !important;
  }
  #au_cookiesalert p.button {
    padding-top: 0.8rem;
  }
  #au_cookiesalert p.button button {
    font-size: 1.2rem;
  }
}

/*########################## IE WARNING ##########################*/
.ie-warning h1, .ie-warning h2, .ie-warning h3, .ie-warning h4 {
  margin-bottom: 0.1rem;
  color: #0a0a0a;
}

.ie-warning a {
  color: #0a0a0a;
  padding: 0.6rem;
  flex: 1 1 25rem;
  max-width: 63.636365rem;
}

.ie-warning a:hover {
  text-decoration: none;
  color: rgba(10, 10, 10, 0.65);
}

.ie-warning p {
  margin-bottom: 0.1rem;
  color: #0a0a0a;
}

.ie-warning p:last-chilf {
  margin-bottom: 0rem;
}

.ie-warning {
  background-color: #fabb00;
  margin-bottom: 0;
  padding: 0.6rem;
  overflow: auto;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.align-left {
  text-align: left !important;
}

.align-right {
  text-align: right !important;
}

.align-center {
  text-align: center !important;
}

.right {
  float: right !important;
}

.left {
  float: left !important;
}

#au_gsa-smiley {
  position: absolute;
  width: 325px;
  background: #313131;
  background: rgba(49, 49, 49, 0.95);
  top: 18px;
  right: 18px;
  color: #fff;
  text-align: center !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1;
}
#au_gsa-smiley h2 {
  text-align: center !important;
  text-transform: uppercase;
  font-size: 22px !important;
  position: relative !important;
}
#au_gsa-smiley div {
  text-align: center !important;
}
#au_gsa-smiley a {
  color: #b5b5b5 !important;
  border-bottom: 1px dotted #b5b5b5;
}
#au_gsa-smiley a:hover {
  text-decoration: none;
  color: #fff;
  border-bottom: 1px solid #fff;
}
#au_gsa-smiley textarea {
  width: 280px;
}
#au_gsa-smiley input[type=submit] {
  font: 22px "AUPassataRegular";
  text-transform: uppercase;
  background: 0;
  border: 0;
  color: #fff;
  cursor: pointer;
  margin: 0.2em;
}

#au_gsa-smiley-partone {
  padding: 14px;
}

#au_gsa-smiley-parttwo {
  padding: 14px;
  padding-top: 1px;
  box-shadow: inset 1px 4px 9px -6px black;
  background: #2f2f2f;
  background: rgba(47, 47, 47, 0.95);
  display: none;
}
#au_gsa-smiley-parttwo h2 {
  text-transform: none !important;
  margin: 0.5em 0 !important;
  line-height: 1em;
}
#au_gsa-smiley-parttwo h2.happy {
  color: #7ab51d;
}
#au_gsa-smiley-parttwo h2.neither {
  color: #f9ba00;
}
#au_gsa-smiley-parttwo h2.unhappy {
  color: #f13a6b;
}

#au_gsa-smiley-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040;
  background-image: url("https://cdn.au.dk/2015/news/graphics/fancybox_sprite.png");
}

#au_gsa-smiley-happy, #au_gsa-smiley-neither, #au_gsa-smiley-unhappy {
  width: 60px;
  height: 56px;
  display: inline-block;
  cursor: pointer;
  margin: 1em;
}

#au_gsa-smiley-happy {
  background: url("https://cdn.au.dk/2015/au/graphics/survey/gsa_test-smileyfaces.png") 2px -56px no-repeat;
}
#au_gsa-smiley-happy.selected {
  background-position: 2px 0;
}

#au_gsa-smiley-neither {
  background: url("https://cdn.au.dk/2015/au/graphics/survey/gsa_test-smileyfaces.png") -89px -56px no-repeat;
}
#au_gsa-smiley-neither.selected {
  background-position: -89px 0;
}

#au_gsa-smiley-unhappy {
  background: url("https://cdn.au.dk/2015/au/graphics/survey/gsa_test-smileyfaces.png") -178px -56px no-repeat;
}
#au_gsa-smiley-unhappy.selected {
  background-position: -178px 0;
}

.au_survey_like {
  position: fixed;
  height: 280px;
  background: #313131;
  background: rgba(49, 49, 49, 0.95);
  bottom: 70px;
  right: 0;
  color: #fff !important;
  text-align: center !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.au_survey_like.embedded {
  clear: both;
  position: static;
  z-index: auto;
  box-shadow: none;
  height: auto;
  padding: 1em;
  background: #f6f6f6;
  color: #212121;
  border: 1px solid #ccc;
}
.au_survey_like.embedded.step2, .au_survey_like.embedded.step3, .au_survey_like.embedded.step4 {
  width: auto;
}
.au_survey_like.embedded > div {
  float: none;
}
.au_survey_like.embedded > div:first-child {
  width: auto;
  height: auto;
}
.au_survey_like.embedded > div:first-child p {
  position: static;
  -ms-transform: none;
  -webkit-transform: none;
  transform: none;
  width: auto;
  margin: auto;
  text-align: center;
}
.au_survey_like.embedded > div:first-child p a {
  color: #212121 !important;
  height: auto;
  padding: 0;
  border-bottom: none !important;
}
.au_survey_like.embedded > div:first-child p a:hover, .au_survey_like.embedded > div:first-child p a:visited {
  color: #212121 !important;
}
.au_survey_like.embedded > div:nth-child(2) > ul {
  margin: auto;
  max-width: 210px;
}
.au_survey_like.embedded > div:nth-child(2) > ul li {
  padding-left: 0 !important;
  line-height: 1 !important;
  float: left;
  display: inline-block;
  margin: 5px !important;
  background: url("https://cdn.au.dk/2015/au/graphics/survey/gsa_test-smileyfaces2.png") no-repeat !important;
}
.au_survey_like.embedded > div:nth-child(2) > ul li a {
  border-bottom: none !important;
}
.au_survey_like.embedded > div:nth-child(2) > ul + p {
  clear: both;
  margin-bottom: 0;
  text-align: center;
}
.au_survey_like.embedded > div:nth-child(3) {
  text-align: center;
}
.au_survey_like.embedded > div:nth-child(3) h2 {
  margin: 0.5em 0 !important;
}
.au_survey_like.embedded > div:nth-child(3) textarea {
  width: 100%;
}
.au_survey_like.embedded > div:nth-child(3) input[type=submit] {
  display: block;
  margin: auto;
  color: #000;
}
.au_survey_like.embedded > div:nth-child(4) h2 {
  text-align: center;
  width: auto;
  padding: 50px 0;
}
.au_survey_like > div:nth-child(2) {
  display: none;
}
.au_survey_like > div:nth-child(3) {
  display: none;
}
.au_survey_like > div:nth-child(4) {
  display: none;
}
.au_survey_like > div:nth-child(5) {
  display: none;
}
.au_survey_like.step1 {
  width: 50px;
}
.au_survey_like.step2 {
  width: 128px;
}
.au_survey_like.step2 > div:nth-child(2) {
  display: block;
}
.au_survey_like.step2 > div:nth-child(5) {
  display: block;
}
.au_survey_like.step3 {
  width: 400px;
}
.au_survey_like.step3 > div:nth-child(2) {
  display: block;
}
.au_survey_like.step3 > div:nth-child(3) {
  display: block;
}
.au_survey_like.step3 > div:nth-child(5) {
  display: block;
}
.au_survey_like.step4 {
  width: 400px;
}
.au_survey_like.step4 > div:first-child {
  display: none;
}
.au_survey_like.step4 > div:nth-child(4) {
  display: block;
}
.au_survey_like.step4 > div:nth-child(5) {
  display: block;
}
.au_survey_like > div {
  float: left;
}
.au_survey_like > div:first-child {
  width: 50px;
  height: 280px;
}
.au_survey_like > div:first-child p {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -ms-transform-origin: left top;
  -webkit-transform-origin: left top 0;
  transform-origin: left top 0;
  width: 280px;
  position: absolute;
  left: 50px;
  font: 18px "AUPassataRegular";
}
.au_survey_like > div:first-child p a {
  color: #fff;
  display: block;
  padding-top: 12px;
  height: 38px;
}
.au_survey_like > div:first-child p a:hover {
  text-decoration: none;
}
.au_survey_like > div:nth-child(2) > ul {
  margin-top: 1.75em;
}
.au_survey_like > div:nth-child(2) > ul li {
  text-indent: -9999px;
  background: url("https://cdn.au.dk/2015/au/graphics/survey/gsa_test-smileyfaces.png") no-repeat !important;
  margin-bottom: 3rem;
}
.au_survey_like > div:nth-child(2) > ul li a {
  display: block;
  width: 58px;
  height: 56px;
}
.au_survey_like > div:nth-child(2) > ul li:first-child {
  background-position: 2px -56px !important;
}
.au_survey_like > div:nth-child(2) > ul li:nth-child(2) {
  background-position: -89px -56px !important;
}
.au_survey_like > div:nth-child(2) > ul li:nth-child(3) {
  background-position: -178px -56px !important;
}
.au_survey_like > div:nth-child(2) > ul li.selected:first-child {
  background-position: 2px 0px !important;
}
.au_survey_like > div:nth-child(2) > ul li.selected:nth-child(2) {
  background-position: -89px 0px !important;
}
.au_survey_like > div:nth-child(2) > ul li.selected:nth-child(3) {
  background-position: -178px 0px !important;
}
.au_survey_like > div:nth-child(3) {
  padding: 1rem 1.1rem 0 1.1rem;
}
.au_survey_like > div:nth-child(3) textarea {
  width: 266px;
  height: 160px;
}
.au_survey_like > div:nth-child(3) br {
  display: none;
}
.au_survey_like > div:nth-child(3) input[type=submit] {
  font: 22px "AUPassataRegular";
  text-transform: uppercase;
  background: 0;
  border: 0;
  color: #fff;
  cursor: pointer;
  margin: 0.2em;
}
.au_survey_like > div:nth-child(4) h2 {
  padding: 115px 0;
  width: 400px;
}
.au_survey_like > div:nth-child(5) {
  position: absolute;
  top: -18px;
  left: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040;
  background-image: url("https://cdn.au.dk/2015/news/graphics/fancybox_sprite.png");
}

@media print {
  .au_survey_like {
    display: none;
  }
}
.au_survey_taskily {
  position: fixed;
  width: 360px;
  background: #313131;
  background: rgba(49, 49, 49, 0.95);
  bottom: 150px;
  right: 0;
  color: #fff;
  text-align: center !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 99;
  /*
  &.catchme {
      left: 0;
      right: auto;
  }*/
}
.au_survey_taskily h2 {
  margin-bottom: 1em;
  line-height: 1.25;
}
.au_survey_taskily p.answers {
  width: 140px;
  margin: auto;
  font: 14px "AUPassataRegular";
  text-transform: uppercase;
}
.au_survey_taskily p.answers a {
  display: block;
  width: 60px;
  padding: 0.5em 0;
  color: #fff;
  margin-bottom: 1em;
}
.au_survey_taskily p.answers a.yes {
  float: left;
  background: #7ab51d;
}
.au_survey_taskily p.answers a.no {
  float: right;
  background: #e2001a;
}

#au_maps_route_from_container, #au_maps_route_to_container, #au_maps_building_content, #au_maps_direct_link {
  display: none;
}

#au_maps_route_directionspanel {
  margin-bottom: 1rem;
}

#au_maps_search_route .au_collapsible {
  background-color: #f9f9f9;
}
#au_maps_search_route .au_collapsible .csc-header h1 {
  background-color: transparent !important;
}

#au_maps_container {
  margin-bottom: 2rem;
}
#au_maps_container > .column > div, #au_maps_container > .columns > div {
  padding-top: 1rem;
  border-top: 1px solid #ccc;
  margin-bottom: 1rem;
}

#au_maps_overlay_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
#au_maps_overlay_container .button {
  text-decoration: none;
  width: 49%;
  margin-bottom: 0.65rem;
  cursor: pointer;
}

.au_maps_people_table .header, .au_maps_people_table th {
  font-weight: bold;
  cursor: pointer;
}

.au_maps_people_table .alignright {
  text-align: right;
}

.dataTables_paginate {
  margin: auto;
  text-align: center !important;
  width: 200px;
}

.dataTables_paginate .paginate_button, .dataTables_paginate .paginate_active {
  margin: 0px 3px;
  cursor: pointer;
}

.dataTables_paginate .paginate_active {
  text-decoration: underline;
}

.au-pie-container {
  clear: both;
  margin-bottom: 2em;
  overflow: auto;
}
.au-pie-container > div {
  float: left !important;
}
.au-pie-container .pie-legend {
  list-style: none;
  float: right;
  margin: 0 !important;
}
.au-pie-container .pie-legend li {
  display: block;
  padding-left: 30px !important;
  position: relative;
  margin-bottom: 4px;
  border-radius: 5px;
  padding: 2px 8px 2px 28px;
  font-size: 14px;
  cursor: default;
  -webkit-transition: background-color 200ms ease-in-out;
  -moz-transition: background-color 200ms ease-in-out;
  -o-transition: background-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out;
}
.au-pie-container .pie-legend li:hover {
  background-color: #fafafa;
}
.au-pie-container .pie-legend li span {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 100%;
}

#inputSearch {
  display: inline !important;
  font-size: 1em !important;
  width: 200px !important;
  padding: 0.72727rem !important;
}

#inputSearchAttribute {
  display: inline-block !important;
  font-size: 1em !important;
  width: 165px !important;
  margin: 0 1em !important;
  padding: 0.72727rem !important;
  padding-right: 2.18182rem !important;
}

@font-face {
  font-family: "font-awesome";
  src: url("https://fonts.au.dk/fonts/fa-pro-light-300.otf");
  font-weight: 300;
  font-style: normal;
}
.no-wrap {
  white-space: nowrap;
}

.avoid-wrap {
  display: inline-block;
}

/* Blå kasse */
.au-vacancy-filter-container {
  background-color: #003e5c;
  background-image: url("https://cdn.au.dk/apps/react/vacancies/latest/jobs-wide.svg");
  background-size: cover;
  overflow: visible;
  color: #fff;
  font-family: "AUPassataRegular";
  font-size: 1.5rem;
  position: relative;
  padding-top: 2rem;
  /* Række med hvide streger */
  /* PhD link */
  /* Filterknapper */
  /* Mørkerøde filterkasser */
  /* Filterknapper - begge størrelser */
}
.au-vacancy-filter-container.empty {
  background-image: url("https://cdn.au.dk/apps/react/vacancies/latest/empty-state-wide.svg");
  overflow: auto;
  margin-bottom: 1.3636364rem;
}
.au-vacancy-filter-container strong {
  font-family: "AUPassataBold";
  font-weight: normal;
}
.au-vacancy-filter-container > h1 {
  margin: 0 1rem 2rem 2rem !important;
  font-family: inherit;
  font-size: 3rem;
  font-weight: normal;
  line-height: 1.1;
}
.au-vacancy-filter-container > h1::first-letter {
  text-transform: uppercase;
}
.au-vacancy-filter-container .au-vacancy-vip-tap {
  position: relative;
  font-size: 1.81818181rem;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0.65rem 2rem 0.65rem 1rem;
  display: flex;
  flex-direction: row;
  /* vip/tap filterknapper */
}
.au-vacancy-filter-container .au-vacancy-vip-tap > div:not(.mobile) {
  padding-right: 1.2rem;
}
.au-vacancy-filter-container .au-vacancy-vip-tap > div > button {
  padding: 1rem 1.2rem 1rem 4rem;
  position: relative;
}
.au-vacancy-filter-container .au-vacancy-vip-tap > div > button:hover {
  color: rgba(255, 255, 255, 0.75);
}
.au-vacancy-filter-container .au-vacancy-vip-tap > div > button::after {
  text-align: center;
  width: 36px;
  height: 36px;
  top: 3px;
  left: 3px !important;
  line-height: 36px;
  font-size: 26px;
}
.au-vacancy-filter-container .au-vacancy-vip-tap > div > button:first-child {
  display: none;
}
.au-vacancy-filter-container .au-vacancy-vip-tap > div > button:first-child::before {
  content: "";
}
.au-vacancy-filter-container a.button.phd {
  margin: 0 0 0 auto;
  text-transform: uppercase;
  font-family: "AUPassataBold";
  padding-top: 14px;
  align-self: flex-start;
  white-space: nowrap;
}
.au-vacancy-filter-container a.button.phd:hover {
  background-color: #2b81a4 !important;
}
.au-vacancy-filter-container a.button.phd::after {
  font-family: "au_icons";
  content: ">";
  margin-left: 0.7rem;
}
.au-vacancy-filter-container a.button.phd.empty, .au-vacancy-filter-container a.button.phd.empty::after {
  background-color: transparent !important;
}
.au-vacancy-filter-container .au-vacancy-filter {
  padding: 0.5rem 2rem 0 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  /* Tilføj filter */
}
.au-vacancy-filter-container .au-vacancy-filter > span {
  display: inline-block;
  margin-right: 1rem;
  padding: 0.64rem 0;
  line-height: 1;
}
.au-vacancy-filter-container .au-vacancy-filter > div:not(.mobile) {
  position: relative;
  margin-bottom: 0.5rem;
}
.au-vacancy-filter-container .au-vacancy-filter > div:not(.mobile) > button {
  padding: 0.64rem 1rem;
  margin-right: 1rem;
  border-radius: 16px;
}
.au-vacancy-filter-container .au-vacancy-filter > div:not(.mobile) > button::after {
  width: 24px;
  height: 24px;
  top: 3px;
  left: 3px;
  line-height: 24px;
  font-size: 17px;
  text-align: center;
}
.au-vacancy-filter-container .au-vacancy-filter > div:not(.mobile) > button.selected {
  padding-left: 3rem;
  padding-right: 1.2rem;
}
.au-vacancy-filter-container .au-vacancy-filter .au-vacancy-filter-areas .au-vacancy-filter-list,
.au-vacancy-filter-container .au-vacancy-filter > div.mobile.au-vacancy-filter-list {
  /* Fagområde */
  width: 340px;
}
.au-vacancy-filter-container .au-vacancy-filter .au-vacancy-filter-areas .au-vacancy-filter-list li.area button span:last-child,
.au-vacancy-filter-container .au-vacancy-filter > div.mobile.au-vacancy-filter-list li.area button span:last-child {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.75);
}
.au-vacancy-filter-container .au-vacancy-filter .au-vacancy-filter-areas .au-vacancy-filter-list li.area button.item-selected span:last-child,
.au-vacancy-filter-container .au-vacancy-filter > div.mobile.au-vacancy-filter-list li.area button.item-selected span:last-child {
  color: #5b0c0c;
}
.au-vacancy-filter-container .au-vacancy-filter-list {
  position: absolute;
  top: 0.64rem;
  left: 1rem;
  z-index: 20;
  font-size: 1.5rem;
  background-color: #5b0c0c;
  width: 300px;
  padding-bottom: 0.5rem;
  visibility: hidden;
  opacity: 0;
  transform: rotate3d(0.5, 0, -0.05, 90deg) scale(0.85);
  transform-origin: 1.14rem 1rem;
  transition: all 170ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  /* Luk-kryds i mørkerødt filter */
}
.au-vacancy-filter-container .au-vacancy-filter-list.mobile {
  display: none;
}
.au-vacancy-filter-container .au-vacancy-filter-list.visible {
  visibility: visible;
  opacity: 1;
  transform: rotate3d(0, 0, 0, 0) scale(1);
  transition: all 170ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.au-vacancy-filter-container .au-vacancy-filter-list > span {
  display: block;
  margin: 1rem;
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.75);
  position: relative;
}
.au-vacancy-filter-container .au-vacancy-filter-list > span::before {
  display: inline-block;
  position: absolute;
  content: "";
  left: 0;
  bottom: -6px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.55);
  width: 20px;
}
.au-vacancy-filter-container .au-vacancy-filter-list > button:first-of-type {
  position: absolute;
  right: 0.65rem;
  top: 0.65rem;
  font-family: "au_icons";
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.75);
}
.au-vacancy-filter-container .au-vacancy-filter-list a.button {
  background-color: #e2007a !important;
  margin: 1rem 0 1rem 1rem;
}
.au-vacancy-filter-container .au-vacancy-filter-list a.button:hover {
  background-color: #af005e !important;
}
.au-vacancy-filter-container .au-vacancy-filter-list ul {
  margin: 0;
}
.au-vacancy-filter-container .au-vacancy-filter-list ul button {
  margin-right: 0;
  padding: 1rem;
  display: block;
  width: 100%;
}
.au-vacancy-filter-container .au-vacancy-filter-list ul button:hover {
  color: rgba(255, 255, 255, 0.75);
}
.au-vacancy-filter-container .au-vacancy-filter-list ul button.item-selected {
  color: #5b0c0c;
  background-color: #fff;
  border-radius: 0;
}
.au-vacancy-filter-container button {
  font-family: inherit;
  color: inherit;
  cursor: pointer;
  text-align: left;
}
.au-vacancy-filter-container button.filter {
  background-color: transparent;
  border-radius: 21px;
  transition: background 150ms 50ms cubic-bezier(0.645, 0.045, 0.355, 1);
  /* Mikroskop */
  /* Værktøjskasse */
}
.au-vacancy-filter-container button.filter:hover {
  color: rgba(255, 255, 255, 0.75);
}
.au-vacancy-filter-container button.filter::before, .au-vacancy-filter-container button.filter::after {
  display: inline-block;
  font-family: "font-awesome";
  font-weight: 300;
  position: absolute;
  border-radius: 50%;
}
.au-vacancy-filter-container button.filter::before {
  content: "";
  left: 1rem;
}
.au-vacancy-filter-container button.filter::after {
  content: "" !important;
  /* font-awesome X */
  background-color: #003e5c;
  color: #fff;
  opacity: 0;
  transform: rotate(-100deg) scale(0.3);
  transition: transform 150ms cubic-bezier(0.55, 0.055, 0.675, 0.19), opacity 150ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.au-vacancy-filter-container button.filter.selected {
  background-color: #fff;
  color: #003e5c;
  transition: background 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.au-vacancy-filter-container button.filter.selected:hover {
  color: #005d8a;
}
.au-vacancy-filter-container button.filter.selected:hover::after {
  background-color: #00537b;
}
.au-vacancy-filter-container button.filter.selected::after {
  opacity: 1;
  transform: rotate(0deg) scale(1);
  transition: transform 150ms 50ms cubic-bezier(0.215, 0.61, 0.355, 1), opacity 150ms 50ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.au-vacancy-filter-container button.filter.vip::before {
  content: "";
}
.au-vacancy-filter-container button.filter.tap::before {
  content: "";
}
.au-vacancy-filter-container a.button.empty {
  text-transform: uppercase;
  margin-left: 2rem;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  background-color: #37a0cb;
}

.au-vacancies-no-results {
  font: 1.81818182rem "AUPassataRegular";
  padding: 6rem 0 4rem 0;
  max-width: 550px;
  margin: auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.au-vacancies-no-results .meh-with-cap {
  display: inherit;
  flex: 0 0 auto;
  flex-direction: column;
}
.au-vacancies-no-results .meh-with-cap img {
  flex: 1 0 auto;
  margin-right: 2rem;
  min-width: 10rem;
}
.au-vacancies-no-results .no-results {
  flex: 1 0 300px;
}
.au-vacancies-no-results .no-results p:last-child {
  margin-bottom: 0;
}

.au-vacancies-list {
  margin: 2rem 0;
  font-family: "AUPassataRegular";
}
.au-vacancies-list p {
  font-family: Georgia;
}
.au-vacancies-list > li {
  border-bottom: 1px solid #e4e4e4;
  padding: 0.9rem 0 !important;
  opacity: 1;
  transition-property: opacity;
  transition-duration: 100ms;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-delay: 200ms;
}
.au-vacancies-list > li:nth-child(1) {
  transition-delay: 33.3333333333ms;
}
.au-vacancies-list > li:nth-child(2) {
  transition-delay: 66.6666666667ms;
}
.au-vacancies-list > li:nth-child(3) {
  transition-delay: 100ms;
}
.au-vacancies-list > li:nth-child(4) {
  transition-delay: 133.3333333333ms;
}
.au-vacancies-list > li:nth-child(5) {
  transition-delay: 166.6666666667ms;
}
.au-vacancies-list > li:first-child {
  border-top: 1px solid #e4e4e4;
}
.au-vacancies-list > li h2 {
  margin: 0;
  font-size: 1.636363rem;
}
.au-vacancies-list > li > a {
  display: flex;
  text-decoration: none;
}
.au-vacancies-list > li > a h2,
.au-vacancies-list > li > a p {
  color: #0a0a0a;
}
.au-vacancies-list > li > a p {
  margin-bottom: 0;
}
.au-vacancies-list > li > a:hover h2 {
  color: #e2007a;
}
.au-vacancies-list > li div {
  padding: 0.3rem 0;
}
.au-vacancies-list > li div:first-child, .au-vacancies-list > li div:last-child {
  text-align: right;
}
.au-vacancies-list > li div:first-child > span, .au-vacancies-list > li div:last-child > span {
  display: block;
}
.au-vacancies-list > li div:first-child {
  flex: 0 0 auto;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.au-vacancies-list > li div:first-child span:last-child {
  font-size: 1.636363rem;
  line-height: 1.4;
}
.au-vacancies-list > li div:nth-child(2) {
  flex: 1 1 auto;
  padding-right: 1.5rem;
}
.au-vacancies-list > li div:nth-child(2) span {
  color: #7b7b7b;
}
.au-vacancies-list > li div:last-child {
  flex: 0 0 290px;
}
.au-vacancies-list > li div:last-child span {
  color: #7b7b7b;
}
.au-vacancies-list > li.new div:nth-child(2) span:first-child::before {
  content: "Ny";
  display: inline-block;
  background-color: #37a0cb;
  color: #fff;
  padding: 2px 4px;
  margin-right: 0.5rem;
  line-height: 1;
  text-transform: uppercase;
  font-size: 1.2rem;
}
.au-vacancies-list.updating > li {
  opacity: 0.5;
  transition-delay: 0ms;
  transition-duration: 0ms;
  transition-timing-function: linear;
}

html[lang=en] .au-vacancies-list > li.new div:nth-child(2) span:first-child::before {
  content: "New";
}

.au-vacancies-jobagent {
  display: flex;
  flex-wrap: wrap;
  padding: 2.72727273rem 0 0 2.72727273rem;
  background-color: #5f0030;
  background-size: cover;
  margin-bottom: 2.7272723rem;
}
.au-vacancies-jobagent a.button {
  margin: 0;
  text-transform: uppercase;
  font-size: 1.2727273rem;
  flex-grow: 1;
}
.au-vacancies-jobagent a.button:hover {
  background-color: #e2007a !important;
}
.au-vacancies-jobagent > * {
  align-self: center;
  margin: 0 2.72727273rem 2.72727273rem 0 !important;
}
.au-vacancies-jobagent div {
  font: 1.4rem "AUPassataRegular";
  color: #fff;
  flex-grow: 1;
}
.au-vacancies-jobagent div h3 {
  margin-top: 0;
}
.au-vacancies-jobagent div p {
  margin-bottom: 0;
}

@media (max-width: 1023px) {
  .au-vacancy-filter-container {
    background-image: url("https://cdn.au.dk/apps/react/vacancies/latest/jobs-narrow.svg");
  }
  .au-vacancy-filter-container.empty {
    background-image: url("https://cdn.au.dk/apps/react/vacancies/latest/empty-state-narrow.svg");
  }
  .au-vacancy-filter-container .au-vacancy-vip-tap > div:first-child > button:nth-child(n+2),
.au-vacancy-filter-container .au-vacancy-vip-tap > a.button {
    display: none;
  }
  .au-vacancy-filter-container .au-vacancy-vip-tap > div button.vip-tap {
    display: initial;
  }
  .au-vacancy-filter-container .au-vacancy-vip-tap > div button.selected {
    display: initial !important;
  }
  .au-vacancy-filter-container .au-vacancy-vip-tap .au-vacancy-filter-list.mobile {
    top: 1rem;
  }
  .au-vacancy-filter-container .au-vacancy-filter {
    padding: 0 1rem;
  }
  .au-vacancy-filter-container .au-vacancy-filter > span {
    width: 100%;
    padding-left: 1rem;
    margin: 1rem 0;
    font-size: 1.2rem;
  }
  .au-vacancy-filter-container .au-vacancy-filter > div:not(.mobile) > button {
    margin-bottom: 1rem;
  }
  .au-vacancy-filter-container .au-vacancy-filter-list {
    display: none;
  }
  .au-vacancy-filter-container .au-vacancy-filter-list.mobile {
    display: block;
    width: auto !important;
    left: 1rem;
    right: 1rem;
    top: 4rem;
  }
  .au-vacancy-filter-container .au-vacancy-filter-list.mobile button.vip,
.au-vacancy-filter-container .au-vacancy-filter-list.mobile button.tap {
    padding-left: 3.5rem;
    border-radius: 0;
  }
  .au-vacancy-filter-container .au-vacancy-filter-list.mobile button.vip.selected,
.au-vacancy-filter-container .au-vacancy-filter-list.mobile button.tap.selected {
    color: #5b0c0c;
  }
  .au-vacancy-filter-container .au-vacancy-filter-list.mobile button.vip.selected::after,
.au-vacancy-filter-container .au-vacancy-filter-list.mobile button.tap.selected::after {
    display: none;
  }
  .au-vacancy-filter-container .au-vacancy-filter-list.mobile button.vip.selected::before,
.au-vacancy-filter-container .au-vacancy-filter-list.mobile button.tap.selected::before {
    color: #5b0c0c;
  }

  .au-vacancies-list > li div:last-child {
    flex-basis: 200px;
  }
}
@media (max-width: 1023px) {
  .au-vacancies-list > li a {
    flex-wrap: wrap;
    align-items: flex-end;
  }
  .au-vacancies-list > li div:first-child, .au-vacancies-list > li div:last-child {
    text-align: right;
  }
  .au-vacancies-list > li div:first-child {
    flex: 1 0 auto;
    order: 3;
    padding-right: 0;
  }
  .au-vacancies-list > li div:nth-child(2) {
    flex: 1 100%;
    order: 1;
    padding-bottom: 0;
  }
  .au-vacancies-list > li div:last-child {
    flex: 1 1 auto;
    order: 2;
    text-align: left;
    max-width: calc(100% - 120px);
  }
}
#au-vacancies-social {
  position: relative;
  border-top: 1px solid #e4e4e4;
  padding-top: 30px;
}

/* Single page CSS (moved from Dycon_Emply extension) */
.au-vacancies-single-content {
  display: grid;
  grid-template-columns: auto;
  grid-column-gap: 2.727273rem;
  grid-row-gap: 2.727273rem;
  grid-template-areas: "info" "text";
  margin-bottom: 2.727273rem;
}

.au-vacancies-single-text {
  grid-area: text;
}

.au-vacancies-single-text a {
  word-break: break-all;
}

.au-vacancies-single-text a[href^="https://au.emply.net/recruitment/vacancyApply.aspx"] {
  display: none;
}

@media (min-width: 950px) {
  .au-vacancies-single-content {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "info info" "text text";
  }
}
@media (min-width: 1024px) {
  .au-vacancies-single-content {
    grid-template-columns: auto 340px;
    grid-template-rows: auto auto 1fr;
    grid-template-areas: "text info" "text ." "text .";
  }

  .au-vacancies-vcard {
    align-self: start;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .au-vacancies-single-content {
    display: -ms-grid;
    -ms-grid-columns: auto 340px;
    -ms-grid-rows: auto 1fr auto auto;
  }

  .au-vacancies-single-text {
    -ms-grid-row: 1;
    -ms-grid-row-span: 4;
    -ms-grid-column: 1;
    margin-right: 2.727273rem;
  }

  .au-vacancies-infobox {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
  }

  .au-vacancies-vcard {
    display: block !important;
    margin-top: 2.727273rem;
    -ms-grid-row: 3;
    -ms-grid-column: 2;
  }

  .au-vacancies-vcard + .au-vacancies-vcard {
    -ms-grid-row: 4;
  }
}
.au-vacancies-single-intro {
  margin-bottom: 2.727273rem;
}

.au-vacancies-single-intro > p {
  font-size: 1.8rem;
  line-height: 1.4;
}

.au-vacancies-single ul {
  overflow: hidden;
}

.au-vacancies-single-intro > span {
  font: 1.63636364rem "AUPassataRegular";
  color: #7b7b7b;
}

.au-vacancies-infobox {
  padding: 1.81818182rem 1.81818182rem 2.72727273rem 1.81818182rem;
  background: #003e5c url("https://cdn.au.dk/apps/react/vacancies/latest/information.svg");
  background-size: cover;
  color: #fff;
  font-family: "AUPassataRegular";
  grid-area: info;
  align-self: start;
}

.au-vacancies-infobox strong {
  font-weight: normal;
  font-family: "AUPassataBold";
}

.au-vacancies-infobox > div {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 2.3636364rem;
}

.au-vacancies-infobox > div p {
  display: flex;
  flex-direction: column;
}

.au-vacancies-infobox > div p span:first-child {
  flex-grow: 1;
  line-height: 1.1;
}

.au-vacancies-infobox > div p:nth-child(1) {
  text-align: left;
}

.au-vacancies-infobox > div p:nth-child(2) {
  text-align: center;
  margin-left: 1rem;
  margin-right: 1rem;
}

.au-vacancies-infobox > div p:nth-child(3) {
  text-align: right;
}

.au-vacancies-infobox span {
  display: block;
}

.au-vacancies-infobox span.date {
  font-size: 1.63636364rem;
  white-space: nowrap;
}

.au-vacancies-infobox span.id {
  color: rgba(255, 255, 255, 0.6);
  display: inline-block;
  margin-top: 1rem;
}

.au-vacancies-infobox ul {
  margin-bottom: 2.1818182rem;
}

.au-vacancies-infobox ul > li {
  padding-left: 4.54545455rem !important;
  position: relative;
}

.au-vacancies-infobox ul > li::before {
  position: absolute;
  font: 3rem "font-awesome" !important;
  color: #fff;
}

.au-vacancies-infobox ul > li:first-child::before {
  left: 5px;
  content: "";
}

.au-vacancies-infobox ul > li:last-child::before {
  left: 3px;
  content: "";
}

.au-vacancies-infobox a.button,
.au-vacancies-apply a.button {
  margin: 0;
  text-transform: uppercase;
  font-size: 1.2727273rem;
}

.au-vacancies-infobox a.button {
  width: 100%;
}

.au-vacancies-infobox a.button:hover,
.au-vacancies-apply a.button:hover {
  background-color: #2b81a4 !important;
}

.au-vacancies-apply {
  display: flex;
  flex-wrap: wrap;
  padding: 2.72727273rem 0 0 2.72727273rem;
  background: #003e5c url("https://cdn.au.dk/apps/react/vacancies/latest/work-at-au.svg");
  background-size: cover;
  margin-bottom: 2.7272723rem;
}

.au-vacancies-apply > * {
  align-self: center;
  margin: 0 2.72727273rem 2.72727273rem 0 !important;
}

.au-vacancies-apply a.button {
  flex-grow: 1;
}

.au-vacancies-apply p {
  font: 1.63636364rem "AUPassataRegular";
  color: #fff;
  flex-grow: 1;
}

.au-vacancies-vcard {
  background-color: #f2f2f2;
  padding: 2.18181812rem;
  font-family: "AUPassataRegular";
}

.au-vacancies-vcard strong {
  font-weight: normal;
  font-family: "AUPassataBold";
}

.au-vacancies-vcard h2 {
  margin-top: 0 !important;
  width: 100%;
  font: 1.6rem "AUPassataRegular";
  margin-bottom: 1rem !important;
}

.au-vacancies-vcard p span {
  display: block;
}

.au-vacancies-vcard p span.contact-option {
  margin: 1rem 0 0 0;
}

.au-vacancies-vcard img {
  float: right;
  width: 90px;
  align-self: baseline;
  margin-left: 1rem;
}

@media (max-width: 640px) {
  .au-vacancies-infobox {
    float: none;
    width: auto;
  }

  .au-vacancies-apply > * {
    width: 100%;
  }

  .au-vacancies-apply a.button {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .au-vacancies-vcard {
    float: none;
    width: auto;
  }
}
.aubanner.au-info, .aubanner.au-about {
  min-height: 212px;
}

.aubanner.au-info h2 {
  font-family: "AUPassataLight";
  display: block;
  font-size: 36px;
  margin: 0;
  padding: 0.5rem 0 0.5rem 0;
}

.aubanner.au-info h2 strong {
  font-family: "AUPassataBold";
}

.aubanner.au-info::after {
  display: none;
}

.aubanner.au-info .aubanner-content {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.aubanner.au-info:hover::before {
  opacity: 0 !important;
}

#au-vacancies-social {
  position: relative;
  border-top: 1px solid #e4e4e4;
  padding-top: 30px;
}

@media print {
  .au-vacancies-single-content {
    display: block;
  }

  .au-vacancies-vcard {
    border: 1px solid black;
    float: left;
    width: calc(50% - .75rem);
    margin-right: 0.75rem;
    margin-bottom: 1.5rem;
  }

  .au-vacancies-vcard:last-of-type {
    margin-left: 0.75rem;
    margin-right: 0;
  }

  .au-vacancies-infobox {
    border: 1px solid black;
    background: #f2f2f2;
    float: right;
    margin-left: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .au-vacancies-infobox ul > li::before {
    color: black;
  }

  .aubanner, .au-vacancies-facts, .au-vacancies-apply, .au-vacancies-infobox a.button, .neutral-container {
    display: none;
  }
}
:focus {
  outline: none !important;
}

body.layout13 {
  background: #f5f5f5;
}

ul {
  margin-left: 0;
}
ul ul {
  margin-top: 0.65rem;
}

ol {
  margin-left: 2.2rem;
}
ol li {
  margin-bottom: 1.5rem;
}
ol li li {
  margin-left: 1rem;
}

.button,
label {
  font-family: "AUPassataRegular";
}

ul li,
.footer ul li,
.au_shortcuts ul li,
.universe-utility ul li {
  list-style: none;
  padding-left: 1.5rem;
  margin-bottom: 0.65rem;
}

.main ul li:before,
.footer ul li:before,
footer ul li:before,
.au_shortcuts .last ul li:before,
.universe-utility ul li:before,
.pagenav ul li:before,
.au_alphabox ul.items li:before,
.utility-links .au_databox .content ul li:before,
.top-bar-content ul li:before,
.target-group ul li:before,
.au_news_popup ul li:before {
  font-family: "au_icons";
  content: "≥";
  font-size: 0.8rem;
  position: absolute;
  margin-left: -1.5rem;
  line-height: 2.5em;
  font-weight: bold;
}

.resetlist > li,
header .resetlist > li,
.header-menu .resetlist > li,
.main .resetlist > li,
header .resetlist > li:before,
.main .resetlist > li:before,
.resetlist > li:before,
.au_news_popup .resetlist > li:before {
  margin: 0;
  padding: 0;
  background-image: none;
  content: "";
  list-style-type: none;
}

.indent {
  padding-left: 1.4rem;
}

.main a {
  text-decoration: underline;
}
.main h1,
.main h2,
.main h3,
.main h4 {
  margin-top: 1em;
  margin-bottom: 0;
}
.main .aubanner a {
  text-decoration: none;
}

.hero {
  background-color: transparent;
  background-size: cover;
  background-position: top center;
  color: white;
  height: 600px;
  padding-top: 250px;
  font-family: "AUPassataRegular";
  font-size: 200%;
}
.layout13 .hero {
  margin-top: -150px;
}
@media screen and (min-width: 75em) {
  body:not(.layout13) .hero {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2.5em;
    height: 580px;
    background-size: initial;
    background-position-y: -20px;
  }
}
.hero .row .columns {
  padding-right: 340px;
}
@media screen and (max-width: 39.9375em) {
  .hero .row .columns {
    padding-right: 0;
  }
}
.hero a,
.hero a:hover {
  text-decoration: none;
}
.hero p {
  line-height: 1.4;
}
.hero h1,
.hero h2,
.hero h3,
.hero h4,
.hero h5,
.hero h6 {
  line-height: 1.2em;
}
.hero h1 {
  font-size: 350%;
}
.hero h2 {
  font-size: 300%;
}
.hero h3 {
  font-size: 250%;
}
.hero h4 {
  font-size: 200%;
}
.hero h5 {
  font-size: 150%;
}
.hero h6 {
  font-size: 125%;
}
@media screen and (max-width: 63.9375em) {
  .hero h1,
.hero h2,
.hero h3,
.hero h4,
.hero h5,
.hero h6 {
    font-size: 150%;
  }
}
@media screen and (max-width: 39.9375em) {
  .hero {
    padding-top: 180px !important;
  }
  .hero h1,
.hero h2,
.hero h3,
.hero h4,
.hero h5,
.hero h6 {
    font-size: 175%;
  }
  .hero p {
    line-height: 1.4;
    font-size: 0.9em;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  header .header-menu .columns .menu {
    font-size: calc(1vw + 8px) !important;
  }

  header .is-dropdown-submenu-parent a {
    padding: 1rem 1vw 1rem 1vw !important;
  }

  header .is-dropdown-submenu-parent:after {
    display: none;
  }

  .left-menu {
    padding: 2rem 1rem !important;
  }
}
.expanded .row {
  max-width: 127.2727272727rem;
  margin-left: auto;
  margin-right: auto;
}

.row.expanded .row .row {
  margin-left: -15px;
  margin-right: -15px;
}

header {
  font-family: "AUPassataRegular";
  position: relative;
  z-index: 2;
}
.department header, .general.neutral header {
  height: 150px;
}
header .section-header.row {
  height: 96px;
  position: relative;
}
header .section-header.row .columns {
  background-color: #002546;
  color: #fff;
}
@media screen and (min-width: 40em) {
  .department header .section-header.row {
    height: 50px;
  }
}
.department header {
  height: auto;
}
.department header .header-menu .columns {
  background-color: #fff;
}
@media screen and (min-width: 40em) {
  .department header .header-menu .columns {
    border-bottom: 1px solid #E4E4E4;
  }
}
.department header .find,
.department header .search,
.department header .utility-links {
  position: absolute;
  right: 0;
}
.department header .search {
  width: 20px;
  right: 2rem;
  top: 0;
  padding-top: 0.5rem;
  padding-left: 1rem;
  border-left: 1px solid rgba(255, 255, 255, 0.15);
}
.department header .search input {
  border: 0 !important;
}
@media screen and (max-width: 39.9375em) {
  .department header .search {
    padding: 0 1.6rem;
    height: 60px;
  }
}
.department header .find {
  right: 40px;
  padding-top: 0.3rem;
  top: 0;
  border-left: 1px solid rgba(255, 255, 255, 0.15);
}
.department header .find .find-button {
  padding-right: 40px;
  font-size: 1.45rem;
}
.department header .utility-links {
  left: auto;
  right: 14rem;
}
.department header .opensearch .search {
  width: 200px;
  right: 0;
  top: 0.5rem;
  transition: 0.2s;
  border-left: 0;
  padding: 0 1rem;
}
.department header .opensearch .find,
.department header .opensearch .utility-links,
.department header .opensearch .language-selector {
  display: none;
}
.department header .find-button:after {
  font-family: "au_icons";
  content: "f";
  font-size: 3rem;
  position: absolute;
  height: 25px;
  width: 34px;
  color: #fff;
  z-index: 100;
  display: block;
  top: 1rem;
  right: 6px;
  overflow: hidden;
}
header .header-menu .columns {
  background-color: #f2f2f2;
  padding: 0;
  height: auto;
  z-index: 98;
}
header .header-menu .columns .menu {
  font-size: 1.5rem;
}
header .header-menu .columns .menu .menu {
  font-size: 100%;
}
header .language-selector a {
  display: block;
  position: relative;
  top: 0.75em;
  padding-right: 1em;
}
@media screen and (max-width: 39.9375em) {
  header .language-selector {
    position: absolute;
    border: 0 !important;
    display: block;
    text-align: left;
    background: none !important;
    padding-left: 15px !important;
  }
}
header .seal img {
  width: 75px;
  padding-top: 10px;
}
header .top-bar-content {
  position: absolute;
  left: 0;
  right: 0;
  background: #e6e6e6;
  max-width: 127.27273rem;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 0;
  z-index: 99;
  display: none;
}
header .top-bar-content > .row {
  margin-left: 0;
  margin-right: 0;
}
header .top-bar-content h5 {
  font-size: 2rem;
}
@media screen and (max-width: 39.9375em) {
  header .top-bar-content {
    position: static;
  }
}

.opensearch .au_searchform:before {
  content: "";
}

@media screen and (min-width: 40em) {
  .general:not(.neutral) header .top-bar .menu {
    position: relative;
  }
}
@media screen and (max-width: 39.9375em) {
  .general header .top-bar .menu,
.department header .top-bar.custom .menu {
    display: block;
  }
}
.general header .top-bar .menu > li:hover,
.department header .top-bar.custom .menu > li:hover {
  background-color: #F2F2F2;
}
.general header .top-bar .menu .active > a:hover,
.department header .top-bar.custom .menu .active > a:hover {
  background-color: #F2F2F2;
}
.general header .top-bar .menu > li > a,
.department header .top-bar.custom .menu > li > a {
  position: relative;
  padding: 1.2rem;
  padding-right: 3rem;
}
.general header .top-bar .menu > li > a:hover,
.department header .top-bar.custom .menu > li > a:hover {
  color: #af005e;
  text-decoration: none;
}
.general header .top-bar .menu > li > a:after,
.department header .top-bar.custom .menu > li > a:after {
  position: absolute;
  font-family: "au_icons";
  font-size: 0.9rem;
  content: "≥";
  transition: 0.7s;
  transform: rotate(90deg);
  right: 1.5rem;
  top: 1.6rem;
}
.general header .top-bar .menu > li > a:hover:after,
.department header .top-bar.custom .menu > li > a:hover:after {
  transition: 0.2s;
  transform: rotate(450deg);
  color: #af005e;
  text-decoration: none;
}

body.general.neutral header .menu a,
body.department header .menu a {
  padding: 1.2rem;
}
body.general.neutral header .header-menu:not(.open) .menu > li:hover,
body.department header .header-menu:not(.open) .menu > li:hover {
  background-color: #e6e6e6;
}
body.general.neutral header .header-menu:not(.open) .menu li li,
body.department header .header-menu:not(.open) .menu li li {
  background-color: #e6e6e6;
  margin: 0;
  padding: 0;
}
body.general.neutral header .header-menu:not(.open) .menu .active > a:hover,
body.department header .header-menu:not(.open) .menu .active > a:hover {
  background-color: #e6e6e6;
}
body.general.neutral header .is-dropdown-submenu-parent a,
body.department header .is-dropdown-submenu-parent a {
  padding: 1.2rem;
  padding-right: 3rem;
}
body.general.neutral header .is-dropdown-submenu-parent a:hover,
body.department header .is-dropdown-submenu-parent a:hover {
  color: #2b81a4;
  text-decoration: none;
}
body.general.neutral header .is-dropdown-submenu-parent:after,
body.department header .is-dropdown-submenu-parent:after {
  position: absolute;
  font-family: "au_icons";
  font-size: 0.9rem;
  content: "≥";
  transition: 0.7s;
  transform: rotate(90deg);
  right: 1.5rem;
  top: 1.2rem;
}
body.general.neutral header .is-dropdown-submenu-parent:hover:after,
body.department header .is-dropdown-submenu-parent:hover:after {
  transition: 0.2s;
  transform: rotate(450deg);
  color: #2b81a4;
  text-decoration: none;
}

.au_searchbox {
  z-index: 1;
  font-size: 1rem;
  cursor: pointer;
}
.au_searchbox fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.au_searchbox legend, .au_searchbox .au_searchbox_button {
  display: none;
}

.utility-links {
  position: absolute;
  left: 50%;
  font-size: 1.2rem;
  top: 1em;
}
:lang(en) .utility-links {
  left: 45%;
}
.utility-links ul.utility {
  margin: 0;
  column-count: 2;
  column-gap: 0.8rem;
}
.utility-links ul.utility > li:nth-child(3) {
  display: inline-block;
}
:lang(en) .utility-links ul.utility > li:nth-child(n+3) {
  margin-right: -70px;
}
.utility-links ul.utility > li {
  list-style: none;
  margin: 0;
}
.utility-links ul.utility > li > a {
  color: #fefefe;
  padding-bottom: 0;
  display: block;
}
.department .utility-links ul.utility > li > a {
  height: 36px;
}
.utility-links ul.utility > li > a.float-right {
  height: 36.5px;
}
.utility-links ul.utility > li > a:after {
  content: "≥";
  color: white;
  font-family: au_icons;
  transform: rotate(90deg);
  display: inline-block;
  font-size: 1rem;
  margin-left: 0.5rem;
}
.utility-links ul.utility > li > a:hover {
  text-decoration: none;
}
.utility-links ul.utility > li > a:hover:after {
  transition: 0.2s;
  transform: rotate(450deg);
}
.utility-links .dropdown-pane {
  padding: 0;
  z-index: 99;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .utility-links {
    left: 40% !important;
  }
}
.department .utility-links ul.utility {
  column-count: 3;
}

.study-entries {
  height: 0;
  overflow: visible;
  position: relative;
  font-family: "AUPassataRegular";
}
.study-entries .columns div {
  background-color: #5f0030;
  color: #fff;
  padding: 3em 2em;
  bottom: -5em;
  position: absolute;
  right: 0;
  min-width: 320px;
}
.study-entries .columns div h3 {
  font-size: 1.2rem;
  border-bottom: 2px solid #fff;
  padding-bottom: 1em;
}
.study-entries .columns div a {
  display: block;
  color: #fff;
}
.study-entries .columns div a:hover {
  color: #e2007a;
}
.study-entries .columns div ul {
  margin: 0;
}
.study-entries .columns div li {
  font-size: 120%;
  list-style-type: none;
  line-height: 3em;
  position: relative;
}
.study-entries .columns div li:hover:after {
  transition: 0.2s;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  color: #e2007a;
  text-align: center;
  background: #fff;
  position: absolute;
  right: 0;
  top: 0.75em;
  line-height: 32px;
  content: "≥";
  font-family: "au_icons";
  z-index: 2;
  font-size: 16px;
  margin-top: 0;
}
@media screen and (max-width: 39.9375em) {
  .study-entries {
    height: auto;
  }
  .study-entries .columns {
    padding-left: 0;
    padding-right: 0;
  }
  .study-entries .columns div {
    position: relative;
    bottom: auto;
    top: -2rem;
  }
}
@media (max-width: 320px) {
  .study-entries .columns div {
    min-width: 300px;
    top: 2rem;
  }
}

.featured-content {
  padding-top: 10rem;
}
.featured-content .featured-content-header {
  padding-bottom: 2em;
}
.featured-content .featured-content-header h3,
.featured-content .featured-content-header h4 {
  font-family: "AUPassataRegular", Verdana, sans-serif;
}
.featured-content .featured-content-header h4 {
  float: right;
  border-bottom: 2px solid;
  margin-left: 3em;
  height: 2.5rem;
  font-size: 1.4rem;
}
@media screen and (max-width: 39.9375em) {
  .featured-content .featured-content-header h4 {
    font-size: 1.2rem;
    margin-left: 1em;
    border: 0;
    padding-top: 0.25em;
  }
}
.featured-content .featured-content-header h4 a span {
  font-family: "au_icons";
  padding-right: 0.5em;
  font-size: 0.75em;
}
@media screen and (max-width: 39.9375em) {
  .featured-content .featured-content-header h4 a span {
    padding-right: 0;
  }
}
.featured-content .featured-content-header h4 a:hover {
  text-decoration: none;
}

.logo {
  position: relative;
}
.logo > a > h2 {
  font-family: "AUPassataRegular";
  font-size: 1.75rem;
  text-transform: uppercase;
  word-spacing: 1000px;
  line-height: 1em;
  margin: 1.2em;
  margin-left: 4.6em;
  width: 50%;
}
@media screen and (min-width: 40em) {
  .department .logo > a > h2 {
    font-size: 1.1rem;
    margin-top: 0.8em;
    margin-left: 5rem;
  }
  .department .logo > a > h2:before {
    margin-top: 0.5rem;
  }
}
.logo > a > h2:before {
  content: "A";
  position: absolute;
  left: 2rem;
  font-family: "au_icons";
  font-size: 3.5em;
  margin-top: 0.75rem;
}
.logo > a {
  color: #fff;
}
.logo > a:hover {
  text-decoration: none;
}
.department .logo {
  font-size: 0.75em;
}

.au_searchform {
  margin-top: 2em;
  position: relative;
}
@media screen and (min-width: 40em) {
  .department .au_searchform {
    margin-top: 0;
  }
}
.au_searchform:before {
  font-family: "au_icons";
  content: "7";
  position: absolute;
  color: #fff;
  font-size: 200%;
  z-index: 1;
  top: 2px;
}
@media screen and (max-width: 39.9375em) {
  .au_searchform:before {
    font-size: 250%;
    top: -2px;
  }
}
.au_searchform:focus:before {
  color: #0a0a0a;
}
.au_searchform #tx_googlequery_searchform_ss_q,
.au_searchform #cludo-search-form-input {
  background: #002546;
  border: 0;
  border-bottom: 2px solid #687989;
  text-align: right;
  color: #fff;
}
.au_searchform #tx_googlequery_searchform_ss_q:focus,
.au_searchform #cludo-search-form-input:focus {
  background-color: white;
  border-bottom-color: transparent;
  color: #333;
  text-align: left;
}

.target-groups {
  padding: 5em;
  color: #fff;
  text-align: center;
}
.target-groups h2 {
  font-family: AUPassataRegular;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.6rem;
  margin-bottom: 3.2rem;
}
.target-groups .button {
  background-color: #2c0016;
  color: #fff;
  padding: 1em 3.5em;
  font-size: 1.5rem;
  margin: 0.25rem;
}
.target-groups .button.active {
  background-color: #e2007a;
}

.target-group {
  background: url("https://cdn.au.dk/2016/assets/img/misc/aias.jpg") center center;
  background-size: cover;
}
.target-group > div > .csc-default {
  display: none;
  padding-top: 5em;
  padding-bottom: 3em;
  color: #fff;
}
.target-group > div > .csc-default.active {
  display: block;
}
.target-group > div > .csc-default a {
  color: #fff;
  text-decoration: underline;
}
.target-group ul li:before {
  color: #fff;
}
.target-group .row {
  margin-bottom: 2em;
}

footer.global {
  margin-top: 3em;
}
footer.global .columns {
  background-color: #002546;
  color: #fff;
  padding-top: 2em;
  padding-bottom: 2em;
}
footer.global .columns h3 {
  font-size: 110%;
  font-family: "AUPassataBold";
  margin-bottom: 2rem;
  text-transform: uppercase;
}
footer.global .columns .seal {
  width: 33%;
  margin-left: 2em;
}
@media screen and (max-width: 39.9375em) {
  footer.global .columns .seal {
    width: 75%;
    margin-left: 1em;
  }
}
footer.global .columns a {
  color: #fff;
}
footer.global .columns a:hover {
  text-decoration: underline;
}
footer.global .columns li {
  margin-left: 0;
  list-style: none;
}
footer.global .columns li:before {
  color: #fff;
}
footer.global .columns ul {
  margin-left: 0;
}
footer.global .columns .muted a {
  color: #687989;
}
footer.global .columns .muted a:nth-of-type(-n+2) {
  display: inline-block;
}
footer.global .columns .muted a:nth-of-type(n+3) {
  display: block;
}

.footer {
  padding: 5rem 0;
  border-top: 1px solid #e4e4e4;
}
.footer .au_padding {
  display: flex;
  width: 100%;
}

.featured-links {
  padding: 10em 0;
}

.section-title h1,
.section-title h2 {
  font-family: "AUPassataRegular";
  font-size: 3.6rem;
  padding: 0.5em 0;
}
.section-title h1 a,
.section-title h2 a {
  color: #002546;
}
.section-title h1 a:hover,
.section-title h2 a:hover {
  text-decoration: none;
}
@media screen and (max-width: 39.9375em) {
  .section-title h1,
.section-title h2 {
    font-size: 2rem;
  }
}
.section-title .columns {
  border-bottom: 3px solid #002546;
}
@media screen and (min-width: 90em) {
  .section-title .columns {
    padding: 0.5em 0;
  }
}
.section-title a {
  color: #fff;
}

.section-department-center {
  background-color: #e6e6e6;
}
.section-department-center .top-bar {
  display: block !important;
}
.section-department-center .top-bar h1,
.section-department-center .top-bar h2,
.section-department-center .top-bar h3,
.section-department-center .top-bar h4,
.section-department-center .top-bar h5,
.section-department-center .top-bar h6 {
  margin: 0.68181818rem 0;
  font-family: "AUPassataRegular";
}
@media screen and (max-width: 39.9375em) {
  .section-department-center .top-bar h1,
.section-department-center .top-bar h2,
.section-department-center .top-bar h3,
.section-department-center .top-bar h4,
.section-department-center .top-bar h5,
.section-department-center .top-bar h6 {
    font-size: 1.2rem;
  }
}

.breadcrumb {
  text-align: right;
  color: #636363;
  font-family: "AUPassataRegular";
  font-size: 90%;
}
.breadcrumb .columns {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.breadcrumb strong {
  font-family: "AUPassataRegular";
  font-weight: normal;
}
.breadcrumb a {
  color: #7b7b7b;
  text-decoration: underline;
}
.breadcrumb .bc-expand {
  display: none;
}
.breadcrumb .collapse > span:not(.breadcrumb-prefix) {
  display: none;
}
.breadcrumb .collapse > span:not(.breadcrumb-prefix):first-of-type, .breadcrumb .collapse > span:not(.breadcrumb-prefix):nth-child(2), .breadcrumb .collapse > span:not(.breadcrumb-prefix):nth-last-of-type(2), .breadcrumb .collapse > span:not(.breadcrumb-prefix):last-of-type {
  display: inline;
}
.breadcrumb .collapse > a {
  display: none;
}
.breadcrumb .collapse > a:first-of-type, .breadcrumb .collapse > a:nth-child(2), .breadcrumb .collapse > a:nth-last-of-type(2), .breadcrumb .collapse > a:last-of-type {
  display: inline;
}
.breadcrumb .collapse .bc-expand {
  display: inline-block;
  cursor: pointer;
}
.breadcrumb .breadcrumb-prefix {
  display: none;
}
.breadcrumb .icon-bullet_rounded {
  font-size: 80%;
  cursor: none;
}

h1.pagetitle {
  font-size: 3.6rem;
  margin: 0 0 1.6rem 0;
}

@media screen and (max-width: 39.9375em) {
  .related {
    padding-top: 3.2rem;
  }
}
.pagenav .au_branch:before {
  content: "≥≥";
  letter-spacing: -5px;
  font-family: "au_icons";
}

.left-menu {
  background: #f2f2f2;
  padding: 2rem;
  font-family: "AUPassataRegular";
  font-size: 1.5rem;
  margin-bottom: 2rem;
}
.left-menu ul {
  margin: 0;
  margin-left: 1rem;
}
.left-menu ul ul {
  margin-bottom: 1.6rem;
  margin-left: 0;
}
.left-menu > ul > li:before {
  font-size: 1rem;
  margin-left: -2rem;
}
.left-menu li {
  list-style: none;
  margin-bottom: 1.4rem;
}
.left-menu li:before {
  font-size: 0.8rem;
}
.left-menu li li {
  font-size: 1.3rem;
  margin-left: 1rem;
  margin-bottom: 0rem;
}
.left-menu li a {
  display: block;
}
.left-menu a:hover,
.left-menu li.au_current a,
.left-menu li.au_current:before {
  color: #e2007a;
}
.department .left-menu a:hover, .general.neutral .left-menu a:hover,
.department .left-menu li.au_current a,
.general.neutral .left-menu li.au_current a,
.department .left-menu li.au_current:before,
.general.neutral .left-menu li.au_current:before {
  color: #2b81a4;
}
.left-menu li.au_current > a {
  font-family: "AuPassataBold";
}
.department .left-menu li.au_current > a {
  font-family: "AuPassataBold";
}
.left-menu li.au_current ul a {
  color: #002546 !important;
}

body {
  font-size: 1.3rem;
}

li:before,
label {
  color: #003d73;
}

a:hover {
  text-decoration: underline;
}

.dropdown-pane {
  background-color: #003260;
  color: #fff;
}

.top-bar,
.top-bar ul {
  background-color: transparent;
  padding: 0;
}

.top-bar-content {
  line-height: 3em;
}
.top-bar-content .button.arrow {
  background: none;
  color: #002546;
  padding-right: 3em;
  border-width: 0 !important;
}
.top-bar-content .button.arrow:after {
  color: #fff;
  background: #002546;
}

.title-bar {
  background: transparent;
}
.title-bar a {
  color: #000;
}

@media screen and (max-width: 39.9375em) {
  .title-bar {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .content {
    padding-top: 15px;
  }

  .left-menu h3 {
    display: none;
  }

  .au_responsive_hidden {
    display: none;
  }

  .au_responsive_only {
    display: block;
  }
}
.header-menu {
  z-index: 3;
}
.header-menu.open .language-selector,
.header-menu.open .search {
  display: none;
}
.header-menu.open body:not(.department) .menu-icon::after {
  background: #fff;
  box-shadow: 0 7px 0 #fff, 0 14px 0 #fff;
}
.header-menu.open li {
  background: #002546;
}
.header-menu.open li a {
  color: #fff;
}
.header-menu.open .is-accordion-submenu-parent > a::after {
  content: "+";
  border: 0;
  color: #9B9B9B;
  right: 2rem;
  position: absolute;
  font-size: 130%;
  font-family: "AUPassataBold";
  margin-top: -3px;
}
.header-menu.open [aria-expanded=true].is-accordion-submenu-parent > a::after {
  transform: rotate(45deg);
  transition-duration: 0.2s;
  color: #fff;
}
.header-menu.open li {
  font-size: 1.5rem;
}
.header-menu.open li a {
  border-color: rgba(255, 255, 255, 0.15) !important;
  border-width: 1px;
  padding: 1.5rem;
}
.header-menu.open li a:hover {
  text-decoration: underline;
}
.header-menu.open li li {
  font-size: 1.2rem;
}
.header-menu.open li li a {
  padding: 1rem;
  padding-left: 3rem;
}
.header-menu .menu-title {
  float: left;
  padding: 0.6em 0 0em 0.4em;
}

#fade {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 99;
  opacity: 0;
  display: none;
}
#fade .transparent {
  opacity: 0 !important;
}

.au_contentauthor {
  border-top: 1px solid #e4e4e4;
  clear: both;
  padding: 1.2em 0;
  font-size: 90%;
  color: #636363;
  font-family: AUPassataRegular;
  text-transform: none;
  /*ændret fra uppercase*/
  padding-bottom: 2.2em;
  display: block;
  overflow: hidden;
}

div.au_contentauthor > div {
  display: inline-block;
}

.tx-lfcontactauthor-pi1 a {
  color: #636363;
}

@media screen and (max-width: 39.9375em) {
  .level0 .pagenav, .level0 #au_mob_togglesectionnav, .level0 .main > h3 {
    display: none;
  }
  .level0 .top-bar.expanded .title-area {
    background: transparent;
  }
  .level0 .top-bar {
    background: transparent;
  }
  .level0 div.languageselector {
    right: 6.75rem;
  }

  :not(.level0) .top-bar {
    display: none;
  }
  :not(.level0) .sectiontitle {
    padding-bottom: 0.5em;
  }
  :not(.level0) #au_mob_togglesectionnav {
    top: 65px;
  }

  .section-nav-open div.languageselector,
div.languageselector {
    position: absolute;
    right: 3.75rem;
    top: 40px;
    display: block;
    z-index: 2;
  }

  .sectiontitle {
    padding-top: 2em;
    z-index: 2;
  }

  .main > h3 {
    color: #fff;
    margin: 0;
    padding-top: 0;
  }

  .pagenav {
    padding-top: 0;
  }
  .pagenav ul li {
    font-size: 15px;
  }
  .pagenav ul li:before, .pagenav ul li.au_selected_branch:before, .pagenav ul li.au_branch:before, .pagenav ul li.au_selected:before, .pagenav ul li.au_current:before {
    margin-left: -25px;
  }

  #au_mob_togglesectionnav {
    top: 76px;
    position: absolute;
    right: 0;
  }

  .top-bar .toggle-topbar.menu-icon {
    margin-top: -31px;
  }

  div.search {
    z-index: 2;
    display: block;
    position: absolute;
    width: 2em;
    right: 16px;
    border: 0;
  }
  div.search::after {
    font-size: 2em !important;
    top: 1.5em !important;
    right: 0 !important;
  }
  div.search input[type=text] {
    background: transparent !important;
    border: 0;
    color: #515151;
    float: right;
    width: 1.5em;
    margin: 0;
    padding: 0 !important;
    box-sizing: border-box;
    box-shadow: none;
    height: 3.5rem;
    font-size: 16px;
  }

  .opensearch .search {
    width: 100% !important;
    margin-right: 0 !important;
    transition: 0.4s ease-in-out;
    right: 0 !important;
  }
  .opensearch .search input[type=text] {
    width: 100%;
    transition: 0.2s ease-in-out;
    background-color: #fff !important;
    box-shadow: none;
    padding: 0.5em 1em !important;
  }
  .opensearch .search::after {
    visibility: hidden;
  }
  .opensearch h2 {
    visibility: hidden;
  }
}
@media screen and (max-width: 39.9375em) {
  .mobile-nav {
    margin-right: 1rem;
  }

  .department .language-selector {
    padding-top: 0.5rem !important;
    right: 58px !important;
  }
  .department .opensearch .search {
    top: 0 !important;
  }

  .header-menu {
    /*
    :not(.level0) & #au_section_nav_inner_list,
    :not(.level0) &+.columns #au_section_nav_inner_list {
      order: 2;
      width: 100%;
      margin-left: 0;
      z-index: 2;
      margin-bottom: 0;

      li {
        margin-bottom: 0;
        padding-left: 0;

        a {
          padding: 1rem 1.5rem;
          display: block;
        }
      }
    }
    */
  }
  .header-menu .utility-links {
    width: 100% !important;
    z-index: 1;
    top: 0;
    position: relative !important;
    right: auto !important;
    left: auto;
    order: 3;
  }
  .open .header-menu .utility-links {
    display: block;
  }
  .header-menu .utility-links ul.utility {
    column-count: 1 !important;
    display: block;
  }
  .header-menu .utility-links ul.utility li {
    display: block;
  }
  .header-menu .utility-links ul.utility li a {
    display: block;
    background: #e6e6e6;
    color: #002546;
    padding: 0.5rem 1.6rem;
    float: none !important;
  }
  .header-menu .utility-links ul.utility li a:after {
    color: #002546;
  }
  :not(.department) .header-menu .utility-links ul.utility li a {
    background: #cdcdcd;
  }
  .header-menu:not(.open) .utility-links,
.header-menu:not(.open) #au_section_nav_inner_list {
    display: none;
  }
  body:not(.level0):not(.layout16):not(.layout17) .header-menu #menu {
    display: none !important;
  }

  body.level0.department div.section-header:not(.no-topmenu) ~ div #au_section_nav_inner_list {
    display: none;
  }

  .left-menu {
    padding: 0;
    border-bottom: 1px solid #e6e6e6;
  }

  .top-bar {
    position: relative;
    z-index: 1;
  }

  .logo > h2,
.logo > a > h2 {
    font-size: 1.2rem;
    margin-left: 4.6em;
  }
  .logo > h2:before,
.logo > a > h2:before {
    left: 1rem;
  }

  .au_searchform {
    margin-top: 1rem;
  }

  ::-webkit-input-placeholder {
    /* WebKit browsers */
    color: transparent !important;
  }

  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: transparent;
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: transparent;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: transparent;
  }

  header .section-header.row {
    height: 58px;
  }
  header .section-header.row .main-logo object {
    height: 49px;
    margin-left: 4px;
  }
  .bss header .section-header.row .main-logo {
    width: 55px;
  }

  body:not(.department) div.search {
    right: 2em;
  }
  body:not(.department) div.search #tx_googlequery_searchform_ss_q,
body:not(.department) div.search #cludo-search-form-input {
    border-bottom: 0;
  }
  body:not(.department) .title-bar {
    padding: 0;
  }
  body:not(.department) .mobile-nav {
    position: absolute;
    right: 0;
    top: 0;
    padding: 1.2em 1em;
    height: 60px;
    z-index: 2;
    margin-right: 0;
  }
  body:not(.department) .mobile-nav .menu-icon::after {
    background: #fff;
    box-shadow: 0 7px 0 #fff, 0 14px 0 #fff;
  }
  body:not(.department) .mobile-nav div.search {
    right: 4em;
  }
  body:not(.department) .header-menu.open {
    /*
    .menu-icon::after {
      background: $primary-secondary-color;
      box-shadow: 0 7px 0 $primary-secondary-color, 0 14px 0 $primary-secondary-color;
    }
    */
  }
  body:not(.department) .header-menu.open > div li {
    background: #E6E6E6;
    color: #003d45;
  }
  body:not(.department) .header-menu.open > div li a {
    color: #003d45;
  }
  body:not(.department) .header-menu.open li a {
    border-color: rgba(95, 0, 48, 0.15) !important;
    border-width: 1px;
    border-bottom-style: solid;
  }
  body:not(.department) .header-menu.open [aria-expanded=true].is-accordion-submenu-parent > a::after {
    color: #e2007a;
  }
  body:not(.department) .language-selector {
    right: 8rem;
    top: 0.75rem;
  }
  body:not(.department) .language-selector a {
    color: #fff;
  }

  body.level0 .header-menu.open div li li a {
    border: 0 !important;
    padding-left: 0;
  }
}
/* jQuery UI fix */
.ui-autocomplete {
  position: absolute;
  z-index: 101;
}
.ui-autocomplete .ui-state-focus {
  background-color: #f2f2f2 !important;
  background-image: none !important;
  border: none !important;
}
.ui-autocomplete .ui-menu-item {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  font-size: 1.1rem;
}
.ui-autocomplete .ui-menu-item a {
  text-decoration: none;
  display: block;
  padding: 0.3em 0.4em;
  line-height: 1.5;
  zoom: 1;
}
.ui-autocomplete .ui-menu-item.more {
  font-weight: bold;
}

.ui-helper-hidden-accessible {
  position: absolute;
  left: -9999px;
}

.ui-menu {
  max-height: 300px;
  overflow-y: scroll;
}

.ui-autocomplete-loading {
  background: url("//cmsdesign.au.dk/design/2008/graphics/gsa/ajax-loader.gif") no-repeat scroll 95% 50%;
}

[data-dropdown-menu] ul {
  display: none;
}

.utility-links .dropdown-pane {
  width: 360px;
}
@media screen and (max-width: 39.9375em) {
  .utility-links .dropdown-pane {
    width: 100%;
  }
}
.utility-links .dropdown-pane.student {
  background-color: #365f13;
}
.utility-links .dropdown-pane > h2 {
  padding: 1rem 1rem 0 1rem;
}
.utility-links .dropdown-pane > h2 a {
  color: #fff;
}
.utility-links .dropdown-pane > p {
  padding: 0 1rem;
  font-size: 1.25rem;
}
.utility-links .dropdown-pane .button {
  /*
      &:not(:last-of-type) {
        border-bottom: 1px solid #fff;
      }*/
  border-top: 1px solid #fff;
  margin-bottom: 0;
}
.utility-links .dropdown-pane .button.text-left {
  text-align: left;
}
.utility-links .dropdown-pane .button.margin {
  border-top: none;
  margin: 1rem;
  width: auto;
}
.utility-links .dropdown-pane .button.margin:after {
  transition: 0.2s;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  color: #333;
  text-align: center;
  background: #fff;
  position: absolute;
  right: 0.65rem;
  bottom: 0.65rem;
  line-height: 2.4em;
  content: "≥";
  font-family: "au_icons";
  z-index: 1;
  font-size: 1.2rem;
}

.dropdown-pane {
  right: 0;
  z-index: 110;
}

#utility-pane-staff {
  background-color: #37a0cb;
}

#utility-pane-student {
  background-color: #8bad3f;
}

#utility-pane-phd .bg-dark-phd,
#utility-pane-phd .button.bg-phd:hover {
  background-color: #002546 !important;
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) and (orientation: portrait) {
  .medium-only-portrait-3 {
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 0.9090909091rem;
    padding-left: 0.9090909091rem;
    max-width: 25%;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) and (orientation: portrait) and (min-width: 40em) {
  .medium-only-portrait-3 {
    padding-right: 1.3636363636rem;
    padding-left: 1.3636363636rem;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) and (orientation: portrait) {
  .medium-only-portrait-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding-right: 0.9090909091rem;
    padding-left: 0.9090909091rem;
    max-width: 33.3333333333%;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) and (orientation: portrait) and (min-width: 40em) {
  .medium-only-portrait-4 {
    padding-right: 1.3636363636rem;
    padding-left: 1.3636363636rem;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) and (orientation: portrait) {
  .medium-only-portrait-6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.9090909091rem;
    padding-left: 0.9090909091rem;
    max-width: 50%;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) and (orientation: portrait) and (min-width: 40em) {
  .medium-only-portrait-6 {
    padding-right: 1.3636363636rem;
    padding-left: 1.3636363636rem;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) and (orientation: portrait) {
  .medium-only-portrait-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    padding-right: 0.9090909091rem;
    padding-left: 0.9090909091rem;
    max-width: 66.6666666667%;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) and (orientation: portrait) and (min-width: 40em) {
  .medium-only-portrait-8 {
    padding-right: 1.3636363636rem;
    padding-left: 1.3636363636rem;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) and (orientation: portrait) {
  .medium-only-portrait-12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0.9090909091rem;
    padding-left: 0.9090909091rem;
    max-width: 100%;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) and (orientation: portrait) and (min-width: 40em) {
  .medium-only-portrait-12 {
    padding-right: 1.3636363636rem;
    padding-left: 1.3636363636rem;
  }
}
.department .language-selector {
  position: absolute;
  right: 126px;
  top: 0.28em;
  z-index: 2;
  font-size: 125%;
}
.department .language-selector a {
  color: #fff;
}
@media screen and (min-width: 40em) {
  .department .utility-links {
    right: 20rem !important;
  }
}

.neutral .language-selector {
  position: absolute;
  right: 215px;
  top: 0.75em;
  z-index: 1;
  font-size: 125%;
}
@media screen and (max-width: 39.9375em) {
  .neutral .language-selector {
    right: 4rem !important;
    top: 0.5em !important;
    font-size: 100%;
  }
}
.neutral .language-selector a {
  color: #fff;
}

.au_social_sharing a {
  text-decoration: none;
}

@media screen and (min-width: 90em) {
  .main {
    padding-right: 0;
  }
  .layout16 .main, .layout17 .main {
    padding-left: 0;
  }
  .layout16 .main.content .row, .layout17 .main.content .row {
    max-width: 130rem;
  }
}
.main-logo {
  display: block;
  width: 252px;
  min-height: 42px;
  z-index: 1003;
  overflow: hidden;
  margin: 4px 0 0 -10px;
}
.main-logo object {
  height: 42px;
  pointer-events: none;
}

#tx_googlequery_searchform_ss_q.loading {
  background-position: 95% !important;
}

@media screen and (min-width: 40em) {
  .general .main-logo {
    margin: 15px 0 0 0px;
  }
  .general .main-logo object {
    height: 58px;
  }

  .au_responsive_only {
    display: none;
  }
}
@media (max-width: 320px) {
  .bss header .section-header.row .main-logo {
    width: 42px;
  }
  header .section-header.row .main-logo object {
    height: 40px;
    margin-left: 0;
    margin-top: 5px;
  }
}
.neutral-container {
  clear: both;
}
.neutral-container div[class*=bg-]:not(.aubanner) {
  padding: 1rem;
  margin-bottom: 1rem;
}
.neutral-container div[class*=bg-light] .au_collapsible,
.neutral-container div[class*=bg-dark] .au_collapsible {
  padding: 0;
  background-color: transparent;
}
.neutral-container div[class*=bg-dark] {
  color: #fff;
}
.neutral-container div[class*=bg-dark] li:before {
  color: #fff;
}
.neutral-container div[class*=bg-dark] a {
  color: #fff;
}
.neutral-container .csc-firstHeader {
  margin-top: 0;
}

.neutral-container.expanded {
  margin: 1rem 0 1rem 0;
}

@media screen and (max-width: 39.9375em) {
  .no-js .top-bar {
    display: none;
  }
}
@media print, screen and (min-width: 40em) {
  .no-js .title-bar {
    display: none;
  }
}

.general .menu .active > a,
.general .menu .active > a:hover {
  color: #e2007a;
  background-color: transparent;
}

.urgency > div {
  background-color: #F8E71C;
  padding: 1.5rem;
  position: relative;
}
.urgency > div a {
  color: #000;
}
.urgency > div div.small-12 {
  padding-left: 6rem;
  padding-right: 50%;
}
.urgency > div div.small-12 p {
  margin-bottom: 0;
}
.urgency > div div.small-12:before {
  font-family: "au_icons";
  content: "i";
  font-size: 3rem;
  position: absolute;
  margin-top: 0;
  margin-left: -4.5rem;
  line-height: 2em;
  color: #f00;
  z-index: 2;
}
@media screen and (max-width: 39.9375em) {
  .urgency > div {
    z-index: 2;
  }
  .urgency > div div.small-12 {
    padding-left: 7rem;
    padding-right: 0;
  }
  .urgency > div div.small-12:before {
    font-size: 5rem;
    margin-top: 0;
    margin-left: -6.5rem;
    line-height: 5rem;
  }
}

.page-id {
  font-family: Georgia;
  color: #06315a;
  width: 100%;
  text-align: right;
  display: block;
  padding-right: 1em;
  margin-top: -2em;
  font-size: 80%;
}
.page-id:hover {
  color: #fff;
}
.page-id:before {
  content: "page id: ";
}

/* Responsive video fra YouTube */
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  /*skal bare være mellem 50 og 60% */
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Historic content */
.au_historic.row.historic {
  -webkit-clip-path: polygon(3% 0, 7% 1%, 11% 0, 16% 2%, 20% 0, 23% 2%, 28% 2%, 32% 1%, 35% 1%, 39% 3%, 41% 1%, 45% 0, 47% 2%, 50% 2%, 53% 0, 58% 2%, 60% 2%, 63% 1%, 65% 0, 67% 2%, 69% 2%, 73% 1%, 76% 1%, 79% 0, 82% 1%, 85% 0, 87% 1%, 89% 0, 92% 1%, 96% 0, 98% 3%, 99% 3%, 99% 6%, 100% 11%, 98% 15%, 100% 21%, 99% 28%, 100% 32%, 99% 35%, 99% 40%, 100% 43%, 99% 48%, 100% 53%, 100% 57%, 99% 60%, 100% 64%, 100% 68%, 99% 72%, 100% 75%, 100% 79%, 99% 83%, 100% 86%, 100% 90%, 99% 94%, 99% 98%, 95% 99%, 92% 99%, 89% 100%, 86% 99%, 83% 100%, 77% 99%, 72% 100%, 66% 98%, 62% 100%, 59% 99%, 54% 99%, 49% 100%, 46% 98%, 43% 100%, 40% 98%, 38% 100%, 35% 99%, 31% 100%, 28% 99%, 25% 99%, 22% 100%, 19% 99%, 16% 100%, 13% 99%, 10% 99%, 7% 100%, 4% 99%, 2% 97%, 1% 97%, 0 94%, 1% 89%, 0 84%, 1% 81%, 0 76%, 0 71%, 1% 66%, 0 64%, 0 61%, 0 59%, 1% 54%, 0 49%, 1% 45%, 0 40%, 1% 37%, 0 34%, 1% 29%, 0 23%, 2% 20%, 1% 17%, 1% 13%, 0 10%, 1% 6%, 1% 3%);
  clip-path: polygon(3% 0, 7% 1%, 11% 0, 16% 2%, 20% 0, 23% 2%, 28% 2%, 32% 1%, 35% 1%, 39% 3%, 41% 1%, 45% 0, 47% 2%, 50% 2%, 53% 0, 58% 2%, 60% 2%, 63% 1%, 65% 0, 67% 2%, 69% 2%, 73% 1%, 76% 1%, 79% 0, 82% 1%, 85% 0, 87% 1%, 89% 0, 92% 1%, 96% 0, 98% 3%, 99% 3%, 99% 6%, 100% 11%, 98% 15%, 100% 21%, 99% 28%, 100% 32%, 99% 35%, 99% 40%, 100% 43%, 99% 48%, 100% 53%, 100% 57%, 99% 60%, 100% 64%, 100% 68%, 99% 72%, 100% 75%, 100% 79%, 99% 83%, 100% 86%, 100% 90%, 99% 94%, 99% 98%, 95% 99%, 92% 99%, 89% 100%, 86% 99%, 83% 100%, 77% 99%, 72% 100%, 66% 98%, 62% 100%, 59% 99%, 54% 99%, 49% 100%, 46% 98%, 43% 100%, 40% 98%, 38% 100%, 35% 99%, 31% 100%, 28% 99%, 25% 99%, 22% 100%, 19% 99%, 16% 100%, 13% 99%, 10% 99%, 7% 100%, 4% 99%, 2% 97%, 1% 97%, 0 94%, 1% 89%, 0 84%, 1% 81%, 0 76%, 0 71%, 1% 66%, 0 64%, 0 61%, 0 59%, 1% 54%, 0 49%, 1% 45%, 0 40%, 1% 37%, 0 34%, 1% 29%, 0 23%, 2% 20%, 1% 17%, 1% 13%, 0 10%, 1% 6%, 1% 3%);
  background-color: beige;
  padding: 5rem 1rem;
  margin-left: 0;
  margin-bottom: 1rem;
  position: relative;
}

.au_historic.row.historic::before {
  content: "Historical section";
  display: inline-block;
  padding: 0 1rem;
  background-color: #fafad2;
  border: 1px solid #000;
  text-transform: full-width;
  margin: 4rem 2rem auto;
  right: 0;
  top: 0px;
  position: absolute;
}

html:lang(da) .au_historic.row.historic::before {
  content: "Historisk afsnit";
}

/* Faktaboks */
.au_box_1 {
  background: none repeat scroll 0 0 #f1f7fa;
  border-bottom: 3px solid #002546;
  border-top: 1px solid #002546;
  margin-bottom: 2rem;
  padding: 1rem;
  overflow: auto;
  float: left;
  width: 200px;
  margin-right: 1em;
}
.au_box_1.right {
  float: right;
  margin-left: 1em;
  margin-right: 0;
}
.au_box_1.center {
  float: none;
  width: auto;
  margin: auto;
}

/*Spinner fra au-news gøres global*/
.spinner {
  width: 40px;
  height: 40px;
  margin: 60px auto;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
.rendering .prefix {
  display: inline;
  border: none;
  background: inherit;
  font: inherit;
}

@media screen and (max-width: 39.9375em) {
  .header-menu.open .responsive-pagenav,
.universe .responsive-pagenav {
    order: 2;
    width: 100%;
    margin-left: 0;
    z-index: 2;
    margin-bottom: 0;
  }
  .header-menu.open .responsive-pagenav ul,
.universe .responsive-pagenav ul {
    margin: 0;
  }
  .header-menu.open .responsive-pagenav li,
.universe .responsive-pagenav li {
    padding-left: 3rem !important;
    margin-bottom: 0;
  }
  .header-menu.open .responsive-pagenav li::before,
.universe .responsive-pagenav li::before {
    font-family: au_icons;
    content: "≥";
    font-size: 1rem;
    position: absolute;
    margin-left: -2rem;
    margin-top: 1rem;
    line-height: 2.5em;
    font-weight: 700;
    color: #fff;
  }
  .header-menu.open .responsive-pagenav li a,
.universe .responsive-pagenav li a {
    display: block;
    padding: 1rem 1.5rem 1rem 0;
  }
  .header-menu.open .responsive-pagenav li.lvl2, .header-menu.open .responsive-pagenav li.lv2,
.universe .responsive-pagenav li.lvl2,
.universe .responsive-pagenav li.lv2 {
    /* OMG */
  }
  .header-menu.open .responsive-pagenav li.lvl2::before, .header-menu.open .responsive-pagenav li.lv2::before,
.universe .responsive-pagenav li.lvl2::before,
.universe .responsive-pagenav li.lv2::before {
    font-size: 0.8rem;
  }
  .header-menu.open .responsive-pagenav li.au_branch::before,
.universe .responsive-pagenav li.au_branch::before {
    content: "≥≥";
    letter-spacing: -5px;
  }
  .header-menu.open .responsive-pagenav li.au_current,
.universe .responsive-pagenav li.au_current {
    font-weight: bold;
  }
}
body.general.neutral .neutral-container {
  margin-bottom: 2em;
}
body.general.neutral .left-menu li.au_current > a, body.general.neutral .left-menu li.au_current ul a {
  font-family: inherit;
  color: inherit !important;
}
body.general.neutral header .top-bar .menu > li > a:after {
  display: none;
}
body.general.neutral header .main-logo {
  width: 300px;
}
body.general.neutral header .au_searchform #tx_googlequery_searchform_ss_q {
  background: #e9e9e9;
  border-bottom: 0;
  color: #000;
  box-shadow: none;
}
body.general.neutral header .header-menu.open li {
  background: #e6e6e6;
}
body.general.neutral .au_searchform:before {
  left: 0.5em;
}

body.mod1 .main-logo {
  margin: 8px 0 0;
}
body.mod1 .main-logo object {
  height: 46px;
}
body.mod1 header .section-header.row {
  height: 46px;
  position: relative;
}
body.mod1 header .seal img {
  width: 36px;
  padding-top: 5px;
  float: right;
}
body.mod1 .au_searchform {
  margin-top: 7px;
}
body.mod1 .au_searchform #tx_googlequery_searchform_ss_q {
  background: #224869;
  border: 0;
  text-align: right;
  color: #fff;
  height: 32px;
}
body.mod1 .au_searchform #tx_googlequery_searchform_ss_q:focus {
  background: #fff;
  color: #333;
  text-align: left;
}
body.mod1 .au_searchform:before {
  top: 0;
  left: 0.5em;
}
@media screen and (min-width: 64em) {
  body.mod1 .au_searchbox {
    position: relative;
    left: 63px;
  }
}

@media print {
  .medium-1 {
    flex: 0 0 8.3333333333%;
  }

  .medium-2 {
    flex: 0 0 16.6666666667%;
  }

  .medium-3 {
    flex: 0 0 25%;
  }

  .medium-4 {
    flex: 0 0 33.3333333333%;
  }

  .medium-5 {
    flex: 0 0 41.6666666667%;
  }

  .medium-6 {
    flex: 0 0 50%;
  }

  .medium-7 {
    flex: 0 0 58.3333333333%;
  }

  .medium-8 {
    flex: 0 0 66.6666666667%;
  }

  .medium-9 {
    flex: 0 0 75%;
  }

  .medium-10 {
    flex: 0 0 83.3333333333%;
  }

  .medium-11 {
    flex: 0 0 91.6666666667%;
  }

  .medium-12 {
    flex: 0 0 100%;
  }

  a[href]:after, a[href*="/"]:after, a[href*="/"]:visited:after {
    content: normal;
  }

  a {
    text-decoration: none !important;
  }

  .button, .au_button a, .au_button, .au_focus * {
    color: #fff !important;
  }

  .left-menu, .au_news-list-browse, .section-header, .language-selector, .header-menu {
    display: none;
  }

  .main {
    flex: 0 0 100%;
    max-width: 100%;
  }

  footer {
    display: none;
  }

  footer.global .columns {
    background: transparent;
  }

  .aubanner p, .aubanner a, .aubanner h2, .aubanner h3, .aubanner.aubanner-2 p, .aubanner.aubanner-2 a, .aubanner.aubanner-2 h2, .aubanner.aubanner-2 h3, .aubanner.dark p, .aubanner.dark a, .aubanner.dark h2, .aubanner.dark h3 {
    color: #fff !important;
  }

  .aubanner.aubanner-2.white p, .aubanner.aubanner-2.white a, .aubanner.aubanner-2.white h2, .aubanner.aubanner-2.white h3 {
    color: #333 !important;
  }

  .footer.globalfooter {
    display: none;
  }

  .neutral-container div[class*=bg-]:not(.aubanner) {
    background-color: transparent !important;
    border: 1px solid black;
  }

  header {
    display: none;
  }

  .row {
    display: block;
  }

  .column, .columns {
    max-width: 100%;
  }

  .clearfix {
    page-break-inside: avoid;
  }
}
/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}
.mfp-preloader a {
  color: #CCC;
}
.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}
.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}
.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mfp-arrow:active {
  margin-top: -54px;
}
.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}
.mfp-arrow:before, .mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}
.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}
.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}
.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}
.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}
.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}
.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}
.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}
.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}
.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}
.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
   * Remove all paddings around the image on small screen
   */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
.datepicker {
  display: none;
  position: absolute;
  padding: 4px;
  margin-top: 1px;
  direction: ltr;
}
.datepicker.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  display: none;
  min-width: 160px;
  list-style: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  color: #333;
  font-size: 13px;
  line-height: 18px;
}
.datepicker.dropdown-menu th {
  padding: 4px 5px;
}
.datepicker.dropdown-menu td {
  padding: 4px 5px;
}
.datepicker table {
  border: 0;
  margin: 0;
  width: auto;
}
.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
}
.datepicker td {
  text-align: center;
  width: 20px;
  height: 20px;
  border: 0;
  font-size: 12px;
  padding: 4px 8px;
  background: #fff;
  cursor: pointer;
}
.datepicker td.active.day, .datepicker td.active.year {
  background: #2ba6cb;
}
.datepicker td.old, .datepicker td.new {
  color: #999;
}
.datepicker td span.active {
  background: #2ba6cb;
}
.datepicker td.day.disabled {
  color: #eee;
}
.datepicker td span.month.disabled, .datepicker td span.year.disabled {
  color: #eee;
}
.datepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  border: 0;
  font-size: 12px;
  padding: 4px 8px;
  background: #fff;
  cursor: pointer;
}
.datepicker th.active.day, .datepicker th.active.year {
  background: #2ba6cb;
}
.datepicker th.date-switch {
  width: 145px;
}
.datepicker th span.active {
  background: #2ba6cb;
}
.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle;
}
.datepicker.days div.datepicker-days {
  display: block;
}
.datepicker.months div.datepicker-months {
  display: block;
}
.datepicker.years div.datepicker-years {
  display: block;
}
.datepicker thead tr:first-child th {
  cursor: pointer;
}
.datepicker thead tr:first-child th.cw {
  cursor: default;
  background-color: transparent;
}
.datepicker tfoot tr:first-child th {
  cursor: pointer;
}

.datepicker-inline {
  width: 220px;
}

.datepicker-rtl {
  direction: rtl;
}
.datepicker-rtl table tr td span {
  float: right;
}

.datepicker-dropdown {
  top: 0;
  left: 0;
}
.datepicker-dropdown:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: 1px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: 6px;
}
.datepicker-dropdown:after {
  content: "";
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  position: absolute;
  top: -6px;
  left: 7px;
}

.datepicker > div,
.datepicker-dropdown::before,
.datepicker-dropdown::after {
  display: none;
}

.datepicker-close {
  position: absolute;
  top: -30px;
  right: 0;
  width: 15px;
  height: 30px;
  padding: 0;
  display: none;
}

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent;
}